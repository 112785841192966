import React, { Component } from "react";
import { Redirect,Link } from "react-router-dom";
import axios from "axios";
import { Button } from "reactstrap";
import MembershipThumb from "./../images/membership-upgrade.gif";
import Header from "./Common/CommonCirclesHeader";
import Footer from "./Common/CommonCirclesFooter";
import Loader from "./Loader";
import CustomCouponsModal from './CustomCouponsModal';
import parse from 'html-react-parser';
import APICalls from "./apis/APICalls";

export default class MembershipUpgrade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigate: false,
      isLoading: false,
      msg: "",
      redirect : false,
      redirect_to_home : false,
      limit_reached : false,
      membership_id:'',
      membership_price:'',
      errors: {},
      errMsg:'',
      coupon_code:'',
      applied_coupon_code:'',
      final_price:0.00,
      coupon_applied:false,
      invalid_code:false,
      available_coupons:[],
      isCouponsModalOpen:false,
      redirect_to_checkout:false,
      redirect_to_add_members:false,
      current_plan_id:''
    };
    this.user = JSON.parse(localStorage.getItem("userData"));
  }  
  state = {
    navigate: false,
  };

  async componentDidMount() 
  {
    this.setState({ isLoading:true });
    
    await axios
    .get("/membershipdetail", {})
    .then((response) => {
      if (response.status === 200) 
      {
        var memberships = response.data.data;
        this.setState({ memberships:memberships });
      }
    })
    .catch((error) => {
      this.setState({ isLoading: false });
    }); 

    const journey_id = localStorage.getItem('journey_id');

    await axios.get('/check_free_plan_limit', { params:{journey_id:journey_id} })
    .then((response) => {
      if(response.status === 200) 
      {       
        this.setState({ isLoading:false,limit_reached : true });
      }
    })
    .catch((error) => {
      this.setState({ isLoading: false });
    }); 

    const api = new APICalls();
    await api.getCurrentMembershipDetails()
                .then((response) => {
                  if(response.status === 200) 
                  {
                    console.log(response.data.current_membership_details);
                    this.setState({current_plan_id:response.data.current_membership_details.membership_id});
                  }
                })
                .catch((error) => {
                  this.setState({ isLoading: false });
                }); 
  }

  onChangehandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };


  upgrade_membership = e => {
    this.setState({ redirect_to_checkout:true });
  };  

  purchase_subscription = e => {
    const membership_id     = e.target.dataset.membership_id;
    const plan_id           = e.target.dataset.plan_id;
    const membership_price  = e.target.dataset.price;

    this.setState({ membership_id:membership_id,plan_id:plan_id,membership_price:membership_price });
    this.setState({ redirect_to_checkout:true });
  };

  add_members = e => {
    const membership_id     = e.target.dataset.membership_id;
    const plan_id           = e.target.dataset.plan_id;
    const membership_price  = e.target.dataset.price;

    this.setState({ membership_id:membership_id,plan_id:plan_id,membership_price:membership_price });
    this.setState({ redirect_to_add_members:true });
  };  

  keep_free = e => {
    this.setState({ redirect_to_home:true });
  };   

  remove_coupon = e => {
    this.setState({ final_price:this.state.membership_price,coupon_applied:false,applied_coupon_code:'',msg:'' });
  };  
  
  openCouponsModal = e => {
    this.setState({
      isCouponsModalOpen: true,
    });
  };

  closeCouponsModal = e => {
    this.setState({
      isCouponsModalOpen: false,
    });
  };

  render() {
    console.log(this.state.current_plan_id);
    const user = JSON.parse(localStorage.getItem("userData"));
    
    const isLoading = this.state.isLoading;

    if(user && (user.email_verified_at === '' || user.email_verified_at === null))
    {
      return <Redirect to="/email-verification" push={true} />;
    }
    
    const login = localStorage.getItem("isLoggedIn");

    if(!login) 
    {
      return <Redirect to="/sign-in" />;
    }

    if(this.state.redirect_to_home) 
    {
      return <Redirect to="/home" />;
    }

    if(this.state.memberships)
    {
      var memberships = this.state.memberships.map((membership,i) => {
        return (
            <li key={i}>
              <span>
                {parse(membership.description)}
              </span>
              {(membership.id === this.state.current_plan_id) ? (
                <Button className="btn btn-primary m-0 green_colored" disabled>
                Active
                </Button>  
              ) : (
                <>
                  {(membership.is_grouped === 1) ? (
                    <Button
                      className="btn btn-primary m-0" data-membership_id={membership.id} data-price={membership.price} data-plan_id={membership.braintree_actual_plan_id}
                      onClick={this.add_members}
                      >
                      Buy Now
                    </Button> 
                  ) : (
                    <Button
                    className="btn btn-primary m-0" data-membership_id={membership.id} data-price={membership.price} data-plan_id={membership.braintree_actual_plan_id}
                    onClick={this.purchase_subscription}
                    >
                    Buy Now
                    </Button>  
                )}
                </>
              )}
            </li>
        );
      });
    }

    if(this.state.redirect_to_checkout) 
    {
        return <Redirect to={{
          pathname: '/checkout',
          state: { plan_id:this.state.plan_id,membership_id:this.state.membership_id,membership_price:this.state.membership_price }
            }}
        /> 
    }
    
    if(this.state.redirect_to_add_members) 
    {
        return <Redirect to={{
          pathname: '/add-members',
          state: { plan_id:this.state.plan_id,membership_id:this.state.membership_id,membership_price:this.state.membership_price }
            }}
        /> 
    }    

    return (
      <>
        <div className="level-2-wrapper">
        <Header />

          {isLoading ? (
            <Loader />
            ) : (
              <span></span>
          )}

          <div className="scrollable-element membership-scroll-wrap">
            <div className="container h-100">
                <div className="row h-100 justify-content-center">
                    <div className="col-12 align-self-center">
                        <div className="mid-content-wrapper">
                            <h5 className="text-center mb-4">Choose a Plan</h5> 
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    <div className="media">
                                        <img src={MembershipThumb} className="mr-lg-5 mr-3" alt="thumb" width="142" />
                                        <div className="media-body">
                                            <ul className="list-unstyled membership-plan">
                                              {memberships}
                                            </ul>
                                        </div>
                                    </div>
                                    
                                    <div className="text-center">
                                        <div><strong><Link to="#" className="support-link">Need a Scholarship?</Link></strong></div>
                                        <h5 className="my-3">Membership Plan Features</h5> 
                                        <p>Membership includes access to all twelve 33 Sec Tune-Ups, eighteen Heartfulness Flows, twelve Sacred Geometry Pulses as well as insights videos that you unlock as you progress along the 33 session Journey Within or 18 session Through The Veil Journey. Premium users also get unlimited Ventbox recordings and unlimited Heartbox deposits.
                                        </p>
                                    </div>

                                    <div className="text-center mt-4">
                                        <p><strong>By going further, you are agreeing to Socreates <Link to="https://socreatesweb.kitlabs.us/terms-of-use">Terms of Payment</Link> and <Link to="https://socreatesweb.kitlabs.us/privacy-policy">Privacy Policy</Link></strong></p>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

          <Footer />
        </div> 

        { this.state.isCouponsModalOpen ? 
          <CustomCouponsModal 
            closeModal={this.closeCouponsModal} 
            isOpen={this.state.isCouponsModalOpen} 
            available_coupons={this.state.available_coupons}
          /> 
          : 
          null 
        }    
      </>
    );
  }
}