import React, { Component } from "react";
import { Redirect,Link } from "react-router-dom";
import axios from "axios";
import Logo from "./../images/logo.png";
import DataNotFound from "./../images/data_not_found.gif";
import AuthLinks from "./AuthLinks";
import Loader from "./Loader";
import CommonFooter from "./CommonFooter";
import GeometryBundle3 from "./GeometryBundle3";

export default class JournalDetails extends Component {
  
  constructor(props) 
  {
    super(props);
    this.state = {
      redirect:false,
      isLoading:false,
      socreates_takeaways:[],
      user_takeaways:['No Response','No Response','No Response'],
      reflections_data_not_found:false,
      video_name:'',
      video_url:'',
      video_id:'',
      uservideo_id:'',
      redirect_to_library:false,
    };
  }
  
  componentDidMount() 
  {
      const journey_id = localStorage.getItem('journey_id');
      const uservideo_id = this.props.location.state.uservideo_id;
      const videoID = this.props.location.state.video_id;

      this.setState({ isLoading:true });

      axios.get('/get_user_journal_detail', { params:{ journey_id:journey_id,uservideo_id:uservideo_id,videoID:videoID } })
      .then((response) => {
        if(response.status === 200) 
        {
          this.setState({ isLoading:false,socreates_takeaways:response.data.data.socreates_takeaways,user_takeaways:response.data.data.user_takeaways,video_name:response.data.data.video_name,video_url:response.data.data.video_url,video_id:response.data.data.videoID, uservideo_id:response.data.data.uservideo_id });
        }
      })
      .catch((error) => {
        if(error.response.status === 400)
        {
          this.setState({ isLoading:false,reflections_data_not_found:true });
        }
      });
  }

  clickBtn = e => {
    this.setState({
      renderView: +e.target.value
    });
  };

  go_to_library = e => {
    this.setState({
      redirect_to_library: true
    });
  };

  render() {
    const isLoading = this.state.isLoading;
    
    const login = localStorage.getItem("isLoggedIn");
    const reflections_data_not_found = this.state.reflections_data_not_found;

    var video_name    = this.state.video_name;
    var video_url     = this.state.video_url;
    var uservideo_id  = this.state.uservideo_id;
    var video_id      = this.state.video_id;

    if(!login) 
    {
      return <Redirect to="/sign-in" />;
    }     

    const user = JSON.parse(localStorage.getItem("userData"));

    if(user && (user.email_verified_at === '' || user.email_verified_at === null))
    {
      return <Redirect to="/email-verification" push={true} />;
    }

    if(this.state.redirect_to_library)
    {
      return <Redirect
          to={{
          pathname: "/library",
          state: { video_name:video_name, received_video_url:video_url,video_type:'insight',uservideo_id:uservideo_id,video_id:video_id }
      }}
      />
    }

    return (
      <>
      <div className="main-wrapper another-main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>

          {isLoading ? (
            <Loader />
            ) : (
              <span></span>
          )}

          <div className="mid-container journal-screen-container">
            <div className="content mt-0">
              <div className="row">
                <div className="col-sm-12 p-0">
                    {reflections_data_not_found ? (
                        <figure className="mt-4"><img src={DataNotFound} alt="DataNotFound" className="gif-large" /></figure>
                      ) : (
                      <>
                        <h4 className="my-3 text-center tab-title">Summary of "{this.state.video_name}" Session</h4>
                        <div className="reflection-btns text-center mt-sm-4 mt-0 mb-4">
                            <Link to="#" onClick={this.go_to_library} className="btn btn-primary">Rewatch This Video</Link>
                            <Link to="/previous-sessions" className="btn btn-primary">All Previous Sessions</Link>
                        </div>

                        <div className="journal-right-content">
                          <div className="row">

                            <div className="col-md-4">
                                <div className="reflection-content">
                                    <h5 className="mb-2">Socreates Summary</h5>
                                    {this.state.socreates_takeaways[0] ? (
                                      <p className="text-justify">1. {this.state.socreates_takeaways[0]}</p>
                                    ) : (
                                      <p></p>
                                    )} 
                                    <h5 className="mb-2 mt-4">Your Reflections</h5>
                                    {this.state.user_takeaways[0] ? (
                                      <p className="text-justify">1. {this.state.user_takeaways[0]}</p>
                                    ) : (
                                      <p className="text-justify">1. No Response Submitted</p>
                                    )} 
                                </div>                                    
                            </div>
                            
                            <div className="col-md-4 mt-3 mt-sm-0">
                                <div className="reflection-content">
                                    <h5 className="mb-2">Socreates Summary</h5>
                                    {this.state.socreates_takeaways[1] ? (
                                      <p className="text-justify">2. {this.state.socreates_takeaways[1]}</p>
                                    ) : (
                                      <p></p>
                                    )} 
                                    <h5 className="mb-2 mt-4">Your Reflections</h5>
                                    {this.state.user_takeaways[1] ? (
                                      <p className="text-justify">2. {this.state.user_takeaways[1]}</p>
                                    ) : (
                                      <p className="text-justify">2. No Response Submitted</p>
                                    )} 
                                </div>                                    
                            </div>

                            <div className="col-md-4 mt-3 mt-sm-0">
                                <div className="reflection-content">
                                    <h5 className="mb-2">Socreates Summary</h5>
                                    {this.state.socreates_takeaways[2] ? (
                                      <p className="text-justify">3. {this.state.socreates_takeaways[2]}</p>
                                    ) : (
                                      <p></p>
                                    )} 
                                    <h5 className="mb-2 mt-4">Your Reflections</h5>
                                    {this.state.user_takeaways[2] ? (
                                      <p className="text-justify">3. {this.state.user_takeaways[2]}</p>
                                    ) : (
                                      <p className="text-justify">3. No Response Submitted</p>
                                    )} 
                                </div>                                    
                            </div>

                          </div>
                        </div>
                        </>   
                    )} 
                </div>
              </div>
            </div>
          </div>
          <GeometryBundle3 />
          <CommonFooter />
        </div>  
      </>
    );
  }
} 