import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import Logo from "./../../images/logo.png";
import VimeoCons from "./../VimeoCons";
import AuthLinks from "./../AuthLinks";
import Loader from "./../Loader";
import CommonFooter from "./../CommonFooter";
import GeometryBundle2 from "./../GeometryBundle2";

export default class Tutorial extends Component {
  constructor(props) 
  {
    super(props);
    this.state = { key: 2,tutorial_video:'',isLoading:false };
  }

  componentDidMount() 
  {
    this.setState({ isLoading:true });
    axios.get('/tutorial_video', {
      params:{journey_id : 30 }
    })
    .then((response) => {
      if(response.status === 200) 
      {       
        this.setState({
            tutorial_video : response.data.data,isLoading:false
        });
      }
    })
    .catch((error) => {
      this.setState({ isLoading:false });
    });
  }

  handleSelect(key, screens) {
    this.props.toggleForm(screens);
    this.setState({ key });
  }

  clickBtn = e => {
    this.setState({
      renderView: +e.target.value
    });
  };

  render() {
    if(this.state.renderView === 1)
    {
      return <Redirect to="/home" />;
    }
    const isLoading = this.state.isLoading;

    if(this.state.tutorial_video)
    {
      return (
        <>
          <div className="main-wrapper">
            <header>
              <figure className="waves-top"></figure>
              <figure className="logo">
                <img src={Logo} alt="logo" />
              </figure>
              <AuthLinks />
            </header>

            {isLoading ? (
              <Loader />
                ) : (
                <></>
            )}

              <div className="mid-container">
                <div className="content">
                    <div className="row">
                        <div className="col-md-6 col-12 onboarding">
                            <figure><VimeoCons get_thumbnail="TRUE" get_video_with_subtitles="TRUE" video_url={this.state.tutorial_video.video_url} /></figure>
                        </div>
                        <div className="col-md-6 col-12 align-self-center">
                            <div className="px-md-5 px-0">
                              <p>Watch this quick tutorial on the app features.</p>
                            </div>
                            <button value={1} className="btn btn-primary" onClick={this.clickBtn}>OK</button>
                        </div>
                    </div>
                </div>            
              </div>
            <GeometryBundle2 />
            <CommonFooter />
          </div>
        </>
      );
    }
    else
    {
      return (
        <>
          <div className="main-wrapper">
            <header>
              <figure className="waves-top"></figure>
              <figure className="logo">
                <img src={Logo} alt="logo" />
              </figure>
              <AuthLinks />
            </header>

            {isLoading ? (
              <Loader />
                ) : (
                <></>
            )}

              <div className="mid-container">
                <div className="content">
                    <div className="row">
                        
                    </div>
                </div>            
              </div>
            <GeometryBundle2 />
            <CommonFooter />
          </div>
        </>
      );
    }
  }
}