import React, { Component } from "react";
import GreatJob from "./../../images/f-tuneup-img.gif";
import Logo from "./../../images/logo.png";
import Screen2 from "./../Screens/Screen2";
import Screen4 from "./../Screens/Screen4";
import AuthLinks from "./../AuthLinks";
import CommonFooter from "./../CommonFooter";
import GeometryBundle1 from "./../GeometryBundle1";

export default class Screen3 extends Component {
  constructor(props) 
  {
    super(props);
    this.state = { key: 2 };
  }

  handleSelect(key, screens) {
    this.props.toggleForm(screens);
    this.setState({ key });
  }

  clickBtn = e => {
    this.setState({
      renderView: +e.target.value
    });
  };

  render() {
    if(this.state.renderView === 2)
    {
      return <Screen2 />;
    }
    if(this.state.renderView === 4)
    {
      return <Screen4 />;
    }

    return (
      <>
        <div className="main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>

          <div className="mid-container">
            <div className="content">
                <div className="row align-self-center">
                    <div className="col-md-6 col-12">
                        <figure><img src={GreatJob} alt="Great Job" className="left-img img-shadow" /></figure>
                    </div>
                    <div className="col-md-6 col-12 align-self-center">
                        <div className="px-md-5 px-0 mt-sm-0 mt-4">
                          <p>Great Job!</p>
                          <p className="mt-md-3 mt-1">You just did your first Heartfulness<br/> Tune-Up</p>
                        </div>
                        <button value={2} className="btn btn-primary" onClick={this.clickBtn}>BACK</button>
                        <button value={4} className="btn btn-primary" onClick={this.clickBtn}>NEXT</button>
                    </div>
                </div>
            </div>     
          </div>
          <GeometryBundle1 />
          <CommonFooter />
        </div>      
      </>
    );
  }
}