import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import Logo from "./../images/logo.png";
import AuthLinks from "./AuthLinks";
import Loader from "./Loader";
import CommonFooter from "./CommonFooter";
import GeometryBundle3 from "./GeometryBundle2";
import parse from 'html-react-parser';

export default class CurrentMemberships extends Component {
  constructor(props) 
  {
    super(props);
    this.state = {
      redirect:false,
      isLoading:false,
      name:'',
      email:'',
      braintree_customer_id:'',
      subscriptions:'',
      membership_name:'',
      membership_start_date:'',
      membership_end_date:'',
      account_type:''
    };
  }
  
  async componentDidMount() 
  {
      this.setState({ isLoading:true });

      const [user_details, current_membership] = await Promise.all([
        axios.get('/user'),
        axios.get('/get_current_membership')
      ]);

      if(user_details.status === 200)
      {
          this.setState({
            name: user_details.data.data.name,
            email: user_details.data.data.email,
            braintree_customer_id: user_details.data.data.braintree_customer_id,
          });

          if(this.state.braintree_customer_id !== '' && this.state.braintree_customer_id !== null)
          {
            const subscriptions = await axios.get('/get_customer_subscriptions?customer_id='+this.state.braintree_customer_id);

            this.setState({
              subscriptions: subscriptions.data,
            });
          }

          setTimeout(
            function() {
              this.setState({ isLoading:false });
            }
            .bind(this),
            3000
          );
      }

      if(current_membership.status === 200 && current_membership.data)
      {
        console.log(current_membership.data);
        this.setState({
          membership_exists: true,
          membership_name: current_membership.data.current_membership_details.membership_name,
          membership_start_date: current_membership.data.current_membership_details.membership_start_date,
          membership_end_date: current_membership.data.current_membership_details.membership_end_date,
          membership_description: current_membership.data.current_membership_details.membership_description,
          membership_frequency: current_membership.data.current_membership_details.membership_frequency,
          subscription_status: current_membership.data.current_membership_details.subscription_status,
          subscription_id: current_membership.data.current_membership_details.transaction_id,
          account_type: current_membership.data.account_type,
        });
      }
      else
      {
        this.setState({
          membership_exists: false,
        });
      }
  }

  cancel_subscription = e => {
    const subscription_id = e.target.dataset.subscription_id;
    console.log(subscription_id);

    this.setState({ isLoading:true });
    axios
    .get("/cancel_subscription", {params: { subscription_id: subscription_id } })
    .then((response) => {
      if (response.status === 200) 
      {
        window.location.reload(false);
      }
    })
    .catch((error) => {
      this.setState({ isLoading: false });
    }); 
  };

  render() {
    const isLoading = this.state.isLoading;
    
    const login = localStorage.getItem("isLoggedIn");

    if(!login) 
    {
      return <Redirect to="/sign-in" />;
    }     

    const user = JSON.parse(localStorage.getItem("userData"));

    if(user && (user.email_verified_at === '' || user.email_verified_at === null))
    {
      return <Redirect to="/email-verification" push={true} />;
    }

    if(this.state.subscriptions)
    {
        var subscriptions_data = this.state.subscriptions.data.map((subscription,i) => {
        return (
            <div key={i}>
              <p>Card Type: {parse(subscription.cardType)}</p>
              <p>Card Expiration : {subscription.expirationDate}</p>
              <p className="mb-3">Card Number : {subscription.maskedNumber}</p>

              {subscription.subscription_details.map((subscription_detail,j) => {
              return (
                  <div key={j} className="mb-0">
                    <hr/>
                    <p className="mb-0">{subscription_detail.membership_name}</p>
                    <p className="mb-0">Subscription ID : {subscription_detail.subscription_id}</p>      
                    <p className="mb-0">Subscription Created On : {subscription_detail.subscription_created_date}</p>                                  
                    <p className="mb-0">Next Billing Date : {subscription_detail.next_billing_date}</p>
                    <p className="mb-0">Subscription Status : {subscription_detail.status}</p>
                    {subscription_detail.status === 'Active' && this.state.account_type !== 'child' ? (
                      <button className="btn btn-primary mt-2 ml-0" data-subscription_id ={subscription_detail.subscription_id} onClick={this.cancel_subscription}> Cancel Subscription</button>  
                      ) : (
                        <span></span>
                    )}
                  </div>
                );
              })}
            </div>
        );
      });
    }

    if(this.state.subscriptions)
    {
      return (
        <>
          <div className="main-wrapper another-main-wrapper">
            <header>
              <figure className="waves-top"></figure>
              <figure className="logo">
                <img src={Logo} alt="logo" />
              </figure>
              <AuthLinks />
            </header>

            {isLoading ? (
              <Loader />
              ) : (
                <span></span>
            )}

            <div className="mid-container library-container library_screen" ref={this.myRef}>
              <div className="content mt-3">
              <div className="video-listing-wrap"> 
                <div className="row">
                <div className="col-sm-4">
                  <div className="existing_membership">
                        <h4 className="membership_title">{this.state.membership_name}</h4>
                        <div dangerouslySetInnerHTML={{ __html: this.state.membership_description }} />
                        <h5>Membership Expiry Date</h5>
                        <p className="memebrship_dates">{this.state.membership_end_date} (EST)</p>
                        <h5>Membership Purchase Date</h5>
                        <p className="memebrship_dates">{this.state.membership_start_date} (EST)</p>
                        {this.state.subscription_status === 'ACTIVE' && this.state.account_type !== 'child' ? (
                          <button className="btn btn-primary cancel_subscription mt-4" data-subscription_id={this.state.subscription_id} onClick={this.cancel_subscription}>Cancel Subscription</button>
                          ) : (
                            <span></span>
                        )}
                  </div>
                </div>

                <div className="col-sm-8">
                  <div className="braintree_subscriptions">
                    <h4 className="membership_title">All Subscriptions</h4>
                      {subscriptions_data}
                  </div>
                </div>

                </div>
              </div>
              </div>
            </div>


            <GeometryBundle3 />
            <CommonFooter />
          </div>  
        </>
      );
    }
    else
    {
      return (
        <>
          <div className="main-wrapper another-main-wrapper">
            <header>
              <figure className="waves-top"></figure>
              <figure className="logo">
                <img src={Logo} alt="logo" />
              </figure>
              <AuthLinks />
            </header>

            {isLoading ? (
              <Loader />
              ) : (
                <span></span>
            )}

            <div className="mid-container setting-container" ref={this.myRef}>
              <div className="content mt-3">
                <div className="row">
                  <div className="existing_membership">
                        <h4 className="membership_title">{this.state.membership_name}</h4>
                        <div dangerouslySetInnerHTML={{ __html: this.state.membership_description }} />
                        <h5>Membership Expiry Date</h5>
                        <p className="memebrship_dates">{this.state.membership_end_date} (EST)</p>
                        <h5>Membership Purchase Date</h5>
                        <p className="memebrship_dates">{this.state.membership_start_date} (EST)</p>
                        {this.state.subscription_status === 'ACTIVE' && this.state.account_type !== 'child' ? (
                          <button className="btn btn-primary cancel_subscription mt-4" onClick={this.cancel_subscription}>Cancel Subscription</button>
                          ) : (
                            <span></span>
                        )}
                  </div>
                </div>
              </div>
            </div>


            <GeometryBundle3 />
            <CommonFooter />
          </div>  
        </>
      );
    }
  }
} 