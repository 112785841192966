import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import HeartfulnessGif from "./../../images/heartfulness.gif";
import Logo from "./../../images/logo.png";
import Screen2 from "./../Screens/Screen2";
import AuthLinks from "./../AuthLinks";
import CommonFooter from "./../CommonFooter";
import GeometryBundle2 from "./../GeometryBundle2";

export default class Screen1 extends Component {
  userData;
  constructor(props) 
  {
    super(props);
    this.state = {
      
    };
  }

  clickBtn = e => {
    this.setState({
      renderView: +e.target.value
    });
  };

  render() {
    const login = localStorage.getItem("isLoggedIn");

    if(login) 
    {
      return <Redirect to="/home" />;
    }

    if(this.state.renderView === 2)
    {
      return <Screen2 />;
    }
    return (
      <>
        <div className="main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>

          <div className="mid-container">
            <div className="content">
                <div className="row align-self-center">
                    <div className="col-md-6 col-12">
                        <figure><img src={HeartfulnessGif} alt="Heartfulness" className="left-img img-shadow" /></figure>
                    </div>
                    <div className="col-md-6 col-12 align-self-center">
                        <div className="px-md-5 px-0 mt-sm-0 mt-4">
                          <p>When we align with our Heart, then our  <br/>thoughts become clearer and more  <br/>uplifting.</p>
                          <p>Let’s do a quick tune-up to become more <br/> Heart-Centered.</p>
                        </div>
                        <button value={2} className="btn btn-primary" onClick={this.clickBtn}>Next</button>
                    </div>
                </div>
            </div>     
          </div>
          <GeometryBundle2 />
          <CommonFooter />
        </div>
      </>
    );
  }
}