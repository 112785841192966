import React, { Component } from "react";
import axios from "axios";
import { createBrowserHistory } from 'history';
import { Button, Form, FormGroup, Input } from "reactstrap";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { Redirect } from "react-router-dom";
import AuthLinks from "./../AuthLinks";
import Logo from "./../../images/logo.png";
import Loader from "./../Loader";
import CommonFooter from "./../CommonFooter";
import GeometryBundle3 from "./../GeometryBundle3";

export default class EmailVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigate: false,
      isLoading: false,
      msg: "",
      redirect: false,
      errMsgEmail: "",
      errMsg: "",
      updated_email: "",
      errors: {},
      show_resend:false,
      resend_msg : "",
      coupon_success_msg : "",
      coupon_error_msg : "",
    };
    this.user = JSON.parse(localStorage.getItem("userData"));
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    const history = createBrowserHistory();
    this.verification_val   = setTimeout(() => this.setState({ verification: true }), 100);
    this.refresh_status_val = setTimeout(() => this.setState({ refresh_status: true }), 100);

    if(this.props.location.state !== undefined)
    {
      if(this.props.location.state.soul_code !== '' && this.props.location.state.soul_code !== undefined)
      {
          axios
            .post('/save_user_soul_code', {
              coupon_code: this.props.location.state.soul_code,
            })
            .then((response) => {
              this.setState({ isLoading: false });
              if(response.status === 200) 
              {  
                if(history.location.state && history.location.state.soul_code) {
                  let state = { ...history.location.state };
                  delete state.soul_code;
                  history.replace({ ...history.location, state });
                }
                this.setState({ coupon_success_msg: response.data.success,isLoading: false });  
              }
            })
            .catch((error) => {
              this.setState({ isLoading: false });
    
              if(error.response.status === 400) 
              {
                this.setState({
                  coupon_error_msg: error.response.data.error,
                });
              }
            });
      }
      else{
        this.setState({ isLoading: false });
      }
    }
    else{
      this.setState({ isLoading: false });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.verification_val);
    this.setState({ refresh_status : false });
  }  

  onChangehandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };

  validateForm() 
  {
    let email_val = this.state.updated_email;
    let errors = {};
    let formIsValid = true;

    if (!email_val) 
    {
      formIsValid = false;
      errors["updated_email"] = "* Please enter your Email Address.";
    }

    if (typeof email_val !== "undefined") 
    {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

      if (!pattern.test(email_val)) 
      {
        formIsValid = false;
        errors["updated_email"] = "* Please enter valid Email Address.";
      }
    }  

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  refreshVerificationstatus = () => {
    const url = '/verify_email_status';
    this.setState({ isLoading: true });
    axios
      .get(url, {
        email: this.user.updated_email,
      })
      .then((response) => {
        this.setState({ isLoading: false });
        if(response.status === 200) 
        {
          var today = new Date(),
          date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();

          this.user.email_verified_at = date;
          localStorage.setItem("userData", JSON.stringify(this.user));

          this.setState({ msg: "",redirect: true });   
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        if(error.response.status === 400) 
        {
        }
      });
  }; 

  resend_email = () => {
    const resend_url = '/email/resend';
    this.setState({ isLoading: true });
    axios
      .get(resend_url, {})
      .then((response) => {
        this.setState({ isLoading: false });
        if(response.status === 200) 
        {
          this.setState({ resend_msg: "Email Resent Successfully." });    
        }
        setTimeout(() => {
          this.setState({ resend_msg: "" });
        }, 2000);
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        if(error.response.status === 400) 
        {
        }
      });
  }; 

  change_email_handler = () => {
    if (this.validateForm()) 
    {
      const url = '/update_email';
      this.setState({ isLoading: true });
      axios
        .post(url, {
          email: this.state.updated_email,
        })
        .then((response) => {
          this.setState({ isLoading: false });
          if(response.status === 200) 
          {
              //const received_data = JSON.stringify(response.data.data);
              //const received_success = response.data.success;

              this.user.email = this.state.updated_email;
              localStorage.setItem("userData", JSON.stringify(this.user));

              this.setState({
                msg: "Email Updated successfully.",
                updated_email: "",
              }); 

              setTimeout(() => {
                this.setState({ msg: "" });
              }, 2000);    
          }

          if(response.status === "failed" && response.success === undefined) 
          {
            this.setState({
              errMsgEmail: response.data.validation_error.email,
            });
            setTimeout(() => {
              this.setState({ errMsgEmail: "" });
            }, 2000);
          } 
          else if(response.status === "failed" && response.success === false) 
          {
            this.setState({
              errMsg: response.data.message,
            });
            setTimeout(() => {
              this.setState({ errMsg: "" });
            }, 2000);
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          if(error.response.status === 401) 
          {
            this.setState({
              errMsg: error.response.data.error,
            });
            setTimeout(() => {
              this.setState({ errMsg: "" });
            }, 5000);
          }

          if(error.response.status === 400) 
          {
            this.setState({
              errMsg: error.response.data.error.email,
            });
            setTimeout(() => {
              this.setState({ errMsg: "" });
            }, 5000);
          }
        });
    }
  };

  render() {
    const login = localStorage.getItem("isLoggedIn");
    const isLoading = this.state.isLoading;

    if(this.state.redirect && (this.user.has_suggested_journey === 'false' || this.user.has_suggested_journey === null))
    {
      return <Redirect to="/welcome" />;
    }
    else if(this.state.redirect && this.user.has_suggested_journey === 'true')
    {
      return <Redirect to="/home" />;
    }

    if(!login) 
    {
      return <Redirect to="/sign-in" />;
    }
    const { navigate } = this.state;

    if(navigate) 
    {
      return <Redirect to="/sign-in" push={true} />;
    }

    if(this.user && this.user.isEmailVerified && this.user.isEmailVerified !== "false")
    {
      return <Redirect to="/home" />;
    }
    return (
        <>
          <div className="main-wrapper">
            <header>
              <figure className="waves-top"></figure>
              <figure className="logo">
                <img src={Logo} alt="logo" />
              </figure>
              <AuthLinks />
            </header>

            {isLoading ? (
            <Loader />
              ) : (
              <></>
            )}

            <div className="mid-container email-verify-container">
              <div className="content">
                  <div className="row">

                      <div className="col-md-6 col-12 align-self-center">
                        <div className="email-verification-text">
                          <h3 className="font-weight-bold">Email Verification</h3>
                          <p className="font-weight-normal">To complete your registration, click the link<br/>
                              in the email sent to <b className="text-orange">{this.user.email}</b> from
                              <b className="text-orange"> support@socreates.com</b>
                          </p>

                          <div className="mt-md-4 mt-2">
                            <p className="mt-2 font-weight-normal">Didn't received the Verification Email?</p>
                            <button className="btn btn-primary" onClick={this.resend_email} >
                              <FontAwesomeIcon icon={faEnvelope} /> Resend Verification Email
                            </button>
                            <p className="text-success">{this.state.resend_msg}</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-12 align-self-center px-md-5 px-3 mt-1 mt-md-0">
                        <div className="email-verification-text">
                          
                            <div className="coupon_msg mb-2">
                              {this.state.coupon_success_msg ? (
                                <span className="text-success">{this.state.coupon_success_msg}</span>
                              ): (
                                <></>
                              )}
                              {this.state.coupon_error_msg ? (
                                <span className="text-danger">Entered Soul Code is Invalid.</span>
                                ): (
                                  <></>
                              )}
                            </div>

                            <p className="font-weight-normal">Email entered incorrectly? Re-enter it below.</p>
                              <Form className="mt-2">

                              <FormGroup className="text-left">
                                <Input
                                  type="email"
                                  name="updated_email"
                                  className=""
                                  placeholder="Enter Email Address *"
                                  value={this.state.updated_email}
                                  onChange={this.onChangehandler}
                                />
                                <span className="text-danger">{this.state.errors.updated_email}</span>
                              </FormGroup>

                              <div className="text-center">
                              <Button
                              className="btn btn-primary mx-2 email-icon"
                              onClick={this.change_email_handler}
                              >
                              <FontAwesomeIcon icon={faEnvelope} /> Submit
                              </Button>
                                {this.state.verification ? (
                                  <Button
                                  className="btn btn-primary mx-2 email-icon mt-md-3 mt-lg-0 dark-brown"
                                  onClick={this.refreshVerificationstatus}
                                  >
                                  <FontAwesomeIcon icon={faSync} /> Refresh
                                  </Button>
                                ) : (
                                  <span></span>
                                )}                                
                              </div>
                              <span className="text-danger text-left mt-3">{this.state.errMsg}</span>
                              <span className="text-success">{this.state.msg}</span>
                              </Form>
                              <p className="mt-3 font-weight-normal">* Tap "Refresh" button above to refresh the Screen after Verifying Email.</p>
                          
                        </div>
                      </div>
                  </div>
              </div>     
            </div>
            <GeometryBundle3 />
            <CommonFooter />
          </div>   
        </>
    );
  }
}