import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Logo from "./../images/logo.png";
import AuthLinks from "./AuthLinks";
import CommonFooter from "./CommonFooter";
import GeometryBundle3 from "./GeometryBundle3";

export default class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.user = JSON.parse(localStorage.getItem("userData"));
  }  
  state = {
    navigate: false,
  };

  clickBtn = e => {
    this.setState({ isLoading: true,redirect:true });
  };


  render() {
    
    const login = localStorage.getItem("isLoggedIn");
    if(!login) 
    {
      return <Redirect to="/sign-in" />;
    }
    return (
      <>
        <div className="main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>     

          <div className="mid-container">
            <div className="content mt-0">
                <div className="row align-self-center">
                    <div className="col-md-12 col-12">
                        <h4 className="mt-2 mb-4">Oops! Page Not Found</h4>
                    </div>
                </div>
            </div>     
          </div>
          <GeometryBundle3 />
          <CommonFooter />
        </div> 
      </>
    );
  }
}