import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Loader from "./Loader";
import Footer from "./Common/CommonCirclesFooter";
import Header from "./Common/CommonCirclesHeader";
import parse from 'html-react-parser';

export default class JourneyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      msg: "",
      redirect: false,
      journeys:[],
      journeys_html:''
    };
  }  

  componentDidMount() 
  {
    console.log(this.props);

    if(this.props.location.state)
    {
      console.log(this.props.location.state);
      const journeys_array = this.props.location.state;
      this.setState({ journeys: journeys_array });
    }

    if(this.props.match.params.journeys)
    {
      console.log(this.props.journeys);
    }
  }

  render() 
  {
    const user = JSON.parse(localStorage.getItem("userData"));

    const isLoading = this.state.isLoading;
    const baseURL = window.baseURL;

    if(user && this.state.has_suggested_journey === 'false')
    {
      return <Redirect to="/welcome" push={true} />;
    }

    if(user && (user.email_verified_at === '' || user.email_verified_at === null))
    {
      return <Redirect to="/email-verification" push={true} />;
    }
 
    const login = localStorage.getItem("isLoggedIn");

    if(!login) 
    {
      return <Redirect to="/sign-in" />;
    }

    if(this.state.journeys)
    {
        var journeys_html = this.state.journeys.map((journey,i) => {
        return (
            <div className="col-6 d-inline-block text-center" key={i}>
              <img src={baseURL+'/'+journey.icon} alt={journey.journeyName} className="img-journey" width='300' />
              <div className="mt-3 text-center description_centered">{parse(journey.journey_description)}</div>
            </div>
        );
      });
    }

    return (
      <>
        <div className="level-2-wrapper">
          <Header />

          {isLoading ? (
            <Loader />
              ) : (
              <></>
          )}

          <div className="container h-100">
              <div className="row h-100 justify-content-center">   
                <div class="col-8 align-self-center">                   
                  <div className="mid-content-wrapper text-center">
                      <div className="row">
                          {journeys_html}
                      </div>
                  </div>  
                </div>  
              </div>
          </div>

          <Footer />
        </div>
      </>
    );
  }
}