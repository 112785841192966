import React, { Component } from "react";
import { Redirect,Link } from "react-router-dom";
import Loader from "./Loader";
import Footer from "./Common/CommonCirclesFooter";
import Header from "./Common/CommonCirclesHeader";
import APICalls from "./apis/APICalls";

export default class ChooseJourney extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigate: false,
      isLoading: false,
      msg: "",
      redirect: false,
      journeys:[],
      journeys_html:'',
      journey_details: false
    };
    this.user = JSON.parse(localStorage.getItem("userData"));
  }  

  async componentDidMount() 
  {
    this.setState({ isLoading: true }); 
    const api = new APICalls();

    await api.getCurrentMembershipDetails()
                                    .then((response) => {
                                      if(response.status === 200) 
                                      {
                                        console.log(response);
                                        localStorage.setItem("plan_id", JSON.stringify(response.data.current_membership_details.membership_id));
                                      }
                                    })
                                    .catch((error) => {
                                      this.setState({ isLoading: false });
                                    }); 

    await api.getUserDetails()
                      .then((response1) => {
                        if (response1.status === 200) 
                        {
                          this.setState({ user_name:response1.data.data.name  });
                          localStorage.setItem("userData", JSON.stringify(response1.data.data));
                          localStorage.setItem("membership_array", JSON.stringify(response1.data.membership));
                        }
                      })
                      .catch((error) => {
                        this.setState({ isLoading: false });
                      });   
                      
    await api.getActiveJourneys()
                      .then((response2) => {
                        if (response2.status === 200) 
                        {
                          this.setState({ journeys:response2.data.data  });
                        }
                      })
                      .catch((error) => {
                        this.setState({ isLoading: false });
                      });   
    this.setState({ isLoading: false });                                           
  }

  componentWillUnmount() 
  {
    this.setState({ isLoading: false }); 
  }

  redirect_to_journey = e => {
    const journey_id   = e.target.dataset.journey_id;
    const journey_name = e.target.dataset.journey_name;

    console.log(journey_id);
    console.log(journey_name);
  }

  redirect_to_journey_details = e => {
    this.setState({ journey_details: true }); 
  }

  render() 
  {
    const user = JSON.parse(localStorage.getItem("userData"));

    const { navigate } = this.state;
    const isLoading = this.state.isLoading;

    const baseURL = window.baseURL;

    if(user && this.state.has_suggested_journey === 'false')
    {
      return <Redirect to="/welcome" push={true} />;
    }

    if(user && (user.email_verified_at === '' || user.email_verified_at === null))
    {
      return <Redirect to="/email-verification" push={true} />;
    }

    if(navigate) 
    {
      return <Redirect to="/sign-in" push={true} />;
    }

    if(this.state.journey_details)
    {
      return <Redirect to={{
          pathname: "/journey-details",
          state: this.state.journeys // your data array of objects
        }}
      />;
    }

    if(this.state.journeys)
    {
        var journeys_html = this.state.journeys.map((journey,i) => {
        return (
            <div className="col-6 d-inline-block" key={i}>
                  <Link to="#" className="d-block" onClick={this.redirect_to_journey} data-journey_id={journey.journeyID} data-journey_name={journey.journeyName}>
                      <img src={baseURL+'/'+journey.icon} alt={journey.journeyName} className="img-journey" width='300' />
                      <p className="mt-3">{journey.journeyName}</p>
                  </Link>
            </div>
        );
      });
    }
  
    const login = localStorage.getItem("isLoggedIn");

    if(!login) 
    {
      return <Redirect to="/sign-in" />;
    }

    return (
      <>
        <div className="level-2-wrapper">
          <Header />

          {isLoading ? (
            <Loader />
              ) : (
              <></>
          )}

          <div className="container h-100">
              <div className="row h-100 justify-content-center">
                  <div className="col-xl-6 col-lg-8 col-sm-10 align-self-center">
                      
                      <div className="mid-content-wrapper text-center">
                          <div className="row">
                              <div className="col-12 mb-4">
                                  {journeys_html}
                              </div>

                              <div className="mt-sm-5 mt-3 w-100"></div>

                              <div className="col-12 text-center">
                                  <div><button className="btn btn-primary" onClick={this.redirect_to_journey_details}>Don't know which journey to choose?</button></div>
                                  <div><Link to="#" className="support-link">Need Technical Support?</Link></div>
                              </div>
                          </div>
                      </div>  
                  </div>
              </div>
          </div>

          <Footer />
        </div>
      </>
    );
  }
}