import React, { Component } from "react";
import Logo from "./../../images/logo.png";
import AuthLinks from "./../AuthLinks";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { Button, Form } from "reactstrap";
import AmazingWork from "./../Screens/AmazingWork";
import Loader from "./../Loader";
import CommonFooter from "./../CommonFooter";
import GeometryBundle2 from "./../GeometryBundle2";
import APICalls from "./../apis/APICalls";

export default class AssessmentQuestions extends Component {
  constructor(props) 
  {
    super(props);
    this.state = { isLoading:false,question:"",question_no:1,next_assessment_question_id:7,current_ques_id:7,errMsg: "",errors: {},data: {},redirect:false,subjective_answer_1:'',subjective_answer_2:'',subjective_answer_3:'' };

    this.onChangehandler = this.onChangehandler.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    axios
    .get("/getAssessmentNextJourneyRoute", {
      params: { 
        next_assessment_question_id: this.state.next_assessment_question_id
      }
    })
    .then((response) => {
      this.setState({ isLoading: false });
      if (response.status === 200)
      {
        this.setState({ question: response.data.question.name,next_assessment_question_id: response.data.question.next_ques_id, errMsgSubjectiveAnswer1:"",errMsgSubjectiveAnswer2:"",errMsgSubjectiveAnswer3:"" });
      }

      if (response.status === "failed") 
      {

      }
    })
    .catch((error) => {
      this.setState({ isLoading: false });
    });
  }

  onChangehandler = (e) => {
    let name  = e.target.name;
    let value = e.target.value;

    let data = {};
    data[name] = value;
    this.setState(data);
  };

  validateForm() 
  {
    let subjective_answer_1 = this.state.subjective_answer_1;
    let subjective_answer_2 = this.state.subjective_answer_2;
    let subjective_answer_3 = this.state.subjective_answer_3;
    let errors = {};
    let formIsValid = true;

    if (!subjective_answer_1) 
    {
      formIsValid = false;
      errors["subjective_answer_1"] = "* Please enter Answer 1.";
    }

    if (!subjective_answer_2) 
    {
      formIsValid = false;
      errors["subjective_answer_2"] = "* Please enter Answer 2.";
    }

    if (!subjective_answer_3) 
    {
      formIsValid = false;
      errors["subjective_answer_3"] = "* Please enter Answer 3.";
    }    

    this.setState({
      errors: errors,
      isLoading: false,
    });
    return formIsValid;
  }

  clickBtn = e => {
    this.setState({ isLoading: true });

    if(this.validateForm()) 
    {
      const url = '/save_user_assessment_answers';
      this.setState({ isLoading: true });
      axios
        .post(url, {
          answer_id: 0,
          question_type: 'subjective',
          subjective_answer_1: this.state.subjective_answer_1,
          subjective_answer_2: this.state.subjective_answer_2,
          subjective_answer_3: this.state.subjective_answer_3,
          question_id: this.state.current_ques_id,
        })
        .then((response) => {
          if(response.status === 200) 
          {
              this.setState({
                question_id: '',
                answer_id: '',
                question_type: '',
                subjective_answer_1: '',
                subjective_answer_2: '',
                subjective_answer_3: '',
              });  
              document.getElementById("submit_assessment_form").reset();
          }

          if(response.status === 400) 
          {
              this.setState({
                question_id: '',
                answer_id: '',
                question_type: '',
                subjective_answer_1: '',
                subjective_answer_2: '',
                subjective_answer_3: '',
              });      
          }

          if(response.status === "failed" && response.success === undefined) 
          {
            this.setState({
              errMsgSubjectiveAnswer1: response.data.validation_error.subjective_answer_1,
              errMsgSubjectiveAnswer2: response.data.validation_error.subjective_answer_2,
              errMsgSubjectiveAnswer3: response.data.validation_error.subjective_answer_3,
            });
            setTimeout(() => {
              this.setState({ errMsgSubjectiveAnswer1: "", errMsgSubjectiveAnswer2: "", errMsgSubjectiveAnswer3 : "" });
            }, 2000);
          } 
          else if(response.status === "failed" && response.success === false) 
          {
            this.setState({
              errMsg: response.data.message,
            });
            setTimeout(() => {
              this.setState({ errMsg: "" });
            }, 2000);
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
        });          

        this.setState({ current_ques_id: this.state.next_assessment_question_id });    

        if(this.state.next_assessment_question_id > 9 || this.state.next_assessment_question_id === -1)
        {
          /******************* Make Assessment Done ******************/
          const complete_assessment_url = '/journeys_suggested_to_users';
          axios
            .post(complete_assessment_url, {
              is_assessment_done: 'true',
            })
            .then((response) => {
              if(response.status === 200) 
              {

              }

              if(response.status === 400) 
              {
                return false;
              }

              if(response.status === "failed" && response.success === undefined) 
              {
                return false;
              } 
              else if(response.status === "failed" && response.success === false) 
              {
                return false;
              }
            })
            .catch((error) => {
              this.setState({ isLoading: false });
            });  

          /********************* Get Membership Id for Free Membership **********************/
          const api = new APICalls();
          
          api.getCurrentMembershipDetails().then((response) => {
              if(response.status === 200) 
              {
                console.log(response.data);
                /********************* Assign Free Membership to User **********************/
                if(response.data.account_type !== 'child')
                {
                  const assign_free_membership = '/buyMembership';
                  axios
                    .post(assign_free_membership,{ membershipID:1,transactionNo:'web111111222',amountPaid:'0' })
                    .then((response) => {
                      

                      if(response.status === 200) 
                      {
                        this.setState({ isLoading: false });
                        this.setState({ redirect: true });
                      }

                      if(response.status === 400) 
                      {
                        return false;
                      }

                      if(response.status === "failed" && response.success === undefined) 
                      {
                        return false;
                      } 
                      else if(response.status === "failed" && response.success === false) 
                      {
                        return false;
                      }
                    })
                    .catch((error) => {
                      this.setState({ isLoading: false });
                    }); 
                  }
                  else
                  {
                      this.setState({ isLoading: false });
                      this.setState({ redirect: true });
                  }
              }

              if(response.status === "failed" && response.success === undefined) 
              {
                return false;
              } 
              else if(response.status === "failed" && response.success === false) 
              {
                return false;
              }
            })
            .catch((error) => {
              console.log(error.response.status);
              if(error.response.status === 400) 
              {
                    const assign_free_membership = '/buyMembership';
                    axios
                    .post(assign_free_membership,{ membershipID:1,transactionNo:'web111111222',amountPaid:'0' })
                    .then((response) => {
                      

                      if(response.status === 200) 
                      {
                        this.setState({ isLoading: false });
                        this.setState({ redirect: true });
                      }

                      if(response.status === 400) 
                      {
                        return false;
                      }

                      if(response.status === "failed" && response.success === undefined) 
                      {
                        return false;
                      } 
                      else if(response.status === "failed" && response.success === false) 
                      {
                        return false;
                      }
                    })
                    .catch((error) => {
                      this.setState({ isLoading: false });
                    }); 
              }
              this.setState({ isLoading: false });
            });            

            return false;
        }

        this.setState({
          question_no: this.state.question_no + 1,
        });

        axios
        .get("/getAssessmentNextJourneyRoute", {
          params: { 
            next_assessment_question_id: this.state.next_assessment_question_id
          }
         })
        .then((response) => {
          this.setState({ isLoading: false });
          if (response.status === 200)
          {
            this.setState({ question: response.data.question.name,next_assessment_question_id: response.data.question.next_ques_id });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
        });
    }
  };

  render() {
    const isLoading = this.state.isLoading;
    const user_details = JSON.parse(localStorage.getItem("userData"));

    if(user_details && user_details.has_suggested_journey && user_details.has_suggested_journey === 'true')
    {
      return <Redirect to="/home" />;
    }

    if(this.state.redirect)
    {
      return <AmazingWork />;
    }
    return (
      <>
        <div className="main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>

          {isLoading ? (
              <Loader />
            ) : (
              <span></span>
          )}          

          <div className="mid-container question-container">
            <div className="content mt-0">
                <div className="row align-self-center">
                    <div className="col-md-6 col-sm-12 col-12 mx-auto">
                        <div className="text-center">
                            <p>Question {this.state.question_no}/3</p>
                            <p className="mt-2 text-center">{this.state.question}</p>
                            <Form className="mt-4 invitation-form" id="submit_assessment_form">
                              <input type="hidden" name="question_id" value={this.state.current_ques_id} onChange={this.onChangehandler} />
                              <div className="form-group text-left">
                                  <textarea className="form-control" name="subjective_answer_1" rows={3} placeholder="Enter Here" onBlur={this.onChangehandler} maxLength="160"/>
                                  <span className="text-danger">{this.state.errMsgSubjectiveAnswer1}</span>
                                  <span className="text-danger">{this.state.errors.subjective_answer_1}</span>
                              </div>
                              <div className="form-group text-left">
                                  <textarea className="form-control" name="subjective_answer_2" rows={3} placeholder="Enter Here" onBlur={this.onChangehandler} maxLength="160"/>
                                  <span className="text-danger">{this.state.errMsgSubjectiveAnswer2}</span>
                                  <span className="text-danger">{this.state.errors.subjective_answer_2}</span>
                              </div>
                              <div className="form-group text-left">
                                  <textarea className="form-control" name="subjective_answer_3" rows={3} placeholder="Enter Here" onBlur={this.onChangehandler} maxLength="160"/>
                                  <span className="text-danger">{this.state.errMsgSubjectiveAnswer3}</span>
                                  <span className="text-danger">{this.state.errors.subjective_answer_3}</span>
                              </div>
                          
                              <div className="mt-3 bottom-btns">
                              <Button
                                className="btn btn-primary"
                                color="success"
                                onClick={this.clickBtn}
                                value={this.state.question_no}
                              >NEXT</Button>
                              </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>     
          </div>
          <GeometryBundle2 />
          <CommonFooter />
        </div> 
      </>
    );
  }
}