import React, { Component } from "react";
import Namaste from "./../../images/namaste.gif";
import Logo from "./../../images/logo.png";
import Screen3 from "./../Screens/Screen3";
import Screen5 from "./../Screens/Screen5";
import AuthLinks from "./../AuthLinks";
import CommonFooter from "./../CommonFooter";
import GeometryBundle2 from "./../GeometryBundle2";

export default class Screen4 extends Component {
  userData;
  constructor(props) 
  {
    super(props);
    this.state = { };
  }

  clickBtn = e => {
    this.setState({
      renderView: +e.target.value
    });
  };

  render() {
    if(this.state.renderView === 3)
    {
      return <Screen3 />;
    }
    if(this.state.renderView === 5)
    {
      return <Screen5 />;
    }

    return (
      <>
        <div className="main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>

          <div className="mid-container">
            <div className="content">
                <div className="row align-self-center">
                    <div className="col-md-6 col-12">
                        <figure><img src={Namaste} alt="Namaste" className="left-img img-shadow" /></figure>
                    </div>
                    <div className="col-md-6 col-12 align-self-center">
                        <div className="px-md-5 px-0 mt-sm-0 mt-4">
                          <p>Do you know the difference between being Heart-Centered and Ego-Centered?</p>
                        </div>
                          <button value={3} className="btn btn-primary" onClick={this.clickBtn}>BACK</button>
                          <button value={5} className="btn btn-primary" onClick={this.clickBtn}>NEXT</button>
                    </div>
                </div>
            </div>     
          </div>
          <GeometryBundle2 />
          <CommonFooter />
        </div>         
      </>
    );
  }
}