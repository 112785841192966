import React, { Component } from "react";
import { Redirect,Link } from "react-router-dom";
import axios from "axios";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faNewspaper } from "@fortawesome/free-solid-svg-icons";
import { faIdCard } from "@fortawesome/free-solid-svg-icons";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from "./../images/logo.png";
import AuthLinks from "./AuthLinks";
import Loader from "./Loader";
import CommonFooter from "./CommonFooter";
import GeometryBundle3 from "./GeometryBundle2";
import APICalls from "./apis/APICalls";

export default class Settings extends Component {
  constructor(props) 
  {
    super(props);
    this.state = {
      redirect:false,
      isLoading:false,
      user_unique_custom_id:'',
      name:'',
      email:'',
      phone:'',
      profileImage:'',
      countryCode:'',
      membership_name:'',
      membership_price:'',
      membership_id:1,
      account_type:''
    };
  }
  
  async componentDidMount() 
  {
      this.setState({ isLoading:true });

      const api = new APICalls();

      await axios.get('/user', {})
      .then((response) => {
        if(response.status === 200) 
        {           
          this.setState({
            user_unique_custom_id: response.data.data.user_unique_custom_id,
            name: response.data.data.name,
            email: response.data.data.email,
            phone: response.data.data.phone,
            countryCode: response.data.data.countryCode,
            profileImage: response.data.data.profileImage,
            membership_name: response.data.membership[0].name,
            membership_price: response.data.membership[0].price,
            membership_id: response.data.membership[0].id,
          });

          api.getCurrentMembershipDetails()
          .then((response) => {
            if(response.status === 200) 
            {
              this.setState({ account_type:response.data.account_type,isLoading: false });
            }
          })
          .catch((error) => {
            this.setState({ isLoading: false });
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  }

  clickBtn = e => {
    this.setState({
      renderView: +e.target.value
    });
  };

  editprofile = e => {

  };

  render() {
    const isLoading = this.state.isLoading;
    
    const login = localStorage.getItem("isLoggedIn");
    const baseapiURLMedia = window.baseapiURLMedia;

    if(!login) 
    {
      return <Redirect to="/sign-in" />;
    }     

    const user = JSON.parse(localStorage.getItem("userData"));

    if(user && (user.email_verified_at === '' || user.email_verified_at === null))
    {
      return <Redirect to="/email-verification" push={true} />;
    }

    return (
      <>
        <div className="main-wrapper another-main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>

          {isLoading ? (
            <Loader />
            ) : (
              <span></span>
          )}

          <div className="mid-container setting-container" ref={this.myRef}>
            <div className="content mt-3">
              <div className="row">

                <div className="col-lg-3 col-md-4">
                  <div className="settings-left-panel h-100">
                    <h5 className="text-center">My Profile</h5>
                    <figure className="text-center">
                      {this.state.profileImage ? (
                        <>
                        <img src={baseapiURLMedia+this.state.profileImage} className="settings_profile_image" alt="Profile" />
                        </>
                      ) : (
                        <>
                        <img src={baseapiURLMedia+'default-profile-image.svg'} className="settings_profile_image" alt="Profile" />
                        </>
                      )}
                    </figure>
                    <ul className="list-unstyled mt-3">
                      <li>
                        <FontAwesomeIcon icon={faUser} className="mr-2"/> {this.state.name}
                      </li>
                      <li>
                        <FontAwesomeIcon icon={faEnvelope} className="mr-2" /> {this.state.email}
                      </li>
                      {this.state.countryCode ? (
                        <li>
                          <FontAwesomeIcon icon={faPhone} className="mr-2" /> +{this.state.countryCode} - {this.state.phone}
                        </li>
                        ) : (
                        <></>
                      )}                     
                    </ul>
                    <Link to='edit-profile' className="btn btn-primary mx-auto"><FontAwesomeIcon icon={faEdit} /> Edit</Link>
                  </div>
                </div>

                <div className="col-lg-9 col-md-8 mt-sm-0 mt-3">
                    <div className="setting-right-panel">
                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <h4>Unique User ID</h4>
                                <p><FontAwesomeIcon icon={faIdCard} className="mr-2"/> {this.state.user_unique_custom_id}</p>
                            </div>
                            <hr className="w-100 my-3 d-lg-none" />
                            <div className="col-lg-6 col-12 mt-3 mt-sm-0">
                                <h4>Membership Plan</h4>
                                <p><FontAwesomeIcon icon={faCreditCard} className="mr-2"/> {this.state.membership_name} - $ {this.state.membership_price}
                                {this.state.membership_id === 1 ? (
                                <Link to='/membership-upgrade' className="btn btn-primary btn-upgrade"><FontAwesomeIcon icon={faCreditCard} /> Upgrade</Link>
                                  ) : (
                                    <>
                                    </>
                                  )
                                }
                                </p>
                            </div>
                            
                            <div className="col-12">
                                <hr className="w-100 my-3" />
                                <div className="public_pro">
                                  <h4>Public Information</h4>
                                  <Link to='edit-public-profile' className="btn btn-primary"><FontAwesomeIcon icon={faEdit} /> Edit</Link>
                                </div>

                                {this.state.account_type === 'parent' ? (
                                  <>
                                    <hr className="my-3" />
                                    <div className="public_pro">
                                      <h4>View/Edit Family Plan Members</h4>
                                      <Link to='edit-members' className="btn btn-primary"><FontAwesomeIcon icon={faEdit} /> Click to View/Edit Members</Link>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                                <hr className="w-100 my-3" />
                                <h4 className="faqs-link"><Link to="#" onClick={()=> window.open("https://socreates.com/faqs", "_blank")}><FontAwesomeIcon icon={faInfoCircle} className="mr-2"/>FAQs and Help Center</Link></h4>
                                <hr className="my-3" />
                                <h4>Policies</h4>
                                <ul className="list-unstyled policies-link mt-2">
                                    <li><Link to="/privacy-policy"><FontAwesomeIcon icon={faLock} className="mr-2"/>Privacy Policy</Link></li>
                                    <li><Link to="/terms-of-use"><FontAwesomeIcon icon={faNewspaper} className="mr-2"/>Terms of Use</Link></li>
                                    <li><Link to="#" onClick={()=> window.open("mailto:socreatesfeedback@kitlabs.us", "_blank")}><FontAwesomeIcon icon={faInfoCircle} className="mr-2"/>Feedback</Link></li>
                                </ul>
                            </div>
                        </div>                      
                    </div>
                </div>

              </div>
            </div>
          </div>


          <GeometryBundle3 />
          <CommonFooter />
        </div>  
      </>
    );
  }
} 