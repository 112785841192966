import React, { Component } from "react";
import WelcomeGif from "./../images/welcome.gif";
import Logo from "./../images/logo.png";
import Screen1 from "./Screens/Screen1";
import Screen2 from "./Screens/Screen2";
import CommonFooter from "./CommonFooter";
import GeometryBundle1 from "./GeometryBundle1";

export default class Welcome extends Component {
  constructor(props) 
  {
    super(props);
    this.state = {
      redirect: false,
      renderView:1
    };
  }

  componentDidMount() {
    this.id = setTimeout(() => this.setState({ redirect: true }), 2000)
  }
  componentWillUnmount() {
    clearTimeout(this.id)
  }

  clickBtn = e => {
    this.setState({
      renderView: +e.target.value
    });
  };

  render() {
    if(this.state.redirect) 
    {
      return <Screen1 />;
    }
    if(this.state.renderView === 2)
    {
      return <Screen2 />;
    }
    return (
      <>
        <div className="main-wrapper">
          <header>
              <figure className="waves-top"></figure>
              <figure className="logo">
                  <img src={Logo} alt="logo" />
              </figure>
          </header>

          <div className="mid-container welcome-container">
            <div className="content">
              <h4>Perspective Hacks</h4>
              <figure><img src={WelcomeGif} alt="welcome" className="welcome-img" /></figure>
              <h4>to Align with your Higher Self</h4>
            </div>          
          </div>
          <GeometryBundle1 />
          <CommonFooter />
        </div>
      </>
    );
  }
}