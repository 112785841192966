import React, { Component } from "react";
import Logo from "./../../images/logo.png";
import Screen1 from "./../Screens/Screen1";
import Screen3 from "./../Screens/Screen3";
import VimeoCons from "./../VimeoCons";
import AuthLinks from "./../AuthLinks";
import CommonFooter from "./../CommonFooter";
import GeometryBundle3 from "./../GeometryBundle3";

export default class Screen2 extends Component {
  constructor(props) 
  {
    super(props);
    this.state = { key: 2 };
  }

  handleSelect(key, screens) {
    this.props.toggleForm(screens);
    this.setState({ key });
  }

  clickBtn = e => {
    this.setState({
      renderView: +e.target.value
    });
  };

  render() {
    if(this.state.renderView === 1)
    {
      return <Screen1 />;
    }
    if(this.state.renderView === 3)
    {
      return <Screen3 />;
    }

    return (
      <>
        <div className="main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>

            <div className="mid-container">
              <div className="content">
                  <div className="row">
                      <div className="col-md-6 col-12 onboarding">
                          <figure><VimeoCons get_thumbnail="TRUE" get_video_with_subtitles="TRUE" video_url="https://vimeo.com/498015241/5714fcaddc" /></figure>
                      </div>
                      <div className="col-md-6 col-12 align-self-center">
                          <div className="px-md-5 px-0">
                            <p>33 Second Tune-Up</p>
                          </div>
                          <button value={1} className="btn btn-primary" onClick={this.clickBtn}>BACK</button>
                          <button value={3} className="btn btn-primary" onClick={this.clickBtn}>NEXT</button>
                      </div>
                  </div>
              </div>            
            </div>
          <GeometryBundle3 />
          <CommonFooter />
        </div>
      </>
    );
  }
}