import React, { Component } from "react";
import { Redirect,Link } from "react-router-dom";
import axios from "axios";
import { Form,Button } from "reactstrap";
import Header from "./Common/CommonCirclesHeader";
import Footer from "./Common/CommonCirclesFooter";
import Loader from "./Loader";
import APICalls from "./apis/APICalls";

export default class FamilyMembersEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navigate: false,
      isLoading: false,
      msg: "",
      redirect : false,
      redirect_to_home : false,
      membership_id:'',
      price:'',
      plan_id:'',
      errors: {},
      errMsg:'',
      successMsg:'',
      redirect_to_checkout:false,
      redirect_to_add_members:false,
      member_name:[],
      member_email:[],
      member_1_name:'',
      member_1_email:'',
      member_2_name:'',
      member_2_email:'',
      member_3_name:'',
      member_3_email:'',
      existing_member_1_name:'',
      existing_member_1_email:'',
      existing_member_2_name:'',
      existing_member_2_email:'',
      existing_member_3_name:'',
      existing_member_3_email:'',
    };

    this.user = JSON.parse(localStorage.getItem("userData"));
  }  


  async componentDidMount() 
  {
    this.setState({ isLoading: true });

    const api = new APICalls();

    await api.getCurrentMembershipDetails()
    .then((response) => {
      if(response.status === 200) 
      {
        this.setState({ membership_id:response.data.current_membership_details.membership_id });
        //localStorage.setItem("plan_id", JSON.stringify(response.data.current_membership_details.membership_id));
      }
    })
    .catch((error) => {
      //this.setState({ isLoading: false });
    }); 

    await axios
    .get("/get_current_family_members", {})
    .then((response) => {
      this.setState({ isLoading: false });
      if(response.status === 200)
      {
        var data_received = response.data.data;

        if(data_received.length > 0)
        {
          data_received.forEach((existing_entry,i) => {
            if(i === 0)
            {
              this.setState({ existing_member_1_name: existing_entry.name,existing_member_1_email: existing_entry.email });
            }
            if(i === 1)
            {
              this.setState({ existing_member_2_name: existing_entry.name,existing_member_2_email: existing_entry.email });
            }
            if(i === 2)
            {
              this.setState({ existing_member_3_name: existing_entry.name,existing_member_3_email: existing_entry.email });
            }
          });
          //this.setState({ isLoading:false });
        }
      }

      if(response.status === "failed") 
      {

      }
    })
    .catch((error) => {
      if(error.response) 
      {
        console.log(error.response.status)
        console.log(error.response.data)
        console.log(error.response.data.error)
      }
      this.setState({ isLoading: false });
    });
  }

  onChangehandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if(name==='member_1_name')
    {
      this.setState({ member_1_name:value });
    }
    if(name==='member_1_email')
    {
      this.setState({ member_1_email:value });
    }

    if(name==='member_2_name')
    {
      this.setState({ member_2_name:value });
    }
    if(name==='member_2_email')
    {
      this.setState({ member_2_email:value });
    }

    if(name==='member_3_name')
    {
      this.setState({ member_3_name:value });
    }
    if(name==='member_3_email')
    {
      this.setState({ member_3_email:value });
    }
  };

  upgrade_membership = e => {
    this.setState({ redirect_to_checkout:true });
  };  

  emailValidation(email)
  {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if(!pattern.test(email)) 
    {
      console.log('here');
        this.setState({
            error: "Email is not valid"
        });
        return false;
    }
    return true;
  }

  add_members_and_checkout = e => {
    if(this.state.member_1_email !== '' && !this.emailValidation(this.state.member_1_email))
    {
      this.setState({ errMsg:'Please enter valid Email Address.' });
      return false;
    }

    if(this.state.member_2_email !== '' && !this.emailValidation(this.state.member_2_email))
    {
      this.setState({ errMsg:'Please enter valid Email Address.' });
      return false;
    }

    if(this.state.member_3_email !== '' && !this.emailValidation(this.state.member_3_email))
    {
      this.setState({ errMsg:'Please enter valid Email Address.' });
      return false;
    }

    var members_array = [];
    if(this.state.member_1_email !== '')
    {
      var members_1_array = { "name": this.state.member_1_name, "email": this.state.member_1_email };
      members_array.push(members_1_array);
    }

    if(this.state.member_2_email !== '')
    {
      var members_2_array = { "name": this.state.member_2_name, "email": this.state.member_2_email };
      members_array.push(members_2_array);
    }

    if(this.state.member_3_email !== '')
    {
      var members_3_array = { "name": this.state.member_3_name, "email": this.state.member_3_email };
      members_array.push(members_3_array);
    }

    if(members_array.length > 0)
    {
      var json_members_array = JSON.stringify(members_array);

      this.setState({ isLoading: true });
      axios
        .post('/save_family_plan_members', {
          membership_id: this.state.membership_id,
          save_type: 'edit',
          family_members_data: json_members_array
        })
        .then((response) => {
          if(response.status === 200) 
          {
              this.setState({
                successMsg:'Member Details Saved Successfully',
                isLoading:false
              });  
              document.getElementById("submit_members_add_form").reset();
          }

          if(response.status === "failed" && response.success === undefined) 
          {
            this.setState({

            });
          } 
          else if(response.status === "failed" && response.success === false) 
          {
            this.setState({
              errMsg: response.data.message,
            });
            setTimeout(() => {
              this.setState({ errMsg: "" });
            }, 2000);
          }
        })
        .catch((error) => {
          if(error.response.status === 400)
          {
            this.setState({ isLoading: false,errMsg:error.response.data.error });
          }
          else
          {
            this.setState({ isLoading: false,errMsg:'' });
          }
        });
      }
      else
      {
        this.setState({ isLoading: false,errMsg: 'Please add atleast 1 member to Continue.'});
      }
  };  

  render() {
    const user = JSON.parse(localStorage.getItem("userData"));
    const isLoading = this.state.isLoading;

    if(user && (user.email_verified_at === '' || user.email_verified_at === null))
    {
      return <Redirect to="/email-verification" push={true} />;
    }
    
    const login = localStorage.getItem("isLoggedIn");

    if(!login) 
    {
      return <Redirect to="/sign-in" />;
    }

    if(this.state.redirect_to_home) 
    {
      return <Redirect to="/home" />;
    }

    if(this.state.redirect_to_checkout) 
    {
        return <Redirect to={{
          pathname: '/checkout',
          state: { plan_id:this.state.plan_id,membership_id:this.state.membership_id,membership_price:this.state.membership_price }
            }}
        /> 
    }    

    return (
      <>
        <div className="level-2-wrapper">
        <Header />

          {isLoading ? (
            <Loader />
            ) : (
              <span></span>
          )}

          <div className="scrollable-element membership-scroll-wrap">
            <div className="container h-100">
                <div className="row h-100 justify-content-center">
                    <div className="col-12 align-self-center">
                        <div className="mid-content-wrapper">
                            <h5 className="text-center mb-4">Edit/View Members to your Group</h5> 
                            <p className="mb-0">Invite upto 3 others with your Socreates Group Plan.</p>
                            <p>Remember: all answers and recordings are kept private and can't be accessed by others.</p>
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                <Form className="mt-4 invitation-form mb-3" id="submit_members_add_form">

                                  <div className="form-group text-left">
                                      <div className="col-sm-6 d-inline-block">
                                        {this.state.existing_member_1_name !== '' ? (
                                          <input type="text" name="member_1_name" className="form-control member_name" placeholder="Enter Member 1 Name" value={this.state.existing_member_1_name} disabled/>
                                          ) : (
                                            <input type="text" name="member_1_name" className="form-control member_name" placeholder="Enter Member 1 Name" onChange={this.onChangehandler} value={this.state.member_1_name}/>
                                        )}                                        
                                      </div>

                                      <div className="col-sm-6 d-inline-block">
                                        {this.state.existing_member_1_email !== '' ? (
                                          <input type="email" name="member_1_email" className="form-control member_email" placeholder="Enter Member 1 Email" value={this.state.existing_member_1_email}  disabled/>
                                          ) : (
                                            <input type="email" name="member_1_email" className="form-control member_email" placeholder="Enter Member 1 Email" onChange={this.onChangehandler} value={this.state.member_1_email}/>
                                        )}                                        
                                      </div>                                                                        
                                  </div>

                                  <div className="form-group text-left">
                                      <div className="col-sm-6 d-inline-block">
                                        {this.state.existing_member_2_name !== '' ? (
                                            <input type="text" name="member_2_name" className="form-control member_name" placeholder="Enter Member 2 Name" value={this.state.existing_member_2_name}  disabled/>
                                          ) : (
                                            <input type="text" name="member_2_name" className="form-control member_name" placeholder="Enter Member 2 Name" onChange={this.onChangehandler} value={this.state.member_2_name} />
                                        )} 
                                      </div>

                                      <div className="col-sm-6 d-inline-block">
                                        {this.state.existing_member_2_email !== '' ? (
                                          <input type="email" name="member_2_email" className="form-control member_email" placeholder="Enter Member 2 Email" value={this.state.existing_member_2_email}  disabled/>
                                          ) : (
                                          <input type="email" name="member_2_email" className="form-control member_email" placeholder="Enter Member 2 Email" onChange={this.onChangehandler} value={this.state.member_2_email} />
                                        )}
                                      </div>                                                                         
                                  </div>

                                  <div className="form-group text-left mb-5">
                                      <div className="col-sm-6 d-inline-block">
                                        {this.state.existing_member_3_name !== '' ? (
                                            <input type="text" name="member_3_name" className="form-control member_name" placeholder="Enter Member 3 Name" value={this.state.existing_member_3_name} disabled/>
                                          ) : (
                                            <input type="text" name="member_3_name" className="form-control member_name" placeholder="Enter Member 3 Name" onChange={this.onChangehandler} value={this.state.member_3_name}/>
                                        )} 

                                        
                                      </div>
                                      <div className="col-sm-6 d-inline-block">
                                        {this.state.existing_member_3_email !== '' ? (
                                          <input type="email" name="member_3_email" className="form-control member_email" placeholder="Enter Member 3 Email" value={this.state.existing_member_3_email} disabled/>
                                          ) : (
                                          <input type="email" name="member_3_email" className="form-control member_email" placeholder="Enter Member 3 Email" onChange={this.onChangehandler} value={this.state.member_3_email}/>
                                        )}

                                      </div>                                                                       
                                  </div>
                                  
                                  <Link to="settings" className="btn btn-primary m-0 mr-4">Back to Settings</Link> 

                                  <Button
                                  className="btn btn-primary m-0" data-membership_id={this.state.membership_id} data-price={this.state.price} data-plan_id={this.state.plan_id}
                                  onClick={this.add_members_and_checkout}
                                  >
                                  Save Members
                                  </Button>   
                            
                                </Form>     

                                {this.state.errMsg ? (
                                  <p className="error_occured mt-4">{this.state.errMsg}</p>
                                  ) : (
                                    <span></span>
                                )}

                                {this.state.successMsg ? (
                                  <p className="success_message mt-4">{this.state.successMsg}</p>
                                  ) : (
                                    <span></span>
                                )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

          <Footer />
        </div>   
      </>
    );
  }
}