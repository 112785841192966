import React, { Component } from "react";
import Geo4 from "./../images/geo-4.png";
import Geo1 from "./../images/geo-1.png";
import Geo5 from "./../images/geo-5.png";
import Geo2 from "./../images/geo-2.png";

export default class GeometryBundle2 extends Component {

  render() {    
    return (
      <>
          <figure className="geomatric-imgs">
            <img src={Geo4} alt="Sacred Geometry" className="geo-top-left" />
            <img src={Geo1} alt="Sacred Geometry" className="geo-top-right" />
            <img src={Geo5} alt="Sacred Geometry" className="geo-bottom-left" />
            <img src={Geo2} alt="Sacred Geometry" className="geo-bottom-right" />
          </figure>
      </>
    );
  }
}