// App.js file
import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import axios from 'axios';
import "./App.css";
import Signup from "./components/signUp/Signup";
import Signin from "./components/SignIn/Signin";
import StartYourDay from "./components/StartYourDay";
import SignupSuccess from "./components/Screens/SignupSuccess";
import EmailVerification from "./components/Email-verification/EmailVerification";
import Home from "./components/Home/Home";
import ChooseJourney from "./components/ChooseJourney";
import JourneyDetails from "./components/JourneyDetails";
import Welcome from "./components/Welcome";
import Journey from "./components/Journey";
import ForgotPassword from "./components/ForgotPassword";
import RevisitJourney from "./components/RevisitJourney";
import MembershipUpgrade from "./components/MembershipUpgrade";
import CurrentMemberships from "./components/CurrentMemberships";
import FamilyMembers from "./components/FamilyMembers";
import FamilyMembersEdit from "./components/FamilyMembersEdit";
import Checkout from "./components/Checkout";
import PaymentSuccess from "./components/PaymentSuccess";
import Journal from "./components/Journal";
import Updates from "./components/Updates";
import Progress from "./components/Progress";
import Community from "./components/Community";
import Settings from "./components/Settings";
import EditProfile from "./components/EditProfile";
import PublicProfile from "./components/PublicProfile";
import Library from "./components/Library";
import PreviousSessions from "./components/PreviousSessions";
import JournalDetails from "./components/JournalDetails";
import FeelingHow from "./components/FeelingHow";
import JourneyComplete from "./components/JourneyComplete";
import NotFound from "./components/NotFound";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsofUse from "./components/TermsofUse";

export default class App extends Component {
  render() {
    this.user = JSON.parse(localStorage.getItem("userData"));
    this.userToken = localStorage.getItem("userToken");

    //const user_token   = this.userToken;

    axios.defaults.baseURL = window.baseURLApi; // the prefix of the URL
    axios.defaults.headers.get['Accept'] = 'application/json';   // default header for all get request
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    //axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    //axios.defaults.headers.common['Access-Control-Allow-Credentials'] = true;
    //axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET, PUT, POST, DELETE, HEAD, OPTIONS, PATCH, PROPFIND, PROPPATCH, MKCOL, COPY, MOVE, LOCK';    
    //axios.defaults.headers.common['Access-Control-Allow-Headers'] = '*';
  
    const login      = localStorage.getItem("isLoggedIn");
    localStorage.setItem("baseapiURLMedia",window.baseURLImage);
    localStorage.setItem("baseapiURLPDF",window.baseapiURLPDF);
    localStorage.setItem("baseapiURLaudios",window.baseapiURLaudios);
    localStorage.setItem("baseURL",window.baseURL);

    const userToken = localStorage.getItem("userToken");
    if(userToken)
    {
      axios.defaults.headers.common['Authorization'] = "Bearer "+userToken;
    }

    return (
      <div className="App">
        {login ? (
          <Router>
          <Switch>
            <Route path="/" exact component={Welcome}></Route>
            <Route path="/sign-up" exact component={Signup}></Route>
            <Route path="/sign-in" exact component={Signin}></Route>
            <Route path="/home" exact component={Home}></Route>
            <Route path="/choose-journey" exact component={ChooseJourney}></Route>
            <Route path="/journey-details" exact component={JourneyDetails}></Route>
            <Route path="/email-verification" exact component={EmailVerification} />
            <Route path="/welcome" exact component={SignupSuccess} />
            <Route path="/start-day" exact component={StartYourDay} />
            <Route path="/forgot-password" exact component={ForgotPassword} />
            <Route path="/journey" exact component={Journey} />
            <Route path="/revisit" exact component={RevisitJourney} />
            <Route path="/membership-upgrade" exact component={MembershipUpgrade} />
            <Route path="/memberships" exact component={CurrentMemberships} />
            <Route path="/add-members" exact component={FamilyMembers} />
            <Route path="/edit-members" exact component={FamilyMembersEdit} />
            <Route path="/journal" exact component={Journal} />
            <Route path="/previous-sessions" exact component={PreviousSessions} />
            <Route path="/journal-detail" exact component={JournalDetails} />
            <Route path="/updates" exact component={Updates} />
            <Route path="/checkout" exact component={Checkout} />
            <Route path="/progress" exact component={Progress} />
            <Route path="/community" exact component={Community} />
            <Route path="/settings" exact component={Settings} />
            <Route path="/edit-profile" exact component={EditProfile} />
            <Route path="/edit-public-profile" exact component={PublicProfile} />
            <Route path="/library" exact component={Library} />
            <Route path="/payment-success" exact component={PaymentSuccess} />
            <Route path="/feeling" exact component={FeelingHow} />
            <Route path="/journey-finish" exact component={JourneyComplete} />
            <Route path="/privacy-policy" exact component={PrivacyPolicy} />
            <Route path="/terms-of-use" exact component={TermsofUse} />
            <Route component={NotFound} />
          </Switch>
          </Router>
        ) : (
          <Router>
          <Switch>
            <Route path="/" exact component={Welcome}></Route>
            <Route path="/sign-up" exact component={Signup}></Route>
            <Route path="/sign-in" exact component={Signin}></Route>
            <Route path="/home" exact component={Home}></Route>
            <Route path="/choose-journey" exact component={ChooseJourney}></Route>
            <Route path="/journey-details" exact component={JourneyDetails}></Route>
            <Route path="/email-verification" exact component={EmailVerification} />
            <Route path="/welcome" exact component={SignupSuccess} />
            <Route path="/start-day" exact component={StartYourDay} />
            <Route path="/forgot-password" exact component={ForgotPassword} />
            <Route path="/journey" exact component={Journey} />
            <Route path="/revisit" exact component={RevisitJourney} />
            <Route path="/membership-upgrade" exact component={MembershipUpgrade} />
            <Route path="/memberships" exact component={CurrentMemberships} />
            <Route path="/add-members" exact component={FamilyMembers} />
            <Route path="/edit-members" exact component={FamilyMembersEdit} />
            <Route path="/journal" exact component={Journal} />
            <Route path="/journal-detail" exact component={JournalDetails} />            
            <Route path="/updates" exact component={Updates} />            
            <Route path="/checkout" exact component={Checkout} />
            <Route path="/progress" exact component={Progress} />
            <Route path="/community" exact component={Community} />
            <Route path="/settings" exact component={Settings} />
            <Route path="/edit-profile" exact component={EditProfile} />
            <Route path="/edit-public-profile" exact component={PublicProfile} />
            <Route path="/library" exact component={Library} />
            <Route path="/payment-success" exact component={PaymentSuccess} />
            <Route path="/feeling" exact component={FeelingHow} />
            <Route path="/journey-finish" exact component={JourneyComplete} />
            <Route path="/previous-sessions" exact component={PreviousSessions} />
            <Route path="/privacy-policy" exact component={PrivacyPolicy} />
            <Route path="/terms-of-use" exact component={TermsofUse} />
            <Route component={NotFound} />
          </Switch>
          </Router>
        )}
      </div>
    );
  }
}