import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Button, Form, FormGroup, Input } from "reactstrap";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from "./../images/logo.png";
import AuthLinks from "./AuthLinks";
import Loader from "./Loader";
import CommonFooter from "./CommonFooter";
import GeometryBundle1 from "./GeometryBundle3";

export default class EditProfile extends Component {
  constructor(props) 
  {
    super(props);
    this.state = {
      redirect:false,
      isLoading:false,
      user_unique_custom_id:'',
      name:'',
      current_name:'',
      email:'',
      phone:'',
      current_phone:'',
      profileImage:'',
      countryCode:'',
      imagedata:'',
      phone_no:'',
      countryIsoCode:'',
      errMsgEmail: "",
      errMsgName: "",
      errMsgPhone: "",
      errMsg: "",
      number_change: false,
      errors: {},
      successmsg:'',
      public_image_preview:''
    };
  }
  
  componentDidMount() 
  {
      this.setState({ isLoading:true });

      axios.get('/user', {})
      .then((response) => {
        this.setState({ isLoading:false });
        if(response.status === 200) 
        {        
          this.setState({
            user_unique_custom_id: response.data.data.user_unique_custom_id,
            name: response.data.data.name,
            current_name: response.data.data.name,
            email: response.data.data.email,
            phone: response.data.data.countryCode+response.data.data.phone,
            current_phone: (response.data.data.phone && (response.data.data.phone !== 'null' || response.data.data.phone !== null)) ? response.data.data.phone : '',
            countryCode: (response.data.data.countryCode && (response.data.data.countryCode !== 'null' || response.data.data.countryCode !== null)) ? response.data.data.countryCode : '',
            countryIsoCode: response.data.data.countryIsoCode,
            profileImage: response.data.data.profileImage,
          });
        }
      })
      .catch((error) => {

      });
  }

  onChangehandler = (e) => {
    let name  = e.target.name;
    let value = e.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };

  handleOnChange(value, data, event, formattedValue) {
    this.setState({ 
      countryIsoCode: data.countryCode,
      phone_no:value.slice(data.dialCode.length),
      countryCode:data.dialCode,
      number_change:true
    });
  };

  handleFileChange(file)
  {
    this.setState({ 
      imagedata: file[0],
      public_image_preview : URL.createObjectURL(file[0])
    });
  }


  validateForm() 
  {
    let name_val  = this.state.name;
    let errors = {};
    let formIsValid = true;

    if (!name_val) 
    {
      formIsValid = false;
      errors["name"] = "* Please enter Name.";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  updateProfile = e => {
    e.preventDefault();
    if (this.validateForm()) 
    {
      var phone = '';
      var countryCode = '';
      var countryIsoCode = '';
      this.setState({ isLoading: true });

      const formElem = document.querySelector('#editing_profile');

      if(this.state.number_change)
      {
        phone = this.state.phone_no;
        countryCode = this.state.countryCode;
        countryIsoCode = this.state.countryIsoCode.toUpperCase();
      }
      else
      {
        phone = this.state.current_phone;
        countryCode = this.state.countryCode;
        countryIsoCode = this.state.countryIsoCode;
      }

      var formData = new FormData(formElem);

      formData.delete('phone');
      formData.delete('name');

      formData.append("name", this.state.name);
      formData.append("phone", phone); 
      if(phone !== '')
      {
        formData.append("countryCode", countryCode); 
        formData.append("countryIsoCode", countryIsoCode);
      }
      else
      {
        formData.append("countryCode", ''); 
        formData.append("countryIsoCode", '');
      }
      
      formData.append("dob", ''); 

      formData.append("image", this.state.imagedata); 

      axios
        .post("/updateProfile", formData)
        .then((response) => {
          this.setState({ isLoading: false });

          if (response.status === 200) 
          {
            this.setState({
              successmsg: "Data Updated Successfully.",
              redirect: true,
              signupData: {
                name: "",
                phone_no: "",
                countryIsoCode: "",
                countryCode: "",
                imagedata: "",
              },
            });
          }

          if (response.status === "failed") 
          {
            this.setState({ msg: response.data.error });
            setTimeout(() => {
              this.setState({ msg: "" });
            }, 2000);
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
        });
    }    
  };

  render() {
    const isLoading = this.state.isLoading;
    
    const login = localStorage.getItem("isLoggedIn");
    const baseapiURLMedia = localStorage.getItem("baseapiURLMedia");

    if(this.state.redirect) 
    {
      return <Redirect to="/settings" />;
    }  

    if(!login) 
    {
      return <Redirect to="/sign-in" />;
    }     

    const user = JSON.parse(localStorage.getItem("userData"));
    if(user && (user.email_verified_at === '' || user.email_verified_at === null))
    {
      return <Redirect to="/email-verification" push={true} />;
    }

    return (
      <>
        <div className="main-wrapper another-main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>

          {isLoading ? (
            <Loader />
            ) : (
              <span></span>
          )}

          <div className="mid-container edit-profile-container">
            <div className="content mt-0">
              <Form className="mt-5" encType="multipart/form-data" id="editing_profile">
              <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="settings-left-panel h-100">
                        <figure className="text-center">
                          {!this.state.public_image_preview ? (
                            <>
                              {this.state.profileImage ? (
                                <>
                                <img src={baseapiURLMedia+this.state.profileImage} className="settings_profile_image" alt="ProfileImage"/>
                                </>
                                ) : (
                                <>
                                <img src={baseapiURLMedia+'default-profile-image.svg'} className="settings_profile_image" alt="ProfileImage"/>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <img src={this.state.public_image_preview} className="settings_profile_image" alt="ProfileImage"/>
                            </>
                          )
                          }
                        </figure>
                        <h5 className="text-center mt-3">Upload a new image</h5>
                        <div className="input-group mb-3 profile-img-upload">
                            <div className="custom-file">
                              <Input
                                type="file"
                                name="image"
                                id="image"
                                className="custom-file-input"
                                placeholder="Upload Profile Image"
                                onChange={(e) => this.handleFileChange(e.target.files)}
                                aria-describedby="profile_pic"
                              />
                              <label className="custom-file-label" htmlFor="image">Choose file</label>
                            </div>
                        </div>
                        
                    </div>
                  </div>

                  <div className="col-lg-8 col-md-8 col-sm-12 mt-3 mt-sm-0">
                    <div className="setting-right-panel">
                        <div className="row">
                          <div className="col-12">

                            <FormGroup>
                              <label><FontAwesomeIcon icon={faUser} className="mr-2 ml-2" />Name <span className="required_field_star">*</span></label>
                              <Input
                                type="text"
                                name="name"
                                className=""
                                placeholder="Enter Name"
                                value={this.state.name}
                                onChange={this.onChangehandler}
                              />
                              <span className="text-danger">{this.state.msg}</span>
                              <span className="text-danger">{this.state.errMsgName}</span>
                              <span className="text-danger">{this.state.errors.name}</span>
                            </FormGroup>  

                            <FormGroup>
                              <label><FontAwesomeIcon icon={faEnvelope} className="mr-2 ml-2" />Email Address</label>
                              <Input
                                type="email"
                                name="email"
                                className="form-control"
                                placeholder="Enter Email Address *"
                                value={this.state.email}
                                disabled={true}
                              />
                              <span className="text-danger">{this.state.msg}</span>
                              <span className="text-danger">{this.state.errMsgEmail}</span>
                              <span className="text-danger">{this.state.errors.email}</span>
                            </FormGroup>

                            <FormGroup>
                              <label><FontAwesomeIcon icon={faPhone} className="mr-2 ml-2" />Phone Number</label>
                              <PhoneInput
                                inputClass={'country'}
                                country={'us'}
                                value={(this.state.phone) ? this.state.phone: ''}
                                onChange={this.handleOnChange.bind(this)}
                                placeholder={'Enter Phone Number'}
                                countryCodeEditable={false}
                                enableSearch={true}
                                inputProps={{
                                  name: 'phone',
                                }}
                              />
                              <span className="text-danger">{this.state.msg}</span>
                              <span className="text-danger">{this.state.errMsgPhone}</span>
                              <span className="text-danger">{this.state.errors.phone}</span>
                            </FormGroup>

                            <div className="form-group text-center mb-0">
                            <Button
                              type="submit"
                              className="btn btn-primary"
                              onClick={this.updateProfile}
                            >
                            Update profile
                            </Button>
                            </div>

                            <p className="text-danger">{this.state.errMsg}</p>
                            <p className="text-success">{this.state.successmsg}</p>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>
              </Form>
            </div>
          </div>

          <GeometryBundle1 />
          <CommonFooter />
        </div> 
      </>
    );
  }
} 