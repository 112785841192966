import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Logo from "./../images/logo.png";
import AuthLinks from "./AuthLinks";
import Loader from "./Loader";
import CommonFooter from "./CommonFooter";
import GeometryBundle1 from "./GeometryBundle1";

export default class Community extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
    this.user = JSON.parse(localStorage.getItem("userData"));
  }  

  render() {
    const user = JSON.parse(localStorage.getItem("userData"));

    if(user && (user.email_verified_at === '' || user.email_verified_at === null))
    {
      return <Redirect to="/email-verification" push={true} />;
    }

    const isLoading = this.state.isLoading;    
    const login = localStorage.getItem("isLoggedIn");

    if(!login) 
    {
      return <Redirect to="/sign-in" />;
    }       

    return (
      <>
        <div className="main-wrapper another-main-wrapper community-wrapper">
          {isLoading ? (
              <Loader />
            ) : (
              <span></span>
          )}

          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>

          <div className="mid-container community-container">
            <div className="content mt-0">
                <div className="row align-self-center">
                  <div className="col-sm-5 mx-auto">
                        <iframe src="https://www.facebook.com/plugins/group.php?href=https%3A%2F%2Fwww.facebook.com%2Fgroups%2Fsocreaterswithin&width=320&show_social_context=true&show_metadata=true&appId=228905318460007" width="320" height="400" style={{ border:'none',overflow:'hidden' }} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; encrypted-media; picture-in-picture;" title="Community"></iframe>
                  </div>
                </div>
            </div>      
          </div>
          <GeometryBundle1 />
          <CommonFooter />
        </div> 
      </>
    );
  }
}