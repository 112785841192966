import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import Moment from 'moment';
import {Pagination} from 'react-laravel-paginex';
import { Tabs, Tab } from 'react-bootstrap';
import Logo from "./../images/logo.png";
import DataNotFound from "./../images/data_not_found.gif";
import AuthLinks from "./AuthLinks";
import Loader from "./Loader";
import CommonFooter from "./CommonFooter";
import GeometryBundle3 from "./GeometryBundle3";

export default class Updates extends Component {
  userData;
  constructor(props) 
  {
    super(props);
    this.state = {
      redirect:false,
      isLoading:false,
      quotes_data_not_found:false,
      insights_data_not_found:false,
      inspirational_quotes:{},
      inspirational_quotes_data:[],
      insights_notifications:{},
      insights_notifications_data:[],
    };
    this.myRef = React.createRef(); 
  }
  
  componentDidMount() 
  {
      this.setState({ isLoading:true });

      axios.get('/web_get_notifications_list', {})
      .then((response) => {
        this.setState({ isLoading:false });
        if(response.status === 200) 
        {           
          if(response.data.data.total > 0)
          {
            this.setState({ inspirational_quotes:response.data.data,inspirational_quotes_data:response.data.data.data });
          } 
          else
          {     
            this.setState({ quotes_data_not_found:true });          
          }
        }
      })
      .catch((error) => {
        if(error.response.status === 400)
        {
          this.setState({ isLoading:false,quotes_data_not_found:true });
        }
      });
  }

  clickBtn = e => {
    this.setState({
      renderView: +e.target.value
    });
  };

  getData=(data)=>{
    this.setState({ isLoading:true });
    const journey_id = localStorage.getItem('journey_id');

    axios.get('/web_insights_notifications_list?page='+data.page, { params: {'journey_id':journey_id} })
    .then((response) => {
      this.setState({ isLoading:false });
      if(response.status === 200) 
      {
        if(response.data.data.total > 0)
        {
          this.setState({ insights_notifications_data:response.data.data.data,insights_notifications:response.data.data });
          this.myRef.current.scrollTo(0, 0);
        } 
        else
        {     
          this.setState({ insights_data_not_found:true });          
        }
        
      }
    })
    .catch((error) => {
    });  
  }

  getQuoteData=(data)=>{
    this.setState({ isLoading:true });

    axios.get('/web_get_notifications_list?page='+data.page, {})
    .then((response) => {
      this.setState({ isLoading:false });
      if(response.status === 200) 
      {           
        if(response.data.data.total > 0)
        {
          this.setState({ inspirational_quotes:response.data.data,inspirational_quotes_data:response.data.data.data });
          this.myRef.current.scrollTo(0, 0);
        } 
        else
        {     
          this.setState({ quotes_data_not_found:true });          
        }
      }
    })
    .catch((error) => {

    }); 
  }  

  changeTab = e => {
    const journey_id = localStorage.getItem('journey_id');

    this.setState({ isLoading:true });
    if(e === 'insights')
    {
      axios.get('/web_insights_notifications_list', { params: {'journey_id':journey_id} })
      .then((response) => {
        this.setState({ isLoading:false });
        if(response.status === 200) 
        {
          if(response.data.data.total > 0)
          {
            this.setState({ insights_notifications:response.data.data,insights_notifications_data:response.data.data.data });
          } 
          else
          {     
            this.setState({ insights_data_not_found:true });          
          }
          
        }
      })
      .catch((error) => {

      });
    }

    if(e === 'quotes')
    {
      this.setState({ isLoading:true });

      axios.get('/web_get_notifications_list', {})
      .then((response) => {
        this.setState({ isLoading:false });
        if(response.status === 200) 
        {            
          if(response.data.data.total > 0)
          {
            this.setState({ inspirational_quotes:response.data.data,inspirational_quotes_data:response.data.data.data });
            this.myRef.current.scrollTo(0, 0);
          } 
          else
          {     
            this.setState({ quotes_data_not_found:true });          
          }
        }
      })
      .catch((error) => {

      });
    }
  };

  render() {
    if(this.state.redirect)
    {
      return <Redirect to="/home"/>;
    }
    const isLoading = this.state.isLoading;
    const quotes_data_not_found   = this.state.quotes_data_not_found;
    const insights_data_not_found = this.state.insights_data_not_found;
    
    const login = localStorage.getItem("isLoggedIn");

    if(!login) 
    {
      return <Redirect to="/sign-in" />;
    }     

    const user = JSON.parse(localStorage.getItem("userData"));

    if(user && (user.email_verified_at === '' || user.email_verified_at === null))
    {
      return <Redirect to="/email-verification" push={true} />;
    }
    var next_btn = <span aria-hidden="true">&raquo;</span>;
    var prev_btn = <span aria-hidden="true">&laquo;</span>;

    return (
      <>
        <div className="main-wrapper another-main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>

          {isLoading ? (
            <Loader />
            ) : (
              <span></span>
          )}

          <div className="mid-container updates-container" ref={this.myRef}>
            <div className="content mt-0">
              <div className="row">
                <div className="col-12 updates-tab-wrap">
                  <Tabs id="myTab" defaultActiveKey="quotes" onSelect={this.changeTab} transition={false} className="nav nav-tabs journal-tabs justify-content-center mb-3 mb-sm-0">

                    <Tab eventKey="quotes" title="Daily Quotes">
                        {quotes_data_not_found ? (
                          <div className="row">
                            <figure className="mt-4 mx-auto"><img src={DataNotFound} alt="DataNotFound" className="gif-large" /></figure>
                          </div>
                          ) : (
                            <div className="row">
                            {this.state.inspirational_quotes_data.map((inspirational_quote, index) => (
                                    <div className="col-lg-3 col-md-6" key={index}>
                                      <div className="update-content">
                                          <h5>{inspirational_quote.title}</h5>
                                          <p>{inspirational_quote.description}</p>
                                          <div className="mt-3"><small> {Moment(`${inspirational_quote.notification_published_date}`).format('MMMM DD, YYYY')}</small></div>
                                      </div>
                                    </div>
                            ))}
                            <div className="col-12">
                              <nav aria-label="Page navigation" className="custom-pagination mt-4">
                                <Pagination changePage={this.getQuoteData} data={this.state.inspirational_quotes} containerClass={"pagination justify-content-center"} nextButtonText={next_btn} prevButtonText={prev_btn} />
                              </nav>
                            </div>
                            </div>                            
                          )}
                    </Tab>

                    <Tab eventKey="insights" title="Insights">
                          {insights_data_not_found ? (
                            <div className="row">
                              <figure className="mt-4  mx-auto"><img src={DataNotFound} alt="DataNotFound" className="gif-large" /></figure>
                            </div>
                          ) : (
                            <div className="row">
                            {this.state.insights_notifications_data.map((insights_notification, index) => (
                                  <div className="col-lg-3 col-md-6" key={index}>
                                    <div className="update-content">
                                        <h5>{insights_notification.media_name}</h5>
                                        <p>{insights_notification.notification_text}</p>
                                    </div>
                                  </div>
                            ))}
                            <div className="col-12">
                              <nav aria-label="Page navigation" className="custom-pagination mt-4">
                                <Pagination changePage={this.getData} data={this.state.insights_notifications} containerClass={"pagination justify-content-center"} nextButtonText={next_btn} prevButtonText={prev_btn} />
                              </nav>
                            </div>
                            </div>                            
                          )}
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>

          <GeometryBundle3 />
          <CommonFooter />
        </div>  
      </>
    );
  }
} 