import React, { Component } from "react";
import { Redirect,Link } from "react-router-dom";
import ThumbsUp from "./../images/h-thumbsUp.gif";
import Logo from "./../images/logo.png";
import AuthLinks from "./AuthLinks";
import CommonFooter from "./CommonFooter";
import GeometryBundle3 from "./GeometryBundle3";

export default class PaymentSuccess extends Component {
  userData;
  constructor(props) 
  {
    super(props);
    this.state = {
      redirect:false,
      redirect_to_failed:false,
      clientToken:'',
      systemTransactionNo:''
    };
  }

  clickBtn = e => {
    this.setState({
      redirect:true
    });
  };

  render() {
    if(this.state.redirect)
    {
      return <Redirect to="/home"/>;
    }

    if(this.state.redirect_to_failed)
    {
      return <Redirect to="/membership-upgrade"/>;
    }

    const user = JSON.parse(localStorage.getItem("userData"));

    if(user && (user.email_verified_at === '' || user.email_verified_at === null))
    {
      return <Redirect to="/email-verification" push={true} />;
    }

    const login = localStorage.getItem("isLoggedIn");

    if(!login) 
    {
      return <Redirect to="/sign-in" />;
    }     
    
    return (
      <>
        <div className="main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>

          <div className="mid-container">
            <div className="content mt-0">
                <div className="row align-self-center">
                    <div className="col-md-6 col-12">
                        <figure><img src={ThumbsUp} alt="ThumbsUp" className="left-img img-shadow" /></figure>
                    </div>
                    <div className="col-md-6 col-12 align-self-center">
                        <div className="px-md-5 px-0 mt-sm-0 mt-4">
                          <h6 className="mt-4">Payment Completed Successfully.</h6>
                          <h6 className="mt-4">Thanks for Upgrading to the Premium Plan. Continue your Journey Within.</h6>
                          <p>Congrats on becoming a Premium member of Socreates!</p>
                          <p>In order to see this change on your mobile app, you will need to sign out and then sign back into your account on your phone. Once you sign back in, you will see your mobile account designated as Premium. </p>
                          <p>Any questions or issues, please contact <Link to="#" onClick={()=> window.open("mailto:support@socreates.com", "_blank")}>support@socreates.com</Link> with either the email address you used to sign up or your Socreates User ID number.</p>
                        </div>
                        <button className="btn btn-primary mt-4" onClick={this.clickBtn}>Continue Your Journey</button>
                    </div>
                </div>
            </div>     
          </div>
          <GeometryBundle3 />
          <CommonFooter />
        </div> 
      </>
    );
  }
}