import React, { Component } from "react";
import EgoMind from "./../../images/Ego.gif";
import Logo from "./../../images/logo.png";
import Screen5 from "./../Screens/Screen5";
import Screen7 from "./../Screens/Screen7";
import AuthLinks from "./../AuthLinks";
import CommonFooter from "./../CommonFooter";
import GeometryBundle1 from "./../GeometryBundle1";

export default class Screen6 extends Component {
  userData;
  constructor(props) 
  {
    super(props);
    this.state = { };
  }

  clickBtn = e => {
    this.setState({
      renderView: +e.target.value
    });
  };

  render() {
    if(this.state.renderView === 5)
    {
      return <Screen5 />;
    }
    if(this.state.renderView === 7)
    {
      return <Screen7 />;
    }

    return (
      <>
        <div className="main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>

          <div className="mid-container">
            <div className="content">
                <div className="row align-self-center">
                    <div className="col-md-6 col-12">
                        <figure><img src={EgoMind} alt="EgoMind" className="left-img img-shadow" /></figure>
                    </div>
                    <div className="col-md-6 col-12 align-self-center">
                        <div className="px-md-5 px-0 mt-sm-0 mt-4">
                          <p>When you are in your Ego Mind, you become judgemental, see problems everywhere and cast blame. You feel isolated and fearful.</p>
                        </div>
                          <button value={5} className="btn btn-primary" onClick={this.clickBtn}>BACK</button>
                          <button value={7} className="btn btn-primary" onClick={this.clickBtn}>NEXT</button>
                    </div>
                </div>
            </div>     
          </div>
          <GeometryBundle1 />
          <CommonFooter />
        </div> 
      </>
    );
  }
}