import React, { Component } from "react";
import { Redirect,Link } from "react-router-dom";
import {  Form } from "reactstrap";
import axios from "axios";
import Logo from "./../images/logo.png";
import InvitationtoJournal from "./../images/Invitation-to-journal.gif";
import AuthLinks from "./AuthLinks";
import Loader from "./Loader";
import DailySessionEnd from "./DailySessionEnd";
import CommonFooter from "./CommonFooter";
import GeometryBundle2 from "./GeometryBundle2";

export default class InvitationToJournal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigate: false,
      isLoading: false,
      msg: "",     
      errMsg: "",     
      errors: {},     
      data: {},     
      success: false,     
      journey_complete : false,     
      skip_feeling_how : false,
    };
    this.user = JSON.parse(localStorage.getItem("userData"));
  } 

  onChangehandler = (e) => {
    let name  = e.target.name;
    let value = e.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  }; 

  validateForm() 
  {
    let journal_answer_1 = this.state.journal_answer_1;
    //let journal_answer_2 = this.state.journal_answer_2;
    //let journal_answer_3 = this.state.journal_answer_3;
    let errors = {};
    let formIsValid = true;

    if (!journal_answer_1) 
    {
      formIsValid = false;
      errors["journal_answer_1"] = "* Please enter Answer 1.";
    }

    /*if (!journal_answer_2) 
    {
      formIsValid = false;
      errors["journal_answer_2"] = "* Please enter Answer 2.";
    }

    if (!journal_answer_3) 
    {
      formIsValid = false;
      errors["journal_answer_3"] = "* Please enter Answer 3.";
    }    */

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  state = {
    navigate: false,
  };

  save_journal = e => {
    e.preventDefault();

    if(this.validateForm()) 
    {
      this.setState({ isLoading: true });
      const journey_id        = this.props.journey_id;
      const questions_id      = this.props.questions_id;
      const currentAnswerID   = this.props.currentAnswerID;
      const type              = this.props.type;
      const currentQuestionID = this.props.currentQuestionID;
      const mediaID           = this.props.mediaID;
      const planID            = this.props.planID;
      const video_info        = this.props.video_info;
      //const video_name        = this.props.video_name;

      const user_video_ans = [this.state.journal_answer_1,this.state.journal_answer_2,this.state.journal_answer_3];

      const today = new Date();
      const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date+' '+time;
      var journey_completed = 'false';

      /***********************************************/
      var feel_session_id = localStorage.getItem('feel_session_id');

      axios
      .get("/check_feel_exists", {
        params : {feel_session_id:feel_session_id, journey_id : journey_id}
      })
      .then((response) => {
        if(response.status === 200)
        {
          if(response.data.data)
          {
            if(response.data.data.record_exists === 'true' && response.data.data.session_end_response !== null)
            {
              this.setState({ skip_feeling_how: true });
            }
          }
        }
      })  
      .catch((error) => {
        this.setState({ isLoading: false });
      });   

      /*************************************************/
      axios
      .get("/getNextJourneyRoute", { 
        params: { journey_id:journey_id, questions_id:questions_id, currentAnswerID:currentAnswerID, type:type, currentQuestionID:currentQuestionID, mediaID:mediaID, planID:planID }
      })
      .then((response) => {
        if(response.status === 200)
        {
          document.getElementById("save_journal_form").reset();

          if(response.data && response.data.success === 'Journey Finished')
          {
            journey_completed = 'true';
            axios
            .post("/save_user_videos", { videoID:mediaID,journey_id:journey_id,videoURL:video_info,last_viewed:dateTime,user_video_ans:JSON.stringify(user_video_ans) })  
            .then((response) => {
              if(response.status === 200)
              {
                if(journey_completed === 'true')
                {
                  this.setState({ journey_complete:true,isLoading: false });
                }
              }
            }) 
            .catch((error) => {
              this.setState({ isLoading: false });
            });
          } 
          
          if(response.data && response.data.success === 'access_denied' && (response.data.reason === 'Free Trial Daily Session limit Reached' || response.data.reason === 'Daily Session Limit Reached')) 
          {
            axios
            .post("/save_user_videos", { videoID:mediaID,journey_id:journey_id,videoURL:video_info,last_viewed:dateTime,user_video_ans:JSON.stringify(user_video_ans) })  
            .then((response) => {
              if(response.status === 200)
              {
                this.setState({ success:true,isLoading: false });
              }
            }) 
            .catch((error) => {
              this.setState({ isLoading: false });
            });
          }

          if(response.data && response.data.question)
          {            
            this.setState({ question: response.data.question.name,question_id:response.data.question.id,answers:response.data.answers },
              () => 
              axios
              .post("/save_user_videos", { videoID:mediaID,journey_id:journey_id,videoURL:video_info,last_viewed:dateTime,user_video_ans:JSON.stringify(user_video_ans) })  
              .then((response) => {
                if(response.status === 200)
                {
                  if(journey_completed === 'true')
                  {
                    this.setState({ journey_complete:true,isLoading: false });
                  }
                  else
                  {
                    this.setState({ success:true,isLoading: false });
                  }
                }
              })                       
            );
          }
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });  
 
      return false;  
    }
    else
    {
      setTimeout(() => {
        this.setState({ isLoading: false });
      }, 1500);

      return false;
    }
  };

  skip_journal = e => {
    e.preventDefault();
    this.setState({ isLoading: true });
      const journey_id        = this.props.journey_id;
      const questions_id      = this.props.questions_id;
      const currentAnswerID   = this.props.currentAnswerID;
      const type              = this.props.type;
      const currentQuestionID = this.props.currentQuestionID;
      const mediaID           = this.props.mediaID;
      const planID            = this.props.planID;
      const video_info        = this.props.video_info;
      //const video_name        = this.props.video_name;
      const user_video_ans = ['','',''];

      const today = new Date();
      const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date+' '+time;
      var journey_completed = 'false';


      /***********************************************/
      var feel_session_id = localStorage.getItem('feel_session_id');

      axios
      .get("/check_feel_exists", {
        params : {feel_session_id:feel_session_id, journey_id : journey_id}
      })
      .then((response) => {
        if(response.status === 200)
        {
          if(response.data.data)
          {
            if(response.data.data.record_exists === 'true' && response.data.data.session_end_response !== null)
            {
              this.setState({ skip_feeling_how: true });
            }
          }
        }
      })  
      .catch((error) => {
        this.setState({ isLoading: false });
      });       

      /*************************************************/
      axios
      .get("/getNextJourneyRoute", { 
        params: { journey_id:journey_id, questions_id:questions_id, currentAnswerID:currentAnswerID, type:type, currentQuestionID:currentQuestionID, mediaID:mediaID, planID:planID }
      })
      .then((response) => {
        if(response.status === 200)
        {
          document.getElementById("save_journal_form").reset();
          if(response.data && response.data.success === 'Journey Finished')
          {
            journey_completed = 'true';
            axios
            .post("/save_user_videos", { videoID:mediaID,journey_id:journey_id,videoURL:video_info,last_viewed:dateTime,user_video_ans:JSON.stringify(user_video_ans) })  
            .then((response) => {
              if(response.status === 200)
              {
                if(journey_completed === 'true')
                {
                  this.setState({ journey_complete:true,isLoading: false });
                }
              }
            }) 
            .catch((error) => {
              this.setState({ isLoading: false });
            });
          } 

          if(response.data && (response.data.reason === 'Free Trial Daily Session limit Reached' || response.data.reason === 'Daily Session Limit Reached'))
          {
            axios
            .post("/save_user_videos", { videoID:mediaID,journey_id:journey_id,videoURL:video_info,last_viewed:dateTime,user_video_ans:JSON.stringify(user_video_ans) })  
            .then((response) => {
              if(response.status === 200)
              {
                this.setState({ success:true,isLoading: false });
              }
            }) 
            .catch((error) => {
              this.setState({ isLoading: false });
            });
          }

          if(response.data && response.data.question)
          {
            this.setState({ question: response.data.question.name,question_id:response.data.question.id,answers:response.data.answers },
              () => 
              axios
              .post("/save_user_videos", { videoID:mediaID,journey_id:journey_id,videoURL:video_info,last_viewed:dateTime,user_video_ans:JSON.stringify(user_video_ans) })  
              .then((response) => {
                if(response.status === 200)
                {
                  if(journey_completed === 'true')
                  {
                    this.setState({ journey_complete:true,isLoading: false });
                  }
                  else
                  {
                    this.setState({ success:true,isLoading: false });
                  }
                }
              })            
            );
          }
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });

      return false;
  };

  render() {
    const isLoading = this.state.isLoading;

    if(this.state.journey_complete && this.state.skip_feeling_how === false)
    {
      return <Redirect to={{
        pathname: '/feeling',
        state: { journey_status:'finished' }
          }}
      /> 
    }

    if(this.state.journey_complete && this.state.skip_feeling_how )
    {
      return <Redirect to="/journey-finish" push={true} />;
    }

    if(this.state.success && this.state.skip_feeling_how)
    {
      return <DailySessionEnd video_info={this.props.video_info} media_id={this.props.mediaID} video_name={this.props.video_name} received_video_url={this.props.flow_video_url} />; 
    }

    if(this.state.success && this.state.skip_feeling_how === false)
    {
      return <Redirect to={{
        pathname: '/feeling',
        state: {  video_info:this.props.video_info, media_id:this.props.mediaID, video_name:this.props.video_name,received_video_url:this.props.flow_video_url }
          }}
      /> 
    }    

    return (
      <>
        <div className="main-wrapper">
            <header>
              <figure className="waves-top"></figure>
              <figure className="logo">
                <img src={Logo} alt="logo" />
              </figure>
              <AuthLinks />
            </header>

            <header></header>
            {isLoading ? (
                <Loader count={10} />
              ) : (
                <span></span>
            )}

            <div className="mid-container ij-container">
              <div className="content">
                  <div className="row">
                      <div className="col-md-6 col-12 align-self-center">
                          <figure><img src={InvitationtoJournal} alt="InvitationtoJournal" className="left-img img-shadow" /></figure>
                      </div>
                      <div className="col-md-6 col-12 align-self-center">
                          <div className="px-lg-5 px-0 text-left">
                            <h5 className="mt-sm-0 mt-3">Invitation to Journal</h5>
                            <p className="mt-2 mb-0">Write 3 takeaways meaningful to you</p>
                            <p className="mt-1 mb-0">To Finish Session, click 'Save' or 'Skip'</p>
                            <p className="mt-1 mb-0">Confidentiality : Save to your Socreates Journal Only.</p>
                            <Form className="mt-3 invitation-form" id="save_journal_form">
                              <div className="form-group">
                                <textarea className="form-control" name="journal_answer_1" rows={3} placeholder="Enter Here" onChange={this.onChangehandler} maxLength="160" />
                                <span className="text-danger">{this.state.errMsgJournalAnswer1}</span>
                                <span className="text-danger">{this.state.errors.journal_answer_1}</span>
                              </div>
                              <div className="form-group">
                                  <textarea className="form-control" name="journal_answer_2" rows={3} placeholder="Enter Here" onChange={this.onChangehandler} maxLength="160" />
                                  <span className="text-danger">{this.state.errMsgJournalAnswer2}</span>
                                  <span className="text-danger">{this.state.errors.journal_answer_2}</span>
                              </div>
                              <div className="form-group">
                                  <textarea className="form-control" name="journal_answer_3" rows={3} placeholder="Enter Here" onChange={this.onChangehandler} maxLength="160" />
                                  <span className="text-danger">{this.state.errMsgJournalAnswer3}</span>
                                  <span className="text-danger">{this.state.errors.journal_answer_3}</span>
                              </div>

                              <Link to="#" className="btn btn-primary" onClick={this.skip_journal}>Skip</Link>  
                              <Link to="#" className="btn btn-primary" onClick={this.save_journal}>Save</Link>
                            </Form>
                          </div>
                      </div>
                  </div>
              </div>     
            </div>

          <GeometryBundle2 />
          <CommonFooter />
        </div>
      </>
    );
  }
}