import React, { Component } from "react";
import axios from "axios";
import Loading from "./../images/loading.gif";

const Loader = () => (
  <div className="divLoader">
    <img src={Loading} className="img-responsive load_video" alt="Loading"/>
  </div>
);

export default class VimeoCons extends Component {

  constructor(props) 
  {
    super(props);
    this.state = { video_thumb:"",loading: true,play_video:"",subtitles_link:"",video_thumb_only:"" }; 
  }

  componentDidMount() 
  {
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });

    const thumbnail_token     = '5deae3fba628a37023fbcb3a78f32e31'; 
    const video_url_get_token = 'cdd6821476e13b3121a98c7437b478ca'; 

    if(this.props.get_thumbnail_only && this.props.get_thumbnail_only==="TRUE")
    {
        if(this.props && this.props.video_url && (this.props.video_url !== '' || this.props.video_url !== 'undefined' || this.props.video_url !== undefined))
        {
          const video_url  = this.props.video_url.split( '/' );
          const video_hash = video_url[video_url.length -1];
          const video_id   = video_url[video_url.length -2];
          const get_thumbnail_url = 'https://api.vimeo.com/videos/'+video_id+':'+video_hash+'/pictures';

            axios.get(get_thumbnail_url, { headers: {"Authorization" : `Bearer ${thumbnail_token}`,'Accept': 'application/json'} })
            .then((response) => {
              const image_sizes = response.data.data[0].sizes;

              const image_sizes_max = image_sizes[image_sizes.length - 1];

              if(response.status === 200) 
              {
                var image_thumb = image_sizes_max.link;
                this.setState({ video_thumb_only: image_thumb,loading:false });
              }
            })
            .catch((error) => {

            });
        }
    }

    if(this.props.get_thumbnail && this.props.get_thumbnail==="TRUE")
    {
        const video_url  = this.props.video_url.split( '/' );
        const video_hash = video_url[video_url.length -1];
        const video_id   = video_url[video_url.length -2];
        const get_thumbnail_url = 'https://api.vimeo.com/videos/'+video_id+':'+video_hash+'/pictures';

          axios.get(get_thumbnail_url, { headers: {"Authorization" : `Bearer ${thumbnail_token}`,'Accept': 'application/json'} })
          .then((response) => {
            const image_sizes = response.data.data[0].sizes;

            const image_sizes_max = image_sizes[image_sizes.length - 1];

            if(response.status === 200) 
            {
              var image_thumb = image_sizes_max.link;
              this.setState({ video_thumb: image_thumb,loading:false });
            }
          })
          .catch((error) => {

          });
    }

    if(this.props.get_thumbnail && this.props.get_video==="TRUE")
    {
        const video_url  = this.props.video_url.split( '/' );
        //const video_hash = video_url[video_url.length -1];
        const video_id   = video_url[video_url.length -2];
        const get_video_url = 'https://api.vimeo.com/me/videos/'+video_id;

        axios.get(get_video_url, { headers: {"Authorization" : `Bearer ${video_url_get_token}`,'Accept': 'application/json'} })
          .then((response) => {

            if(response.status === 200) 
            {
              var video_data = response.data.files[4].link;
              this.setState({ play_video: video_data,loading:false });
            }
          })
          .catch((error) => {

          });
    }

    if(this.props.get_thumbnail && this.props.get_video_with_subtitles==="TRUE")
    {
        const video_url  = this.props.video_url.split( '/' );
        //const video_hash = video_url[video_url.length -1];
        const video_id   = video_url[video_url.length -2];
        const get_video_url = 'https://api.vimeo.com/me/videos/'+video_id;
        const get_video_subtitles = 'https://api.vimeo.com/videos/'+video_id+'/texttracks';

        axios.get(get_video_url, { headers: {"Authorization" : `Bearer ${video_url_get_token}`,'Accept': 'application/json'} })
          .then((response) => {
            if(response.status === 200) 
            {
              var files_data = response.data.files;
              var width_array = [];
              files_data.forEach((file,i) => {
                  if(file.width)
                  {
                    width_array.push(file.width);
                  }
              });
              var max_index = width_array.indexOf(Math.max.apply(null, width_array));
              var video_data = response.data.files[max_index].link;
              this.setState({ play_video: video_data,loading:false });
            }
          })
          .catch((error) => {

          });

        axios.get(get_video_subtitles, { headers: {"Authorization" : `Bearer ${video_url_get_token}`,'Accept': 'application/json'} })
          .then((response) => {

            if(response.status === 200) 
            {
              var subtitles_link_result = response.data.data[0].link;
              this.setState({ subtitles_link: subtitles_link_result,loading:false });
            }
          })
          .catch((error) => {

          });          
    } 
  }

  render() 
  {
    if(this.state.video_thumb_only)
    {
      if(this.props.video_id)
      {
        return (
          <>
            <img src={this.state.video_thumb_only} alt="Video Thumbnail" className="img-responsive card-img-top" video_url={this.props.video_url} video_id={this.props.video_id} uservideo_id={this.props.uservideo_id} video_type={this.props.video_type} media_name={this.props.media_name} />
          </>
        );   
      }
      else
      {
        return (
          <>
            <img src={this.state.video_thumb_only} alt="Video Thumbnail" className="img-responsive card-img-top" video_url={this.props.video_url} video_type={this.props.video_type} media_name={this.props.media_name} />
          </>
        );   
      } 
    }

    if(this.state.video_thumb)
    {
      const track = <track kind="captions" srcLang="en-US" label="English" default src={this.state.subtitles_link} />;
      return (
        <>
          <video className="play_video" autoPlay controls src={this.state.play_video} children={track} controlsList="nodownload" crossOrigin="anonymous" videoid={this.props.videoID} uservideo_id={this.props.uservideo_id} />
        </>
      );    
    }
    return (
      <>
        {this.state.loading ? <Loader /> : null}
      </>
    );
  }
}
