import React, { Component } from "react";
import { Link } from "react-router-dom";
import CircleGeometry from "./../../images/circle-geometry.png";
import Geometry6 from "./../../images/geo-6.png";

export default class CommonCirclesFooter extends Component {
  userData;
  constructor(props) 
  {
    super(props);
    this.state = {};
  }

  render() {

    const login = localStorage.getItem("isLoggedIn");

    if (window.location.href.indexOf("welcome") > -1 || window.location.href.indexOf("email-verification") > -1 || window.location.href.indexOf("start-day") > -1 || window.location.href.indexOf("forgot-password") > -1 || window.location.href.indexOf("journey") > -1 || window.location.href.indexOf("revisit") > -1 || window.location.href.indexOf("membership-upgrade") > -1 || window.location.href.indexOf("checkout") > -1 || window.location.href.indexOf("edit-profile") > -1 || window.location.href.indexOf("feeling") > -1 || window.location.href.indexOf("privacy-policy") > -1) 
    {
      return (
        <>
          <footer>
              <img src={CircleGeometry} alt="CircleGeometry" className="bottom-circle-geom" />
              <img src={Geometry6} alt="Geometry6" className="bottom-geom" />
          </footer>
        </>
      );
    }

    if(!login)
    {
      return (
        <>
          <footer>
              <Link to="/privacy-policy" className="footer_policy" target="_blank">View our Privacy Policy</Link>
              <figure className="waves-bottom"></figure>
          </footer>
        </>
      );
    }
    else
    {
      return (
        <>
          <footer>
              <img src={CircleGeometry} alt="CircleGeometry" className="bottom-circle-geom" />
              <img src={Geometry6} alt="Geometry6" className="bottom-geom" />
          </footer>
        </>
      );
    }
  }
}