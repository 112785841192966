import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Namaste from "./../../images/namaste.gif";
import Logo from "./../../images/logo.png";
import AuthLinks from "./../AuthLinks";
import AssessmentQuestions from "./../Screens/AssessmentQuestions";
import CommonFooter from "./../CommonFooter";
import GeometryBundle1 from "./../GeometryBundle1";

export default class SignupSuccess extends Component {
  constructor(props) 
  {
    super(props);
    this.state = { };
  }

  clickBtn = e => {
    this.setState({
      renderView: +e.target.value
    });
  };

  render() {
    const login = localStorage.getItem("isLoggedIn");

    if(!login) 
    {
      return <Redirect to="/sign-in" />;
    } 

    const user = JSON.parse(localStorage.getItem("userData"));
    if(user && (user.email_verified_at === '' || user.email_verified_at === null))
    {
      return <Redirect to="/email-verification" push={true} />;
    }

    if(this.state.renderView === 1)
    {
      return <AssessmentQuestions />
    }

    return (
      <>
        <div className="main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>

          <div className="mid-container helps-container">
            <div className="content mt-0">
                <div className="row align-self-center">
                    <div className="col-md-6 col-12">
                        <figure><img src={Namaste} alt="Namaste" className="left-img img-shadow" /></figure>
                    </div>
                    <div className="col-md-6 col-12 align-self-center">
                        <div className="px-md-5 px-0 mt-sm-0 mt-4">
                          <h3 className="help-title font-weight-bold">Help us Help you</h3>
                          <p className="mt-3">We have 3 questions to create the compass to a new you. </p>
                          <p className="mt-3">Everything you write is confidential and saved <br/>in your journal.</p>
                        </div>
                          <button value={1} className="btn btn-primary" onClick={this.clickBtn}>Go to Questions</button>
                    </div>
                </div>
            </div>     
          </div>
          <GeometryBundle1 />
          <CommonFooter />
        </div> 
      </>
    );
  }
}