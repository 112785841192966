import React, { Component } from "react";
import { Link,Redirect } from "react-router-dom";
import Updates from "./../images/updates.svg";
import Settings from "./../images/settings.svg";
import Home from "./../images/home.svg";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class AuthLinks extends Component {
  userData;
  constructor(props) 
  {
    super(props);
    this.state = { navigate: false,verification: false,isLoading:false,redirect: false };
    this.user      = JSON.parse(localStorage.getItem("userData"));
  }

  onLogoutHandler = () => {
    localStorage.clear();
    window.location.href = '/sign-in';
  }; 

  render() {
    const { navigate } = this.state;
    const isLoading    = this.state.isLoading;

    if (navigate) {
      return <Redirect to="/sign-in" push={true} />;
    }

    const login = localStorage.getItem("isLoggedIn");

    if(!login) 
    {
      return (
        <>
          <div className="top-right-links without_login">
            <Link to="/sign-in" className="text-white ml-5">Sign In |</Link> 
            <Link to="/sign-up" className="text-white ml-1">Sign Up</Link>
          </div>
        </>
      );
    }
    else
    {
      return (
        <>
          {(window.location.href.indexOf("home") > -1 || window.location.href.indexOf("welcome") > -1 || window.location.href.indexOf("email-verification") > -1 || window.location.href.indexOf("start-day") > -1 || window.location.href.indexOf("feeling") > -1) ? (
              <></>
            ) : (
              <div className="top-home-link">
                <Link to="/home"><figure><img src={Home} alt="Home" /></figure></Link>
              </div>
          )}

          {(window.location.href.indexOf("welcome") > -1 || window.location.href.indexOf("email-verification") > -1 || window.location.href.indexOf("start-day") > -1 || window.location.href.indexOf("feeling") > -1 || window.location.href.indexOf("journey") > -1) ? (
            <div className="top-right-links">
              <span onClick={this.onLogoutHandler} className="text-white ml-3 cur_pointer">Log Out</span>            
            </div>
          ) : (
            <div className="top-right-links">
              <Link to="/memberships"><FontAwesomeIcon icon={faDollarSign} className="mr-2"/></Link>
              <Link to="/updates"><figure><img src={Updates} alt="Updates" /></figure></Link>
              <Link to="/settings"><figure><img src={Settings} alt="Settings" /></figure></Link>
              <span onClick={this.onLogoutHandler} className="text-white ml-3 cur_pointer">Log Out</span>            
            </div>
          )}

          {isLoading ? (
              <div id="overlay">
              <div className="spinner-border" id="spinner"role="status">
                  <span className="sr-only">Loading...</span>
              </div>
              </div>
            ) : (
              <span></span>
          )}
        </>
      );
    }
  }
}