import React, { Component } from "react";
import { Link } from "react-router-dom";
import AuthLinks from "./../AuthLinks";
import CirclesLogo from "./../../images/logo-circles.png";
import CirclesGeometry from "./../../images/circle-geometry.png";
import Geometry7 from "./../../images/geo-7.png";

export default class CommonCirclesHeader extends Component {

  constructor(props) 
  {
    super(props);
    this.state = { };
  }

  render() {

    return (
        <>
          <header>
            <img src={CirclesGeometry} alt="geometry" className="top-circle-geom" />
            <img src={Geometry7} alt="geometry" className="top-geom" />
            <Link to="#" className="secondlogo">
              <img src={CirclesLogo} alt="logo" />
            </Link>
            <AuthLinks />
          </header>
        </>
      );
    }
}