import React, { Component } from "react";
import Meditation from "./../../images/meditation.gif";
import Logo from "./../../images/logo.png";
import Screen4 from "./../Screens/Screen4";
import Screen6 from "./../Screens/Screen6";
import AuthLinks from "./../AuthLinks";
import CommonFooter from "./../CommonFooter";
import GeometryBundle3 from "./../GeometryBundle3";

export default class Screen5 extends Component {
  constructor(props) 
  {
    super(props);
    this.state = { };
  }

  clickBtn = e => {
    this.setState({
      renderView: +e.target.value
    });
  };

  render() {
    if(this.state.renderView === 4)
    {
      return <Screen4 />;
    }
    if(this.state.renderView === 6)
    {
      return <Screen6 />;
    }

    return (
      <>
        <div className="main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>

          <div className="mid-container">
            <div className="content">
                <div className="row align-self-center">
                    <div className="col-md-6 col-12">
                        <figure><img src={Meditation} alt="Meditation" className="left-img img-shadow" /></figure>
                    </div>
                    <div className="col-md-6 col-12 align-self-center">
                        <div className="px-md-5 px-0 mt-sm-0 mt-4">
                          <p>When you are Heart-Centered, you accept and love everyone (including yourself) exactly as they are. You feel and express compassion.</p>
                        </div>
                          <button value={4} className="btn btn-primary" onClick={this.clickBtn}>BACK</button>
                          <button value={6} className="btn btn-primary" onClick={this.clickBtn}>NEXT</button>
                    </div>
                </div>
            </div>     
          </div>
          <GeometryBundle3 />
          <CommonFooter />
        </div>   
      </>
    );
  }
}