import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Logo from "./../images/logo.png";
import StartWithUs from "./../images/start_with_us.gif";
import AuthLinks from "./AuthLinks";
import Loader from "./Loader";
import CommonFooter from "./CommonFooter";
import GeometryBundle3 from "./GeometryBundle3";

export default class StartYourDay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigate: false,
      isLoading: false,
      msg: "",
      redirect: false,
    };
    this.user = JSON.parse(localStorage.getItem("userData"));
  }  
  state = {
    navigate: false,
  };

  clickBtn = e => {
    this.setState({ isLoading: true,redirect:true });
  };


  render() {
    const user = JSON.parse(localStorage.getItem("userData"));
    const isLoading = this.state.isLoading;

    const { navigate } = this.state;

    if(user.email_verified_at === '' || user.email_verified_at === null)
    {
      return <Redirect to="/email-verification" push={true} />;
    }

    if(navigate) 
    {
      return <Redirect to="/sign-in" push={true} />;
    }

    if(this.state.redirect)
    {
      return <Redirect to="/journey" />
    }
    
    const login = localStorage.getItem("isLoggedIn");
    if(!login) 
    {
      return <Redirect to="/sign-in" />;
    }
    return (
      <>
        <div className="main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>

          {isLoading ? (
            <Loader />
            ) : (
              <span></span>
          )}          

          <div className="mid-container">
            <div className="content mt-0">
                <div className="row align-self-center">
                    <div className="col-md-12 col-12">
                        <h4 className="mt-2 mb-4">Hi {user.name}!</h4>
                        <figure><img src={StartWithUs} alt="StartWithUs" className="center-img img-shadow mb-4" /></figure>
                        <button value="start_journey" className="btn btn-primary" onClick={this.clickBtn}>Get Started</button>
                    </div>
                </div>
            </div>     
          </div>
          <GeometryBundle3 />
          <CommonFooter />
        </div> 
      </>
    );
  }
}