import React, { Component } from "react";
import Logo from "./../images/logo.png";
import SocreatesPrivacyPolicy from "./../images/Socreates+Privacy+Policy.gif";
import CommonFooter from "./CommonFooter";
import GeometryBundle1 from "./GeometryBundle1";
import AuthLinks from "./AuthLinks";

export default class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }  

  render() {
    return (
      <>
          <div className="main-wrapper another-main-wrapper">
            <header>
              <figure className="waves-top"></figure>
              <figure className="logo">
                <img src={Logo} alt="logo" />
              </figure>
              <AuthLinks />
            </header>

                <div className="mid-container library-container-1 pp-container">
                  <div className="content mt-4">
                    <div className="video-listing-wrap">          
                      <div class="container">
                        <div className="row">
                          <figure className="text-center" style={{ display: 'block',width: '100%' }}><img src={SocreatesPrivacyPolicy} alt="Great Job" className="center-img img-shadow mb-4" /></figure>
                          <h3 style={{ display: 'block',width: '100%' }} className="mb-3"><strong>Privacy Policy</strong></h3>
                            <p style={{ display: 'block',width: '100%' }}>All user information is kept private. Socreates Users own their own data. Socreates respects and is committed to maintaining your privacy. User’s journal data is private for user’s eyes only and maintained exclusively for the user’s own reflection and self-improvement. Socreates shares only anonymized aggregated data for the purposes of broader improvement. The data entered by any user is kept confidential. Users can at any point delete their data by going to Settings within the App and selecting “Delete My Account”.</p>
                            <p style={{ display: 'block',width: '100%' }}><strong>The data we process is limited to:</strong></p>
                            <ul style={{ display: 'block',width: '100%' }}>
                              <li><p><strong>Name</strong></p></li>
                              <li><p><strong>Email Address</strong> </p></li>
                              <li><p><strong>Date Account Created</strong></p></li>
                              <li><p><strong>Amount of time spent in the App</strong> </p></li>
                              <li><p><strong>Number of Sessions user has had in App</strong></p></li>
                              <li><p><strong>User last Login</strong></p></li>
                              <li><p><strong>Date and time that an Insight Video is watched</strong></p></li>
                              <li><p><strong>Date and time that a voice note is recorded</strong></p></li>
                            </ul>
                            <p>Socreates uses 1) the name 2) email address 3) date created and 4) user last log-in in order to provide optimal customer support when troubleshooting issues. </p>
                            <p>Socreates uses the data in aggregate for all users on 1) number of sessions in the app 2) amount of time spent in the app in order to analyze user behavior in aggregate.</p>
                            <p>Socreates <strong><em>does not store user written and or selected answers</em></strong> such as journal entries or voice notes such (e.g. <strong><em>Ego Thickle’s VentBox, HeartBox </em></strong>recordings); all user generated content is stored only on the user’s phone. </p>
                            <p>Socreates does store the date and time that a user unlocks a particular insight video so that user can receive a summary of the insights unlocked and reminder to put those insights into practice. Socreates does store the date and time that a user makes a recording in the<strong> VentBox</strong> <strong><em>&amp; HeartBox,</em></strong> so the user can receive a notification when the voice note is scheduled to be deleted. </p> 

                            <p>However, the actual voice notes and written notes are not accessible to anyone aside from the user who recorded them. </p>
                            <p><strong><em>The creators and developers have no visibility into any input or selected answers users choose.</em></strong></p>
                            <p><strong><em>Users own their own data and can delete the data at any point by going to the “Settings” menu within the app and clicking “Delete My Account”.</em></strong></p>
                            <p><strong>Socreates Liability Disclaimer:<br/></strong>By using the website, mobile app, products and/or services of Socreates, the user releases Socreates Inc (including its board, officers, employees and other users) jointly and severally from any and all actions, causes of actions, claims and demands for, upon or by reason of any damage, loss or injury, which hereafter may be sustained, perceived, hinted or indicated by participating, subscribing, using or trying out our products and services. Socreates, Inc <strong><em>does not</em></strong> provide liability insurance for protection of users who visit and/or use its website, mobile app, products and/or services.</p>
                            <p>Please note that users who have a history of mental illness or brain disorder such as epilepsy should consult their doctor before using Socreates. Socreates uses audio frequencies and visual effects which could affect users with mental illness or brain disorders. Socreates Inc, is <strong>not liable</strong> if they choose to proceed at their own risk.</p>
                            <p><strong>Socreates, Inc.</strong></p>                         
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
          <GeometryBundle1 />
          <CommonFooter />
      </>
    );
  }
}