import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Namaste from "./../images/namaste.gif";
import Logo from "./../images/logo.png";
import AuthLinks from "./AuthLinks";
import CommonFooter from "./CommonFooter";
import GeometryBundle1 from "./GeometryBundle1";

export default class SessionLocked extends Component {
  constructor(props) 
  {
    super(props);
    this.state = { };
  }

  clickBtn = e => {
    this.setState({
      renderView: e.target.value
    });
  };

  render() {
    if(this.state.renderView === 'gotolibrary')
    {
        return <Redirect
          to={{
          pathname: "/library",
          state: { received_video_url:'' }
        }}
        />
    }

    return (
      <>
        <div className="main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>        

          <div className="mid-container">
            <div className="content mt-0">
                <div className="row align-self-center">
                    <div className="col-md-12 col-12">
                        <figure><img src={Namaste} alt="Namaste" className="center-img img-shadow mb-4" /></figure>
                        <h4 className="mt-2 mb-4">Your next session will be unlocked at Midnight EST. </h4>
                        <button value={"gotolibrary"} className="btn btn-primary" onClick={this.clickBtn}>OK</button>
                    </div>
                </div>
            </div>     
          </div>
          <GeometryBundle1 />
          <CommonFooter />
        </div> 
      </>
    );
  }
}