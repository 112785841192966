import React, { Component } from "react";
import ThumbsUp from "./../../images/h-thumbsUp.gif";
import Logo from "./../../images/logo.png";
import Screen7 from "./../Screens/Screen7";
import Screen9 from "./../Screens/Screen9";
import AuthLinks from "./../AuthLinks";
import CommonFooter from "./../CommonFooter";
import GeometryBundle3 from "./../GeometryBundle3";

export default class Screen8 extends Component {
  userData;
  constructor(props) 
  {
    super(props);
    this.state = { };
  }

  clickBtn = e => {
    this.setState({
      renderView: +e.target.value
    });
  };

  render() {
    if(this.state.renderView === 7)
    {
      return <Screen7 />;
    }
    if(this.state.renderView === 9)
    {
      return <Screen9 />;
    }

    return (
      <>
        <div className="main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>

          <div className="mid-container">
            <div className="content">
                <div className="row align-self-center">
                    <div className="col-md-6 col-12">
                        <figure><img src={ThumbsUp} alt="ThumbsUp" className="left-img img-shadow" /></figure>
                    </div>
                    <div className="col-md-6 col-12 align-self-center">
                        <div className="px-md-5 px-0 mt-sm-0 mt-4">
                          <p>What is a Perspective Hack? <br />A new understanding of an emotional, mental, or behavioral pattern.</p>
                        </div>
                        <div className="mt-2">
                          <button value={7} className="btn btn-primary" onClick={this.clickBtn}>BACK</button>
                          <button value={9} className="btn btn-primary" onClick={this.clickBtn}>NEXT</button>
                        </div>
                    </div>
                </div>
            </div>     
          </div>
          <GeometryBundle3 />
          <CommonFooter />
        </div> 
      </>
    );
  }
}