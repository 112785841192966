import React, { Component } from "react";
import Modal from 'react-bootstrap/Modal';
import VimeoCons from "./VimeoCons";
import Moment from 'moment';
import axios from "axios";

export default class CustomVideoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.user = JSON.parse(localStorage.getItem("userData"));
  }  

  save_last_watched = e => {
    var videoID = this.props.video_id;
    var uservideo_id = this.props.uservideo_id;      

    var last_viewed = Moment(new Date()).format('YYYY-MM-DD HH:mm:ss');

    axios.post('/update_user_videos', { videoID:videoID,uservideo_id:uservideo_id,last_viewed:last_viewed })
      .then((response) => {
        if(response.status === 200) 
        {       
          this.setState({

          });
        }
      })
      .catch((error) => {

      }); 
  };

  render() {  
    return(
      <>
        <div className="d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
        </div>

        <Modal 
        size="lg"
        show={this.props.isOpen} 
        onHide={this.props.closeModal}
        backdrop="static" 
        keyboard={false}
        centered
        animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.props.video_name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.video_type === "insight" ? (
              <div className="insight_watched" onClick={this.save_last_watched}>
                <VimeoCons get_thumbnail="TRUE" get_video_with_subtitles="TRUE" video_url={this.props.video_info} />
              </div>
              ) : (
                <>
                  <VimeoCons get_thumbnail="TRUE" get_video_with_subtitles="TRUE" video_url={this.props.video_info} />
                </>
              )
            }
          </Modal.Body>
        </Modal>
      </>
    );
  }
}