import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Button, Form, FormGroup, Input } from "reactstrap";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from "./../images/logo.png";
import AuthLinks from "./AuthLinks";
import Loader from "./Loader";
import CommonFooter from "./CommonFooter";
import GeometryBundle2 from "./GeometryBundle2";

export default class PublicProfile extends Component {

  constructor(props) 
  {
    super(props);
    this.state = {
      redirect:false,
      isLoading:false,
      user_unique_custom_id:'',
      name:'',
      email:'',
      username:'',
      existing_username:'',
      gender:'',
      location:'',
      age:'',
      gender_status:'',
      location_status:'',
      age_status:'',
      public_image_name:'',
      public_image:'',
      imagedata:'',
      errMsgEmail: "",
      errMsgName: "",
      errMsgPhone: "",
      errMsg: "",
      errMsgUsername: "",
      number_change: false,
      errors: {},
      successmsg:'',
      public_avatars : [],
      activeItem: -1,
      location_switch:false,
      gender_switch:false,
      age_switch:false,
      public_image_preview:''
    };
  }
  
  componentDidMount() 
  {
      this.setState({ isLoading:true });

      axios.get('/user', {})
      .then((response) => {
        if(response.status === 200) 
        {        
          this.setState({
            user_unique_custom_id: response.data.data.user_unique_custom_id,
            name: response.data.data.name,
            email: response.data.data.email,
          });
        }
      })
      .catch((error) => {

      });

      axios.get('/get_user_public_information', {})
      .then((response) => {
        this.setState({ isLoading:false });
        if(response.status === 200) 
        {        
          this.setState({
              username : response.data.data.username,
              existing_username : response.data.data.username,
              location : response.data.data.location,
              gender : response.data.data.gender,
              age : response.data.data.age,
              age_status : response.data.data.age_status,
              gender_status : response.data.data.gender_status,
              location_status : response.data.data.location_status,
              public_image : response.data.data.public_image,
          });

          if(response.data.data.gender_status && response.data.data.gender_status ==='public')
          {
            this.setState({ gender_switch:true });
          }

          if(response.data.data.location_status && response.data.data.location_status ==='public')
          {
            this.setState({ location_switch:true });
          }

          if(response.data.data.age_status && response.data.data.age_status ==='public')
          {
            this.setState({ age_switch:true });
          }
        }
      })
      .catch((error) => {

      });   

      axios.get('/get_public_avatars', {})
      .then((response) => {
        this.setState({ isLoading:false });
        if(response.status === 200) 
        {        
          this.setState({
              public_avatars : response.data.data
          });
        }
      })
      .catch((error) => {

      });    
  }

  onLocationSwitchChange = newValue => {
    this.setState({ location_switch: !this.state.location_switch });
  }

  onGenderSwitchChange = newValue => {
    this.setState({ gender_switch: !this.state.gender_switch });
  }

  onAgeSwitchChange = newValue => {
    this.setState({ age_switch: !this.state.age_switch });
  }    

  onChangehandler = (e) => {
    let name  = e.target.name;
    let value = e.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };

  handleFileChange(file)
  {
    this.setState({ 
      imagedata: file[0],
      public_image_preview : URL.createObjectURL(file[0])
    });
  }

  selected_public_image = e => {
    this.setState ({ activeItem:(e.target.attributes.data_index) ? e.target.attributes.data_index.value:'1',public_image_name:e.target.alt })
  }

  validateForm() 
  {
    let username_val = this.state.username;
    let location_val = this.state.location;
    let gender_val   = this.state.gender;
    let age_val      = this.state.age;
    let errors = {};
    let formIsValid = true;

    if(!username_val) 
    {
      formIsValid = false;
      errors["username"] = "* Username is Required";
    }

    if(!location_val) 
    {
      formIsValid = false;
      errors["location"] = "* Location is Required";
    }

    if(!gender_val) 
    {
      formIsValid = false;
      errors["gender"] = "* Gender is Required";
    }

    if(!age_val) 
    {
      formIsValid = false;
      errors["age"] = "* Age is Required";
    }        

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  updatePublicProfile = e => {
    e.preventDefault();
    if (this.validateForm()) 
    {
      this.setState({ isLoading: true });

      const formElem = document.querySelector('#editing_public_profile');

      var formData = new FormData(formElem);

      formData.delete('public_image_name');
      formData.delete('public_image');

      if(this.state.imagedata)
      {
        formData.append("public_image", this.state.imagedata); 
        formData.append("public_image_name", ''); 
      }
      else
      {
        formData.append("public_image", ''); 
        if(this.state.public_image_name)
        {
          formData.append("public_image_name", this.state.public_image_name); 
        }
        else if(this.state.public_image)
        {
          formData.append("public_image_name", this.state.public_image);
        }
        else
        {
          formData.append("public_image_name", this.state.public_image_name); 
        }
      }

      if(formData.has('location_status'))
      {
        formData.append("location_status", "public");
      }
      else
      {
        formData.append("location_status", "private");
      }

      if(formData.has('gender_status'))
      {
        formData.append("gender_status", "public");
      }
      else
      {
        formData.append("gender_status", "private");
      }

      if(formData.has('age_status'))
      {
        formData.append("age_status", "public");
      }
      else
      {
        formData.append("age_status", "private");
      }            
      axios
        .post("/save_public_information", formData)
        .then((response) => {
          this.setState({ isLoading: false });

          if(response.status === 200) 
          {
            this.setState({
              successmsg: "Data Updated Successfully.",
              redirect: true,
              signupData: {
                username: "",
                gender: "",
                location: "",
                age: "",
                gender_status: "",
                location_status: "",
                age_status: "",
                public_image: "",
                public_image_name: "",
                imagedata: "",
              },
            });
          }

          if (response.status === "failed") 
          {
            this.setState({ msg: response.data.error });
            setTimeout(() => {
              this.setState({ msg: "" });
            }, 2000);
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          /*if(error.response.status === 400) 
          {
            if('location' in error.response.data.errors)
            {
            }
            else
            {
            }
          }*/

          if(error.response.status ===  400)
          {
            if(error.response.data.errors && 'username' in error.response.data.errors)
            {
              this.setState({ errMsgUsername:'Username is already taken.' });
            }
          }          
        });
    }    
  };

  render() {
    const isLoading = this.state.isLoading;
    
    const login = localStorage.getItem("isLoggedIn");
    const baseapiURLMedia = window.baseapiURLMedia;

    if(this.state.redirect) 
    {
      return <Redirect to="/settings" />;
    }  

    if(!login) 
    {
      return <Redirect to="/sign-in" />;
    }     

    const user = JSON.parse(localStorage.getItem("userData"));
    if(user && (user.email_verified_at === '' || user.email_verified_at === null))
    {
      return <Redirect to="/email-verification" push={true} />;
    }

    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 4
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3
      }
    };  

    return (
      <>
        <div className="main-wrapper another-main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>

          {isLoading ? (
            <Loader />
            ) : (
              <span></span>
          )}

          <div className="mid-container public-profile-container">
            <div className="content mt-0">
              <Form className="" encType="multipart/form-data" id="editing_public_profile">
              <div className="row">

                <div className="col-sm-5">
                    <div className="settings-left-panel h-100">
                        <figure className="text-center">
                          {!this.state.public_image_preview ? (
                          <>
                              {this.state.public_image ? (
                                <>
                                <img src={baseapiURLMedia+this.state.public_image} className="settings_profile_image" alt="ProfileImage"/>
                                </>
                              ) : (
                                <>
                                <img src={baseapiURLMedia+'default-profile-image.svg'} className="settings_profile_image" alt="ProfileImage"/>
                                </>
                              )}
                              </>
                            ) : (
                                <img src={this.state.public_image_preview} className="settings_profile_image" alt="ProfileImage"/>
                            )
                            }
                        </figure>

                        <p className="text-success">{this.state.successmsg}</p>

                        <h5 className="text-center mt-2"><FontAwesomeIcon icon={faUser} /> Upload an Avatar</h5>
                        <div className="input-group mb-3 profile-img-upload">
                          <div className="custom-file">
                            <Input
                              type="file"
                              name="public_image"
                              id="public_image"
                              className="custom-file-input"
                              placeholder="Upload Profile Image"
                              onChange={(e) => this.handleFileChange(e.target.files)}
                              aria-describedby="public_profile_pic"
                            />
                            <label className="custom-file-label" htmlFor="public_image">Choose file</label>
                          </div>
                        </div>
                        <h5 className="text-center mt-4 mb-4">- OR Choose from Below Ones -</h5>
                        <div className="profile-img-slide">
                            <Carousel responsive={responsive} >
                              {this.state.public_avatars.map((public_avatar, index) => (
                                  <figure>
                                    <img key={index} src={baseapiURLMedia+public_avatar.image_url} alt={public_avatar.image_url} className={(this.state.activeItem == index) ? 'public_profile_active img-responsive existing_public_avatars' : 'img-responsive existing_public_avatars'} onClick={this.selected_public_image.bind(this)} data_index={index} />
                                  </figure>
                              ))}
                            </Carousel>
                        </div>                         
                    </div>
                </div>

                <div className="col-sm-7 mt-3 mt-sm-0">
                    <div className="setting-right-panel">
                        <div className="row">
                            <div className="col-12">
                                <div className="row">

                                      <div className="col-sm-12">
                                        <FormGroup>
                                          <label>Username <span className="required_field_star">*</span></label>
                                          <Input
                                            type="text"
                                            name="username"
                                            className="form-control"
                                            placeholder="Enter Username"
                                            value={this.state.username}
                                            onChange={this.onChangehandler}
                                            disabled={this.state.existing_username ? true : false}
                                          />
                                          <span className="text-danger">{this.state.msg}</span>
                                          <span className="text-danger">{this.state.errMsgUsername}</span>
                                          <span className="text-danger">{this.state.errors.username}</span>
                                        </FormGroup> 
                                      </div>

                                      <div className="col-lg-6 col-7">
                                        <FormGroup>
                                            <label>Location <span className="required_field_star">*</span></label>
                                            <Input
                                              type="text"
                                              name="location"
                                              className="form-control"
                                              placeholder="Enter Location"
                                              value={this.state.location}
                                              onChange={this.onChangehandler}
                                            />
                                            <span className="text-danger">{this.state.msg}</span>
                                            <span className="text-danger">{this.state.errMsgName}</span>
                                            <span className="text-danger">{this.state.errors.location}</span>
                                        </FormGroup>
                                      </div>
                                      <div className="col-lg-6 col-5">
                                          <FormGroup>
                                          <label className="d-block">Private/Public</label>
                                          <div className="toggle-switch small-switch">
                                          <input
                                            type="checkbox"
                                            className="toggle-switch-checkbox"
                                            name="location_status"
                                            id="location_status"
                                            checked={this.state.location_switch}
                                            onChange={e => this.onLocationSwitchChange(e.target.checked)}
                                          />
                                          <label className="toggle-switch-label" htmlFor="location_status">
                                            <span className="toggle-switch-inner" data-yes="Public" data-no="Private"/>
                                            <span className="toggle-switch-switch" />
                                          </label>
                                          </div>
                                          </FormGroup>
                                      </div>

                                      <div className="col-lg-6 col-7">
                                        <FormGroup>
                                            <label>Gender <span className="required_field_star">*</span></label>
                                            <Input
                                              type="text"
                                              name="gender"
                                              className="form-control"
                                              placeholder="Enter Gender"
                                              value={this.state.gender}
                                              onChange={this.onChangehandler}
                                            />
                                            <span className="text-danger">{this.state.msg}</span>
                                            <span className="text-danger">{this.state.errMsgName}</span>
                                            <span className="text-danger">{this.state.errors.gender}</span>
                                        </FormGroup>
                                      </div>
                                      <div className="col-lg-6 col-5">
                                          <FormGroup>
                                          <label className="d-block">Private/Public</label>
                                          <div className="toggle-switch small-switch">
                                            <input
                                              type="checkbox"
                                              className="toggle-switch-checkbox"
                                              name="gender_status"
                                              id="gender_status"
                                              checked={this.state.gender_switch}
                                              onChange={e => this.onGenderSwitchChange(e.target.checked)}
                                            />
                                            <label className="toggle-switch-label" htmlFor="gender_status">
                                              <span className="toggle-switch-inner" data-yes="Public" data-no="Private"/>
                                              <span className="toggle-switch-switch" />
                                            </label>
                                          </div>
                                          </FormGroup>
                                      </div>

                                      <div className="col-lg-6 col-7">
                                        <FormGroup>
                                            <label>Age (In Years) <span className="required_field_star">*</span></label>
                                            <Input
                                              type="text"
                                              name="age"
                                              className="form-control"
                                              placeholder="Enter Age (In Years)"
                                              value={this.state.age}
                                              onChange={this.onChangehandler}
                                            />
                                            <span className="text-danger">{this.state.msg}</span>
                                            <span className="text-danger">{this.state.errMsgName}</span>
                                            <span className="text-danger">{this.state.errors.age}</span>
                                        </FormGroup>
                                      </div>
                                      <div className="col-lg-6 col-5">
                                          <FormGroup>
                                          <label className="d-block">Private/Public</label>
                                          <div className="toggle-switch small-switch">
                                            <input
                                              type="checkbox"
                                              className="toggle-switch-checkbox"
                                              name="age_status"
                                              id="age_status"
                                              checked={this.state.age_switch}
                                              onChange={e => this.onAgeSwitchChange(e.target.checked)}
                                            />
                                            <label className="toggle-switch-label" htmlFor="age_status">
                                              <span className="toggle-switch-inner" data-yes="Public" data-no="Private"/>
                                              <span className="toggle-switch-switch" />
                                            </label>
                                          </div>
                                          </FormGroup>
                                      </div>                                                                          

                                    </div>                                  
                                                                       
                                    <p className="text-danger">{this.state.errMsg}</p>
                                    <p className="text-success">{this.state.successmsg}</p>

                                    <div className="form-group text-center mb-0">
                                      <Button
                                        type="submit"
                                        className="btn btn-primary"
                                        color="success"
                                        onClick={this.updatePublicProfile}
                                      >
                                      Update Public Profile
                                      </Button>
                                    </div>
                            </div>
                        </div>                      
                    </div>
                </div>
                </div>
              </Form>
            </div>
          </div>

          <GeometryBundle2 />
          <CommonFooter />
        </div> 
      </>
    );
  }
} 