import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import Logo from "./../../images/logo.png";
import JourneyGif from "./../../images/journey_gif.gif";
import AuthLinks from "./../AuthLinks";
import Loader from "./../Loader";
import SessionLocked from "./../SessionLocked";
import CommonFooter from "./../CommonFooter";
import GeometryBundle1 from "./../GeometryBundle1";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigate: false,
      isLoading: false,
      msg: "",
      redirect: false,
      revisit: false,
      active_journey_id:'',
      day_count:1,
      question_count:0,
      journey_finish:false,
      has_suggested_journey:'',
      daily_session_reached:false
    };
    this.user = JSON.parse(localStorage.getItem("userData"));
  }  

  componentDidMount() 
  {
    this.setState({ isLoading: true });
    localStorage.removeItem('feel_id');
    localStorage.removeItem('feel_date');

    /***********************************************/
    axios
    .get("/get_last_attempted_journey", {
    })
    .then((response) => {
      if(response.status === 200)
      {
        if(response.data.data)
        {
          this.setState({ revisit:true });
        }
      }
    })
    .catch((error) => {
      this.setState({ isLoading: false });
    });

    /***********************************************/
    axios
    .get("/user", {
    })
    .then((response) => {
      if(response.status === 200)
      {
        if(response.data.data)
        {
          this.setState({ has_suggested_journey:response.data.data.has_suggested_journey  });
          localStorage.setItem("userData", JSON.stringify(response.data.data));
          localStorage.setItem("membership_array", JSON.stringify(response.data.membership));
          if(response.data.membership && response.data.membership.length)
          {
            localStorage.setItem("plan_id", JSON.stringify(response.data.membership[0].id));
          }
        }
      }
    })
    .catch((error) => {
      this.setState({ isLoading: false });
    });     

    /***********************************************/
    axios
    .post("/myMemberShipJourney", {
    })
    .then((response) => {
      //this.setState({ isLoading: false });

      if(response.status === 200)
      {
        if(response.data.data)
        {
          var latest_active_journey = response.data.data[0];
          localStorage.setItem("journey_id", latest_active_journey.journeyID);
          localStorage.setItem("journey_name", latest_active_journey.journeyName);
          this.setState({ active_journey_id: latest_active_journey.journeyID },
            () => 
                axios
                .get("/get_question_count_for_day", { 
                  params: { 'journey_id':latest_active_journey.journeyID }
                })
                .then((response) => {
                  this.setState({ isLoading: false });

                  if(response.status === 200)
                  {
                    if(response.data)
                    {
                      this.setState({ isLoading: false,question_count:response.data.data });          
                    }
                  }
                })            
          );
        }
      }
    })
    .catch((error) => {
      this.setState({ isLoading: false });
    });  
  }

  componentWillUnmount() {
    this.setState({ isLoading: false }); 
  }

  state = {
    navigate: false,
  };

  visit_journey = e => {
    this.setState({ isLoading: true });
    const journey_id = localStorage.getItem('journey_id');
    const plan_id    = localStorage.getItem("plan_id");

    if(journey_id && plan_id)
    {
      axios
      .get("/getJourneyRoute", { 
        params: { 'journey_id':journey_id,'plan_id':plan_id }
      })
      .then((response) => {
        if(response.status === 200)
        {
          if(response.data && response.data.success === 'Daily Session Limit Reached')
          {
            this.setState({ daily_session_reached: true });
          } 
        }
      })
      .catch((error) => {
        //this.setState({ isLoading: false });
      }); 
    }

    axios
    .get("/get_progress_data", {
        params:{journey_id : this.state.active_journey_id}
    })
    .then((response) => {
      if(response.status === 200)
      {
        if(response.data.data)
        {
          localStorage.setItem("progress_data", JSON.stringify(response.data.data));

          if(response.data.data.get_day_count === 33)
          {
            this.setState({ journey_finish:true });
          }

          this.setState({ day_count: response.data.data.get_day_count},
          () => 
            axios
            .get("/check_feel_exists", {
              params : {feel_session_id:response.data.data.get_day_count + 1, journey_id : journey_id}
            })
            .then((response) => {
              if(response.status === 200)
              {
                if(response.data.data)
                {
                  if(response.data.data.record_exists === 'true')
                  {
                    localStorage.setItem('feel_id',response.data.data.feel_id);
                    localStorage.setItem('feel_session_id',response.data.data.feel_session_id);
                  }
                  this.setState({ isLoading: false,redirect:true });
                }
              }
            })             
          );
        }
      }
    })
    .catch((error) => {
      this.setState({ isLoading: false });
    }); 
  };

  render() {
    const user = JSON.parse(localStorage.getItem("userData"));

    const { navigate } = this.state;
    const isLoading = this.state.isLoading;
    const day_count = this.state.day_count;

    if(this.state.daily_session_reached)
    {
      return <SessionLocked />
    }

    if(user && this.state.has_suggested_journey === 'false')
    {
      return <Redirect to="/welcome" push={true} />;
    }

    if(user && (user.email_verified_at === '' || user.email_verified_at === null))
    {
      return <Redirect to="/email-verification" push={true} />;
    }

    if(navigate) 
    {
      return <Redirect to="/sign-in" push={true} />;
    }

    if(this.state.journey_finish)
    {
      return <Redirect to="/journey-finish" push={true} />;
    }

    if(this.state.redirect && localStorage.getItem("feel_id") === null && localStorage.getItem("feel_date") === null)
    {
      return <Redirect to={{
        pathname: '/feeling',
        state: { day_count: day_count, question_count:this.state.question_count }
          }}
      />      
    }
    else
    {
      if(this.state.redirect && day_count === 0)
      {
        return <Redirect to="/start-day" push={true} />;
      }
  
      if(this.state.redirect && this.state.question_count === 0)
      {
        return <Redirect to='/journal' />;
      }

      if(this.state.redirect && this.state.question_count > 0)
      {
        return <Redirect to='/journey' />;
      }
    }
    
    const login = localStorage.getItem("isLoggedIn");

    if(!login) 
    {
      return <Redirect to="/sign-in" />;
    }

    if(this.state.isLoading)
    {
      return (
        <>
          <div className="main-wrapper">
            <header>
              <figure className="waves-top"></figure>
              <figure className="logo">
                <img src={Logo} alt="logo" />
              </figure>
              <AuthLinks />
            </header>

            {isLoading ? (
              <Loader />
                ) : (
                <></>
            )}

              <div className="mid-container">
                <div className="content">
                    <div className="row">
                    </div>
                </div>            
              </div>
            <GeometryBundle1 />
            <CommonFooter />
          </div>
        </>
      );
    }
    else
    {
      return (
        <>
          <div className="main-wrapper">
            <header>
              <figure className="waves-top"></figure>
              <figure className="logo">
                <img src={Logo} alt="logo" />
              </figure>
              <AuthLinks />
            </header>

            {isLoading ? (
              <Loader />
                ) : (
                <></>
            )}

              <div className="mid-container home-container">
                <div className="content mt-3">
                  <h5>Welcome {user.name}!</h5>
                  <p>Tap Below to Continue your Journey Within</p>
                  <figure className="mt-3">
                    <img src={JourneyGif} alt="Journey Within" className="welcome-img cur_pointer" onClick={this.visit_journey}/>
                  </figure>
                </div>            
              </div>
            <GeometryBundle1 />
            <CommonFooter />
          </div>
        </>
      );
    }
  }
}