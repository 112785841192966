import React, { Component } from "react";
import Logo from "./../images/logo.png";
import LegalSocreates from "./../images/Legal+Socreates.gif";
import CommonFooter from "./CommonFooter";
import GeometryBundle1 from "./GeometryBundle1";
import AuthLinks from "./AuthLinks";

export default class TermsofUse extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }  

  render() {
    return (
      <>
          <div className="main-wrapper another-main-wrapper">
            <header>
              <figure className="waves-top"></figure>
              <figure className="logo">
                <img src={Logo} alt="logo" />
              </figure>
              <AuthLinks />
            </header>

                <div className="mid-container library-container-1 pp-container">
                  <div className="content mt-4">
                    <div className="video-listing-wrap">          
                      <div class="container">
                        <div className="row">
                          <figure className="text-center" style={{ display: 'block',width: '100%' }}><img src={LegalSocreates} alt="Great Job" className="center-img img-shadow mb-4" /></figure>
                          <h3 style={{ display: 'block',width: '100%' }} className="mb-3"><strong>Terms of Use by Socreates</strong></h3>
                          <p style={{ display: 'block',width: '100%' }}>Please read the "Socreates Terms of Use & Socreates Liability Disclaimer" carefully before use of any of our products and services, including the Journeys, Flows and any videos on the website, mobile app or social media channels.</p>

                          <h5 style={{ display: 'block',width: '100%' }} className="mt-3"><strong>Conditions of Use</strong></h5>
                          <p>By visiting the website, downloading the mobile app or following the social media channels you agree with the terms of service and liability disclaimer </p>        

                          <h5 style={{ display: 'block',width: '100%' }} className="mt-3"><strong>Copyright</strong></h5>
                          <p>Content published on this website (digital downloads, images, texts, graphics, videos, logos and teachings) is the property of Socreates, Inc and/or its content creators are protected by international copyright laws. The entire compilation of the content found on this website is the exclusive property of Socreates, Inc, with copyright authorship for this compilation by Socreates, Inc. </p>    
                          
                          <h5 style={{ display: 'block',width: '100%' }} className="mt-3"><strong>Communications</strong></h5>
                          <p>The entire communication with us is electronic. Every time you send us an email or visit our website, you are going to be communicating with us. You hereby consent to receive communications from us. If you subscribe to the newsletter on our website, you are going to receive regular emails from us. We will continue to communicate with you by posting news and notices on our website and by sending you emails. You also agree that all notices, disclosures, agreements and other communications we provide to you electronically meet the legal requirements that such communications be in writing. </p>  
                          
                          <h5 style={{ display: 'block',width: '100%' }} className="mt-3"><strong>Applicable Law</strong></h5>
                          <p>By visiting this website, you agree that the laws of New York, USA without regard to principles of conflict of laws, will govern these terms of service, or any dispute of any sort that might come between Socreates, Inc and you, or its business partners and associates. </p>   
                          
                          <h5 style={{ display: 'block',width: '100%' }} className="mt-3"><strong>Disputes</strong></h5>
                          <p>Any dispute related in any way to your visit to this website or to products you purchase from us shall be arbitrated by state or federal court  in New York, USA and you consent to the exclusive jurisdiction and venue of such courts. </p>

                          <h5 style={{ display: 'block',width: '100%' }} className="mt-3"><strong>License and Site Access</strong></h5>
                          <p>Socreates, Inc permits users to access and make personal use of its content. Users are not permitted to download, copy, modify, or distribute with commercial intent any of Socreates proprietary products. </p>     
                          
                          <h5 style={{ display: 'block',width: '100%' }} className="mt-3"><strong>User Account</strong></h5>
                          <p>Users are responsible for maintaining the confidentiality of their user details (username and password). Users are responsible for all activities that occur under their account.  Socreates Inc. reserves the right to terminate accounts, edit or remove content or cancel orders.</p>        
                          
                          <h5 style={{ display: 'block',width: '100%' }} className="mt-3"><strong>Socreates Liability Disclaimer:</strong></h5>
                          <p>By using the website, mobile app, products and/or services of Socreates, the user releases Socreates Inc (including its board, officers, employees and other users) jointly and severally from any and all actions, causes of actions, claims and demands for, upon or by reason of any damage, loss or injury, which hereafter may be sustained, perceived, hinted or indicated by participating, subscribing, using or trying out our products and services. Socreates, Inc does not provide liability insurance for protection of users who visit and/or use its website, mobile app, products and/or services.</p>  
                          <p>Please note that users who have a history of mental illness or brain disorder such as epilepsy should consult their doctor before using Socreates. Socreates uses audio frequencies and visual effects which could affect users with mental illness or brain disorders. Socreates Inc, is not liable if they choose to proceed at their own risk.</p>       
                          
                          <h5 style={{ display: 'block',width: '100%' }} className="mt-4"><strong>Socreates, Inc.</strong></h5>                                                                                                                                                                             
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
          <GeometryBundle1 />
          <CommonFooter />
      </>
    );
  }
}