import React, { Component } from "react";
import Celebration from "./../images/f-tuneup-img.gif";
import Logo from "./../images/logo.png";
import AuthLinks from "./AuthLinks";
import GoToFlowMediator from "./GoToFlowMediator";
import SessionLocked from "./SessionLocked";
import CommonFooter from "./CommonFooter";
import GeometryBundle3 from "./GeometryBundle3";

export default class DailySessionEnd extends Component {
  constructor(props) 
  {
    super(props);
    this.state = { };
  }

  clickBtn = e => {
    this.setState({
      renderView: e.target.value
    });
  };

  render() {
    if(this.state.renderView === 'goto_recommended_flow')
    {
      return <GoToFlowMediator video_info={this.props.video_info} media_id={this.props.media_id} video_name={this.props.video_name} received_video_url={this.props.received_video_url}/>;
    }
    if(this.state.renderView === 'go_to_library')
    {
      return <SessionLocked />;
    }

    return (
      <>
        <div className="main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>

          <div className="mid-container">
            <div className="content">
                <div className="row align-self-center">
                    <div className="col-md-6 col-12">
                        <figure><img src={Celebration} alt="Celebration" className="left-img img-shadow" /></figure>
                    </div>
                    <div className="col-md-6 col-12 align-self-center">
                        <div className="px-md-5 px-0 mt-sm-0 mt-4">
                          <p>Congratulations on unlocking powerful <br/>
                                Insights today. <br/>
                                Do you want to practice your Recommended  <br/>
                                Heartfulness Flow now? (approx 10 min).
                          </p>
                        </div>
                        <button value={"goto_recommended_flow"} className="btn btn-primary mb-3" onClick={this.clickBtn}>Go to Your Recommended Flow</button>
                        <button value={"go_to_library"} className="btn btn-primary mb-3" onClick={this.clickBtn}>Go to Library</button>
                    </div>
                </div>
            </div>     
          </div>
          <GeometryBundle3 />
          <CommonFooter />
        </div>  
      </>
    );
  }
}