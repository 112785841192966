import React, { Component } from "react";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Input } from "reactstrap";
import axios from "axios";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import "./signup.css";
import { Link,Redirect } from "react-router-dom";
import Logo from "./../../images/logo.png";
import SignUp from "./../../images/signup-img.png";
import Loader from "./../../components/Loader";
import CommonFooter from "./../../components/CommonFooter";
import SignUpGeometryBundle from "./../../components/SignUpGeometryBundle";

export default class Signup extends Component {
  userData;
  constructor(props) 
  {
    super(props);
    this.state = {
      signupData: {
        name: "",
        email: "",
        countryIsoCode:'',
        countryCode:'',
        phone: "",
        coupon_code:'',
        password: "",
        c_password: "",
        isLoading: "",
        register_type: "native",
        device_type: "web",
        sign_up_platform: "web",
        email_verified_at: "",
        redirect: false,
        checked: false,
        soul_code_used:'',
        hidden: true
      },
      msg: "",
      successmsg:"",
      errors: {}
    };
    this.toggleShow = this.toggleShow.bind(this);
  }

  handleCheck = () => {
    this.setState({checked: !this.state.checked});
  };

  toggleShow(e) {
    e.preventDefault();
    this.setState({ hidden: !this.state.hidden });
  }

  terms_of_use = () => {
    window.open('https://socreates.com/terms-of-use', '_blank');
  };

  privacy_policy = () => {
    window.open('https://socreates.com/privacy-policy', '_blank');
  };  

  onChangehandler = (e, key) => {
    const { signupData } = this.state;
    signupData[e.target.name] = e.target.value;
    this.setState({ signupData });
  };

  handleOnChange(value, data, event, formattedValue) {
    const { signupData } = this.state;
    signupData["countryIsoCode"] = data.countryCode.toUpperCase();
    signupData["phone"] = value.slice(data.dialCode.length);
    signupData["countryCode"] = data.dialCode;
    this.setState({ signupData });
  };

  validateForm() 
  {
    let signupData = this.state.signupData;
    let errors = {};
    let formIsValid = true;

    if (!signupData["name"]) 
    {
      formIsValid = false;
      errors["name"] = "* Please enter your Name.";
    }

    if (typeof signupData["name"] !== "undefined") 
    {
      if (!signupData["name"].match(/^[a-zA-Z ]*$/)) 
      {
        formIsValid = false;
        errors["name"] = "* Please enter alphabet characters only.";
      }
    }

    if (!signupData["email"]) 
    {
      formIsValid = false;
      errors["email"] = "* Please enter your Email Address.";
    }

    if (typeof signupData["email"] !== "undefined") 
    {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(signupData["email"])) 
      {
        formIsValid = false;
        errors["email"] = "* Please enter valid Email Address.";
      }
    }

    /*if (!signupData["phone"]) 
    {
      formIsValid = false;
      errors["phone"] = "*Please enter your Phone No.";
    }

    if (signupData["phone"] && typeof signupData["phone"] !== "undefined") 
    {
      if (!signupData["phone"].match(/^[0-9]{10}$/)) 
      {
        formIsValid = false;
        errors["phone"] = "* Please enter valid Phone No.";
      }
    }*/

    if (!signupData["password"]) 
    {
      formIsValid = false;
      errors["password"] = "* Please enter your Password.";
    }

    if (!signupData["c_password"]) 
    {
      formIsValid = false;
      errors["c_password"] = "* Please Confirm your Password.";
    }

    if (signupData["password"] !== signupData["c_password"]) 
    {
      formIsValid = false;
      errors["c_password"] = "Passwords Do not Match. Please Enter Correctly.";
    }

    if (this.state.checked) 
    {

    }
    else
    {
      formIsValid = false;
      errors["terms_chk"] = "Please accept Terms and Conditions.";
    }

    /*if (typeof signupData["password"] !== "undefined") 
    {
      if (!signupData["password"].match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) 
      {
        formIsValid = false;
        errors["password"] = "*Please enter secure and strong password.";
      }
    }*/

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onSubmitHandler = (e) => {
    e.preventDefault();
    this.setState({ soul_code_used : this.state.signupData.coupon_code  });
    if (this.validateForm()) 
    {
      this.setState({ isLoading: true });
      axios
        .post("/register", this.state.signupData)
        .then((response) => {
          this.setState({ isLoading: false });

          if (response.status === 200) 
          {
            this.setState({
              successmsg: "Registered Successfully. Redirecting......",
              signupData: {
                name: "",
                email: "",
                phone: "",
                countryCode:'',
                countryIsoCode:'',
                password: "",
                c_password: "",
                register_type: "",
                device_type:"",
                sign_up_platform:"",
                email_verified_at: "",
                coupon_code:''
              },
            });

            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("userData", JSON.stringify(response.data.success));

            localStorage.setItem("userToken", response.data.success.partial_token);
            axios.defaults.headers.common['Authorization'] = "Bearer "+response.data.success.partial_token;

            setTimeout(() => {
              this.setState({ successmsg: "",redirect: true });
            }, 2000);
          }

          if (response.status === "failed") 
          {
            this.setState({ msg: response.data.error });
            setTimeout(() => {
              this.setState({ msg: "" });
            }, 2000);
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          if(error.response.status === 400) 
          {
            if(error.response.data.error.name && error.response.data.error.name.length)
            {
              this.setState({
                msg: error.response.data.error.name[0],
              });
              setTimeout(() => {
                this.setState({ msg: "" });
              }, 5000);
              return false;
            }

            if(error.response.data.error.email && error.response.data.error.email.length)
            {
              this.setState({
                msg: error.response.data.error.email[0],
              });
              setTimeout(() => {
                this.setState({ msg: "" });
              }, 5000);
              return false;
            }

            if(error.response.data.error.c_password && error.response.data.error.c_password.length)
            {
              this.setState({
                msg: "Password and Confirm Password Did Not Match.",
              });
              setTimeout(() => {
                this.setState({ msg: "" });
              }, 5000);
              return false;
            }          
          }
        });
    }
  };
  render() {
    if (this.state.redirect) 
    {
      if(this.state.soul_code_used !=='')
      {
        var soul_code = this.state.soul_code_used;
      }
      else
      {
        soul_code = '';
      }
      
      return <Redirect to={{
        pathname: '/email-verification',
        state: { soul_code:soul_code }
          }}
      /> 
    }
    
    const login = localStorage.getItem("isLoggedIn");

    if(login) 
    {
      return <Redirect to="/home" />;
    }

    const isLoading = this.state.isLoading;
    return (
      <>
        <div className="main-wrapper signup-main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
          </header>

          {isLoading ? (
            <Loader />
            ) : (
            <span></span>
          )}

          <div className="mid-container signup-container">
            <div className="content signup-wrapper">
                <div className="row">
                    <div className="col-md-6 col-12 align-self-center">
                        <figure><img src={SignUp} alt="SignUp" className="left-img" /></figure>
                    </div>
                    <div className="col-md-6 col-12 p-0">
                        <div className="signup-form-wrapper">
                            <Form className="row" id="signup_form">
                                <div className="form-group col-md-6">
                                    <Input
                                      type="name"
                                      name="name"
                                      className="form-control"
                                      placeholder="Enter Name *"
                                      value={this.state.signupData.name}
                                      onChange={this.onChangehandler}
                                      maxLength="30"
                                      autoComplete='off'
                                    />
                                  <div className="errorMsg text-left">{this.state.errors.name}</div> 
                                </div>

                                <div className="form-group col-md-6">
                                    <Input
                                      type="email"
                                      name="email"
                                      className="form-control"
                                      placeholder="Enter Email Address *"
                                      value={this.state.signupData.email}
                                      onChange={this.onChangehandler}
                                      maxLength="60"
                                      autoComplete='off'
                                    />
                                  <div className="errorMsg text-left">{this.state.errors.email}</div>  
                                </div>

                                <div className="form-group col-12">
                                  <div className="input-group signup-btn-group">
                                    <PhoneInput
                                        inputClass={'country'}
                                        country={'us'}
                                        value=''
                                        onChange={this.handleOnChange.bind(this)}
                                        placeholder={'Enter Phone Number (optional)'}
                                        countryCodeEditable={false}
                                        enableSearch={true}
                                        inputProps={{
                                          name: 'phone',
                                        }}
                                        autoComplete='phone'
                                    />
                                    <div className="errorMsg text-left">{this.state.errors.phone}</div>  
                                  </div>
                                </div>

                                <div className="form-group col-md-6 mb-md-2">
                                    <Input
                                      type={this.state.hidden ? 'password' : 'text'}
                                      name="password"
                                      className="form-control"
                                      placeholder="Enter Password *"
                                      value={this.state.signupData.password}
                                      onChange={this.onChangehandler}
                                      maxLength="40"
                                    />
                                    
                                    <div className="errorMsg text-left">{this.state.errors.password}</div>
                                </div>

                                <div className="form-group col-md-6 mb-md-2">
                                    <Input
                                      type={this.state.hidden ? 'password' : 'text'}
                                      name="c_password"
                                      className="form-control"
                                      placeholder="Confirm Password *"
                                      value={this.state.signupData.c_password}
                                      onChange={this.onChangehandler}
                                      maxLength="40"
                                    />
                                     <p onClick={this.toggleShow} className="signup-eye cur_pointer"><FontAwesomeIcon icon={faEye} /></p>
                                    <div className="errorMsg text-left">{this.state.errors.c_password}</div>   
                                </div>                          

                                <div className="form-group col-md-12">
                                  <Input
                                    type="text"
                                    name="coupon_code"
                                    className=""
                                    placeholder="Soul Code"
                                    value={this.state.signupData.coupon_code}
                                    onChange={this.onChangehandler}
                                    maxLength="30"
                                    autoComplete='off'
                                  />  
                                </div>

                                <div className="form-group col-12 check-box-wrap mb-2">
                                    <input type="checkbox" id="terms_chk" name="terms_chk" required="required" onChange={this.handleCheck}/>
                                    <label htmlFor="terms_chk" className="text-left"><span>By going further, you are agreeing to Socreates's <Link to="/terms-of-use" className="text-white external_link">Terms of Use</Link> and <Link to="/privacy-policy" className="text-white external_link">Privacy Policy</Link></span></label>
                                    <div className="errorMsg text-left text-danger">{this.state.errors.terms_chk}</div>
                                </div>

                                <div className="form-group col-md-12">
                                  <p className="text-danger">{this.state.msg}</p>
                                  <p className="text-success">{this.state.successmsg}</p>
                                </div>
                                
                                <div className="form-group col-md-8 text-left align-self-center mb-0 mt-3 mt-md-0 order-2 order-md-0">
                                    <p className="text-white">Already have an account? <Link to="/sign-in" className="text-white external_link"><b>SignIn</b></Link></p>
                                </div>

                                <div className="form-group col-md-4 text-md-right mb-0">
                                    <button className="btn-arrow" onClick={this.onSubmitHandler}>
                                      <FontAwesomeIcon icon={faArrowRight} />
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </div>
                  
                </div>
            </div>     
          </div>

    <SignUpGeometryBundle />
    <CommonFooter />
  </div>
      </>
    );
  }
}