import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import DropIn from "braintree-web-drop-in-react";
import AuthLinks from "./AuthLinks";
import Logo from "./../images/logo.png";
import Loader from "./Loader";
import CommonFooter from "./CommonFooter";
import GeometryBundle2 from "./GeometryBundle2";
 
export default class Checkout extends Component {
  instance;

  constructor(props) 
  {
    super(props);
    this.state = { redirect: false,isLoading:true,clientToken: null,systemTransactionNo:'',redirect_to_home:false };
    this.user = JSON.parse(localStorage.getItem("userData"));
  }
 
  async componentDidMount() {
    this.setState({ isLoading: true });
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c==='x' ? r :(r&0x3|0x8)).toString(16);
    });

    //var used_coupon_code = (this.props.location.state.used_coupon_code === '') ? null : this.props.location.state.used_coupon_code;

    axios
      .post("/initialize_subscription", { membership_id:this.props.location.state.membership_id,plan_id:this.props.location.state.plan_id })
      .then((response) => {
        this.setState({ isLoading: false });
        if (response.status === 200) 
        {
          this.setState({ clientToken:response.data.token,systemTransactionNo:uuid });
        }

        if (response.status === "failed") 
        {
          this.setState({ msg: response.data.error });
          setTimeout(() => {
            this.setState({ msg: "" });
          }, 5000);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  }
 
  async buy() {
    // Send the nonce to your server

    console.log(this.instance);
    if(this.instance !== undefined)
    {
      const { nonce } = await this.instance.requestPaymentMethod();
      const systemTransactionNo = this.state.systemTransactionNo;

      if(nonce)
      {
        this.setState({ isLoading: true });
      }

      axios
      .get("/save_subscription", { params: { pay_token:nonce,systemTransactionNo:systemTransactionNo,membership_id:this.props.location.state.membership_id } })
      .then((response) => {
        //this.setState({ isLoading: false });
        if (response.status === 200) 
        {
          console.log(response);
          this.setState({ redirect_to_home:true });
        }

        if (response.status === "failed") 
        {
          this.setState({ msg: response.data.error });
          setTimeout(() => {
            this.setState({ msg: "",isLoading: false });
          }, 5000);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });   
    }  
  }
 
  render() {
      const isLoading = this.state.isLoading;

      if(this.state.redirect_to_home)
      {
        return <Redirect to="/payment-success"/>;
      }
      
      const user = JSON.parse(localStorage.getItem("userData"));

      if(user && (user.email_verified_at === '' || user.email_verified_at === null))
      {
        return <Redirect to="/email-verification" push={true} />;
      }

      const login = localStorage.getItem("isLoggedIn");

      if(!login) 
      {
        return <Redirect to="/sign-in" />;
      } 

      if (!this.state.clientToken) {
        return (
          <>
            <div className="main-wrapper">
              <header>
                <figure className="waves-top"></figure>
                <figure className="logo">
                  <img src={Logo} alt="logo" />
                </figure>
                <AuthLinks />
              </header>

              {isLoading ? (
                  <Loader />
                ) : (
                  <span></span>
              )}

              <div className="mid-container">
                <div className="content">
                    <div className="row align-self-center">

                    </div>
                </div>     
              </div>
              <GeometryBundle2 />
              <CommonFooter />
            </div> 
          </>
        );
      }
      else
      {
        return (
            <>
              <div className="main-wrapper">
                <header>
                  <figure className="waves-top"></figure>
                  <figure className="logo">
                    <img src={Logo} alt="logo" />
                  </figure>
                  <AuthLinks />
                </header>

                {isLoading ? (
                    <Loader />
                  ) : (
                    <span></span>
                )}

                <div className="mid-container checkout-container">
                  <div className="content mt-0">
                        <h5 className="text-center mb-3">Add the Card Details Below and Click the Buy Button to Complete Payment.</h5>
                        <h6>You are about to be charged ${this.props.location.state.membership_price}.</h6>
                        <DropIn
                          options={{ authorization: this.state.clientToken }}
                          onInstance={(instance) => (this.instance = instance)}
                          onError={(error) => (this.error = error)}
                        />
                        <button className="btn btn-primary" onClick={this.buy.bind(this)}>Buy</button>
                  </div>     
                </div>
                <GeometryBundle2 />
                <CommonFooter />
              </div> 
            </>
          );
      }
    }
}