import React, { Component } from "react";
import { Redirect,Link } from "react-router-dom";
import axios from "axios";
import Logo from "./../images/logo.png";
import AuthLinks from "./AuthLinks";
import Loader from "./Loader";
import VimeoCons from "./VimeoCons";
import InvitationToJournal from "./InvitationToJournal";
import SessionLocked from "./SessionLocked";
import CommonFooter from "./CommonFooter";
import GeometryBundle1 from "./GeometryBundle1";

export default class Journey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigate: false,
      isLoading: false,
      msg: "",
      redirect: false,
      revisit: false,
      question : '',
      question_id : '',
      answers: [],
      journey_name:'',
      video_info:'',
      video_name:'',
      play_video:false,
      invite_to_journal:false,
      daily_session_reached: false,
      flow_video_url:'',
      journey_completed:false,
      redirect_to_membership:false,
    };
    this.user = JSON.parse(localStorage.getItem("userData"));
    this.questions_count = 0;
  }  

  componentDidMount() 
  {
    this.setState({ isLoading: true });
    const user = JSON.parse(localStorage.getItem("userData"));

    if(user && (user.email_verified_at === '' || user.email_verified_at === null))
    {
      return <Redirect to="/email-verification" push={true} />;
    }
    
    const journey_id     = localStorage.getItem("journey_id");
    const plan_id        = localStorage.getItem("plan_id");
    const journey_name   = localStorage.getItem("journey_name");
    const progress_data  = JSON.parse(localStorage.getItem("progress_data"));

    this.setState({ journey_name: journey_name });
    if(progress_data === null)
    {
      axios
      .get("/get_progress_data", {
          params:{journey_id : journey_id}
      })
      .then((response) => {
        if(response.status === 200)
        {
          if(response.data.data.get_day_count === 33)
          {
            this.setState({ journey_completed: true });
          }
        }
      })
      .catch((error) => {
      }); 
    }
    else
    {
      if(progress_data && progress_data.get_day_count === 33)
      {
        this.setState({ journey_completed: true });
      }
    }

    if(progress_data && progress_data.get_day_count === 0)
    {
      /***********************************************/
      axios
      .post("/save_user_journey_status", {
        'journeyId' : journey_id,
        'journey_status' : 'InProgress',
      })
      .then((response) => {
        if(response.status === 200)
        {

        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });  
    }

    if(journey_id && plan_id)
    {
      /***********************************************/
      axios
      .get("/getJourneyRoute", { 
        params: { 'journey_id':journey_id,'plan_id':plan_id }
      })
      .then((response) => {
        if(response.status === 200)
        {
          console.log(response.data);
          if(response.data && response.data.success === 'Free Plan Limit Reached')
          {
            this.setState({ redirect: true });
          }

          if(response.data && response.data.success === 'Daily Session Limit Reached')
          {
            this.setState({ daily_session_reached: true });
          } 

          if(response.data && response.data.success === 'access_denied' && (response.data.reason === 'Free Trial Daily Session limit Reached' || response.data.reason === 'Daily Session Limit Reached'))
          {
            this.setState({ daily_session_reached: true });
          } 

          if(response.data && response.data.success === 'access_denied' && (response.data.reason === 'Daily Session Limit Reached' || response.data.reason === 'Daily Session Limit Reached'))
          {
            this.setState({ daily_session_reached: true });
          } 

          if(response.data && response.data.success === 'access_denied' && response.data.reason === 'Membership is Invalid')
          {
            this.setState({ redirect_to_membership: true });
          }          

          if(response.data && response.data.success === 'Journey Finished')
          {
            this.setState({ journey_completed: true });
          }  

          if(response.data && response.data.question)
          {
            this.setState({ question: response.data.question.name,question_id:response.data.question.id,answers:response.data.answers });
          }
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });   

      /***********************************************/
      axios
      .get("/get_question_count_for_day", { 
        params: { 'journey_id':journey_id }
      })
      .then((response) => {
        this.setState({ isLoading: false });

        if(response.status === 200)
        {
          this.questions_count = response.data.data;
          if(response.data && response.data.data)
          {
            this.setstate({ questions_count:(response.data.data)+1 });
            
          }
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });         
    }
  }

  state = {
    navigate: false,
  };

  clickBtn = e => {
    e.preventDefault();
    const journey_id = localStorage.getItem("journey_id");
    this.questions_count = this.questions_count  + 1;

    this.setState({ isLoading: true });
    const questions_id      = e.target.dataset.next_item_id;
    const currentAnswerID   = e.target.value;
    const next_item_type    = e.target.dataset.next_item_type;
    //const milestone_next_item_type    = e.target.dataset.milestone_next_item_type;
    const currentQuestionID = this.state.question_id;
    const mediaID           = e.target.dataset.milestone_video_id;
    const planID            = localStorage.getItem("plan_id");
    const video_info        = e.target.dataset.video_info;
    const video_name        = e.target.dataset.video_name;
    const flow_video_url    = e.target.dataset.flow_video_url;

    if(next_item_type === 'end_of_daily_session' || next_item_type === 'end_of_journey')
    {
      setTimeout(() => {
        this.setState({ 
          isLoading: false, 
          play_video:true, 
          video_info:video_info,
          video_name:video_name,
          journey_id:journey_id,
          questions_id:questions_id,
          currentAnswerID:currentAnswerID,
          type:next_item_type,
          currentQuestionID:currentQuestionID,
          mediaID:mediaID,
          planID:planID,
          flow_video_url:flow_video_url,
        });
      }, 1500);
    }
    else
    {
      axios
      .get("/getNextJourneyRoute", { 
        params: { journey_id:journey_id, questions_id:questions_id, currentAnswerID:currentAnswerID, type:next_item_type, currentQuestionID:currentQuestionID, mediaID:mediaID, planID:planID }
      })
      .then((response) => {
        setTimeout(() => {
          this.setState({ isLoading: false });
        }, 1500);

        if(response.status === 200)
        {
          if(response.data && response.data.success === 'Free Plan Limit Reached')
          {
            this.setState({ redirect: true });
          }

          if(response.data && response.data.success === 'access_denied' && (response.data.reason === 'Free Trial Daily Session limit Reached' || response.data.reason === 'Daily Session Limit Reached'))
          {
            this.setState({ daily_session_reached: true });
          } 

          if(response.data && response.data.question)
          {
            this.setState({ question: response.data.question.name,question_id:response.data.question.id,answers:response.data.answers });
          }
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
    }
  };

  proceed_to_journal = e => {
    this.setState({ invite_to_journal: true });  
  };

  render() {
    const user = JSON.parse(localStorage.getItem("userData"));
    const { navigate } = this.state;
    const isLoading = this.state.isLoading;
    const questions_count_val = this.questions_count;

    const progress_data = JSON.parse(localStorage.getItem("progress_data"));

    if(user && (user.email_verified_at === '' || user.email_verified_at === null))
    {
      return <Redirect to="/email-verification" push={true} />;
    }

    if(this.state.journey_completed)
    {
      return <Redirect to="/journey-finish" push={true} />;
    }

    if(this.state.redirect_to_membership)
    {
      return <Redirect to="/membership-upgrade" push={true} />;
    }

    if(this.state.invite_to_journal)
    {
      return <InvitationToJournal journey_id={this.state.journey_id} questions_id={this.state.questions_id} currentAnswerID={this.state.currentAnswerID} type={this.state.type} currentQuestionID={this.state.currentQuestionID} mediaID={this.state.mediaID} planID={this.state.planID} video_info={this.state.video_info} video_name={this.state.video_name} flow_video_url={this.state.flow_video_url} />;
    }

    if (navigate) 
    {
      return <Redirect to="/sign-in" push={true} />;
    }
    
    const login = localStorage.getItem("isLoggedIn");

    if(!login) 
    {
      return <Redirect to="/sign-in" />;
    }

    if(this.state.redirect)
    {
      return <Redirect to='/membership-upgrade'/>
    }

    if(this.state.daily_session_reached)
    {
      return <SessionLocked />
    }

    if(this.state.answers)
    {
      var ans = this.state.answers.map((answer,i) => {
        return (
          <li key={answer.answerID} onClick={this.clickBtn} value={answer.answerID} data-flow_video_url={answer.flow_video_url} data-milestone_next_item_type={answer.milestone_next_item_type} data-milestone_video_id={answer.milestone_video_id} data-next_item_id={answer.next_item_id} data-next_item_type={answer.next_item_type} data-video_info={answer.video_info} data-video_name={answer.video_name} data-video_type={answer.video_type}>{answer.name}</li>
        );
      });
    }

    if(this.state.play_video)
    {
      return (
        <>
          <div className="main-wrapper">
            <header>
              <figure className="waves-top"></figure>
              <figure className="logo">
                <img src={Logo} alt="logo" />
              </figure>
              <AuthLinks />
            </header>

            {isLoading ? (
                <Loader count={questions_count_val} />
              ) : (
                <span></span>
            )}
 
          <div className="mid-container">
            <div className="content">
                <div className="row">
                    <div className="col-md-6 col-12 onboarding">
                        <figure><VimeoCons get_thumbnail="TRUE" get_video_with_subtitles="TRUE" video_url={this.state.video_info} /></figure>
                    </div>
                    <div className="col-md-6 col-12 align-self-center">
                        <div className="px-md-5 px-0 journey-video-content">
                          <h5>You've Unlocked a New Insight Video!</h5>
                          <h4 className="mt-sm-4 mt-md-1"><b>{this.state.video_name}</b></h4>
                          <Link to="#" className="btn btn-primary mt-3" onClick={this.proceed_to_journal}>Next</Link>
                          <p className="mt-3 mb-1">You are working your way through</p>
                          <p><i>{progress_data ? progress_data.phase_name : ''} : <span className="text-uppercase">{progress_data ? progress_data.phase_description : ''}</span></i></p>
                        </div>
                    </div>
                </div>
            </div>    
          </div>

          <GeometryBundle1 />
          <CommonFooter />
        </div>
        </>
      )                         
    }

    return (
      <>
        <div className="main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>

          {isLoading ? (
              <Loader count={questions_count_val} />
            ) : (
              <span></span>
          )}

          <div className="mid-container journey-within-container">
            <div className="content">
              <div className="row">
                <div className="col-sm-8 mx-auto">
                  <div className="px-md-5 px-0">
                    <h4>{this.state.journey_name}</h4>
                    <p className="mt-3">{this.state.question}</p>
                  </div>
                </div>
                <div className="col-sm-10 mx-auto align-self-center">

                <div className="row justify-content-center px-md-5">
                  <div className="col-12">
                    <ul className="list-unstyled journey-answerlist mt-0 mb-0">
                      {ans}
                    </ul>
                  </div>

                  <div className="w-100 mt-4"></div>

                  <div className="col-sm-6 mx-auto">
                    <p><i>Choose the answer that feels best to you. <br />You are not being judged or tested.</i></p>
                    <p><i>{progress_data ? progress_data.phase_name : ''} : <span className="text-uppercase">{progress_data ? progress_data.phase_description : ''}</span></i></p>
                  </div>
                </div>
                </div>
              </div>
            </div>     
          </div>
          <GeometryBundle1 />
          <CommonFooter />
        </div>
      </>
    );
  }
}