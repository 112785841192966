import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './responsive.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

/*window.baseURL    = "https://socreatesv2.kitlabs.us";
window.baseURLApi = "https://socreatesv2.kitlabs.us/api";
window.baseURLImage = "https://socreatesv2.kitlabs.us/uploads/";
window.baseapiURLMedia  = "https://socreatesv2.kitlabs.us/uploads/";
window.baseapiURLPDF    = "https://socreatesv2.kitlabs.us/document_files/";
window.baseapiURLaudios = "https://socreatesv2.kitlabs.us/audios/";*/

window.baseURL    = "https://socreatesappaws.com";
window.baseURLApi = "https://socreatesappaws.com/api";
window.baseURLImage = "https://socreatesappaws.com/uploads/";
window.baseapiURLMedia  = "https://socreatesappaws.com/uploads/";
window.baseapiURLPDF    = "https://socreatesappaws.com/document_files/";
window.baseapiURLaudios = "https://socreatesappaws.com/audios/";

/*document.onkeydown = function(e) {
  if(e.keyCode === 123) {
     return false;
  }
  if(e.ctrlKey && e.shiftKey && e.keyCode === 'I'.charCodeAt(0)) {
     return false;
  }
  if(e.ctrlKey && e.shiftKey && e.keyCode === 'C'.charCodeAt(0)) {
     return false;
  }
  if(e.ctrlKey && e.shiftKey && e.keyCode === 'J'.charCodeAt(0)) {
     return false;
  }
  if(e.ctrlKey && e.keyCode === 'U'.charCodeAt(0)) {
     return false;
  }
}*/

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
