import React, { Component } from "react";
import FeelGoodLoading from "./../images/feel_good_loading.gif";
import TenPercent from "./../images/tenpercent.gif";
import TwentyPercent from "./../images/twentypercent.gif";
import ThirtyPercent from "./../images/thirtypercent.gif";
import FortyPercent from "./../images/fortypercent.gif";
import FiftyPercent from "./../images/fiftypercent.gif";
import SixtyPercent from "./../images/sixtypercent.gif";
import SeventyPercent from "./../images/seventypercent.gif";
import EightyPercent from "./../images/eightypercent.gif";
import NinetyPercent from "./../images/ninetypercent.gif";
import HundredPercent from "./../images/hundredpercent.gif";

export default class Loader extends Component {
  constructor(props) 
  {
    super(props);
    this.state = {
    };
  }

  render() {
    if(this.props.count === 1)
    {
      return (
        <>
          <div className="image_load" id="loading_image"role="status">
              <img src={TenPercent} className="gif-large" alt="TenPercent" />
          </div> 
          <div id="overlay">           
          </div>
        </>
      );
    }
    else if(this.props.count === 2)
    {
      return (
        <>
          <div className="image_load" id="loading_image"role="status">
              <img src={TwentyPercent} className="gif-large" alt="TwentyPercent" />
          </div> 
          <div id="overlay">           
          </div>
        </>
      );
    }
    else if(this.props.count === 3)
    {
      return (
        <>
          <div className="image_load" id="loading_image"role="status">
              <img src={ThirtyPercent} className="gif-large" alt="ThirtyPercent" />
          </div> 
          <div id="overlay">           
          </div>
        </>
      );
    }
    else if(this.props.count === 4)
    {
      return (
        <>
          <div className="image_load" id="loading_image"role="status">
              <img src={FortyPercent} className="gif-large" alt="FortyPercent" />
          </div> 
          <div id="overlay">           
          </div>
        </>
      );
    }
    else if(this.props.count === 5)
    {
      return (
        <>
          <div className="image_load" id="loading_image"role="status">
              <img src={FiftyPercent} className="gif-large" alt="FiftyPercent" />
          </div> 
          <div id="overlay">           
          </div>
        </>
      );
    }
    else if(this.props.count === 6)
    {
      return (
        <>
          <div className="image_load" id="loading_image"role="status">
              <img src={SixtyPercent} className="gif-large" alt="SixtyPercent" />
          </div> 
          <div id="overlay">           
          </div>
        </>
      );
    }
    else if(this.props.count === 7)
    {
      return (
        <>
          <div className="image_load" id="loading_image"role="status">
              <img src={SeventyPercent} className="gif-large" alt="SeventyPercent" />
          </div> 
          <div id="overlay">           
          </div>
        </>
      );
    }
    else if(this.props.count === 8)
    {
      return (
        <>
          <div className="image_load" id="loading_image"role="status">
              <img src={EightyPercent} className="gif-large" alt="EightyPercent" />
          </div> 
          <div id="overlay">           
          </div>
        </>
      );
    }
    else if(this.props.count === 9)
    {
      return (
        <>
          <div className="image_load" id="loading_image"role="status">
              <img src={NinetyPercent} className="gif-large" alt="NinetyPercent" />
          </div> 
          <div id="overlay">           
          </div>
        </>
      ); 
    }
    else if(this.props.count === 10)
    {
      return (
        <>
          <div className="image_load" id="loading_image"role="status">
              <img src={HundredPercent} className="gif-large" alt="HundredPercent" />
          </div> 
          <div id="overlay">           
          </div>
        </>
      );      
    }            
    else
    {
      return (
        <>
          <div className="image_load" id="loading_image"role="status">
              <img src={FeelGoodLoading} className="gif-large" alt="Loading" />
          </div> 
          <div id="overlay">           
          </div>
        </>
      );
    }
  }
}