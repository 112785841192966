import React, { Component } from "react";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { faClone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class CustomCouponsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copySuccess:false,
    };
  }  

  copySoulCode = e => {
    /*const el = this.coupon_name;
    console.log(el);
    el.select();
    document.execCommand("copy")
    this.setState({copySuccess: true});*/
    if(e.target.attributes)
    {
      var coupon_val  = e.target.attributes.getNamedItem("coupon_val").value;

      var input_dummy = document.createElement('input');
      document.body.appendChild(input_dummy);
      input_dummy.value = coupon_val;
      input_dummy.select();
      document.execCommand('copy');
      document.body.removeChild(input_dummy);
      this.setState({copySuccess: true});
    }
  }

  render() {  
    return(
      <>
        <div className="d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
        </div>

        <Modal 
        size="lg"
        show={this.props.isOpen} 
        onHide={this.props.closeModal}
        backdrop="static" 
        keyboard={false}
        animation={false}
        centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Available Soul Codes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.available_coupons.map((available_coupon, index) => (
                <div className="coupon_available" key={index}>
                    {available_coupon.discount_type === 'Percent' ? (
                      <>
                      <div className="row h-100 align-items-center">
                        <div className="col-sm-12 col-md-8">
                          <div className="coupon_left">
                            This Soul Code Offers {available_coupon.value}% off on Membership Amount. 
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-4 text-center">
                          <div className="coupon_right">
                            Code : {available_coupon.coupon_code} <Link to="#" onClick={this.copySoulCode} coupon_val={available_coupon.coupon_code}> <FontAwesomeIcon icon={faClone} /> Copy Soul Code</Link> 
                          </div>
                        </div>
                      </div>
                      </>
                    ): (
                      <>
                      <div className="row h-100 align-items-center">
                        <div className="col-sm-12 col-md-8">
                          <div className="coupon_left">
                            This Soul Code Offers ${available_coupon.value} off on Membership Amount.
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-4 text-center">
                          <div className="coupon_right">
                            Code : {available_coupon.coupon_code} <Link to="#" onClick={this.copySoulCode} coupon_val={available_coupon.coupon_code}> <FontAwesomeIcon icon={faClone} /> Copy Soul Code</Link> 
                          </div>
                        </div>
                      </div>
                      </>
                    )}                    
                </div>
                ))}

                {this.state.copySuccess ?
                  <div className="soul_copied">
                    Soul Code Copied!
                  </div> : null
                }

          </Modal.Body>
        </Modal>
      </>
    );
  }
}