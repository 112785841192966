import React, { Component } from "react";
import { Redirect,Link } from "react-router-dom";
import axios from "axios";
import Logo from "./../images/logo.png";
import DataNotFound from "./../images/data_not_found.gif";
import AuthLinks from "./AuthLinks";
import Loader from "./Loader";
import CommonFooter from "./CommonFooter";
import GeometryBundle1 from "./GeometryBundle1";

export default class PreviousSessions extends Component {
  userData;
  constructor(props) 
  {
    super(props);
    this.state = {
      isLoading:false,
      sessions_list:[],
      sessions_data_not_found:false,
      videoID:'',
      uservideo_id:'',
      redirect_to_detail:false
    };
  }
  
  componentDidMount() 
  {
      this.setState({ isLoading:true });
      const journey_id = localStorage.getItem('journey_id');

      axios.get('/get_user_journal_history', { params:{ journey_id:journey_id } })
      .then((response) => {
        if(response.status === 200) 
        {
          this.setState({ isLoading:false,sessions_list:response.data.data,sessions_data_not_found:false });
        }
      })
      .catch((error) => {
        if(error.response.status === 400)
        {
          this.setState({ isLoading:false,sessions_data_not_found:true });
        }
      });
  }

  session_details = e => {
    var uservideo_id = e.target.attributes.getNamedItem("uservideo_id").value;
    var videoID = e.target.attributes.getNamedItem("video_id").value;
    this.setState({ redirect_to_detail:true,uservideo_id:uservideo_id,videoID:videoID });
  };

  render() {
    const isLoading = this.state.isLoading;
    //const sessions_list = this.state.sessions_list;
    const sessions_data_not_found = this.state.sessions_data_not_found;

    var uservideo_id = this.state.uservideo_id;
    var videoID      = this.state.videoID;
    
    const login = localStorage.getItem("isLoggedIn");

    if(!login) 
    {
      return <Redirect to="/sign-in" />;
    }     

    const user = JSON.parse(localStorage.getItem("userData"));

    if(user && (user.email_verified_at === '' || user.email_verified_at === null))
    {
      return <Redirect to="/email-verification" push={true} />;
    }

    if(this.state.redirect_to_detail)
    {
      return <Redirect
                to={{
                pathname: "/journal-detail",
                state: { uservideo_id:uservideo_id, video_id:videoID }
              }}
            />
    }

    return (
      <>
        <div className="main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>

          {isLoading ? (
              <Loader />
            ) : (
              <span></span>
          )}

          <div className="mid-container previous-session-container">
            <div className="content mt-0">
                <div className="row align-self-center">
                    <div className="col-md-12">
                      <h4 className="text-center mb-4">All Previous Sessions</h4>
                      {sessions_data_not_found ? (
                        <figure className="mt-4"><img src={DataNotFound} alt="DataNotFound" className="gif-large" /></figure>
                      ) : (
                        <>
                        {this.state.sessions_list.reverse().map((session_item, index) => (
                            <div className="sessions_grid mb-4 mr-2" key={index}>
                                <Link to="#" onClick={this.session_details} className="text-center" video_id={session_item.videoID} uservideo_id={session_item.uservideo_id}>Day {index + 1} : {session_item.video_name}</Link>
                            </div>
                        ))}
                        </>
                      )}
                    </div>
                </div>
            </div>     
          </div>
          <GeometryBundle1 />
          <CommonFooter />
        </div>  
      </>
    );
  }
} 