import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { Button, Form } from "reactstrap";
import ISocreated from "./../images/i_socreated_33.gif";
import ThankYou from "./../images/thankyou.gif";
import Logo from "./../images/logo.png";
import AuthLinks from "./AuthLinks";
import Loader from "./Loader";
import VimeoCons from "./VimeoCons";
import CommonFooter from "./CommonFooter";
import GeometryBundle2 from "./GeometryBundle2";
import GeometryBundle3 from "./GeometryBundle3";
import GeometryBundle1 from "./GeometryBundle1";

export default class JourneyComplete extends Component {
  userData;
  constructor(props) 
  {
    super(props);
    this.state = { isLoading:false,show_video:false,show_journey_end_desc:false,show_assessment_responses:false,show_end_of_journey_assessment_question_1:false,show_end_of_journey_assessment_question_2:false,show_end_of_journey_assessment_question_3:false,errors: {}, errMsg: "",data: {},final_screen_end_of_journey:false,redirect_to_home:false };
  }

  componentDidMount() 
  {
    this.setState({ isLoading: true });
    /***********************************************/
    axios
    .get("/get_last_attempted_journey", {
    })
    .then((response) => {
      this.setState({ isLoading: false });
      if(response.status === 200)
      {
        if(response.data.success === 'Journey Finished')
        {
          this.setState({ final_screen_end_of_journey:true });
        }
      }
    })
    .catch((error) => {
      this.setState({ isLoading: false });
    });
  }

  onChangehandler = (e) => {
    let name  = e.target.name;
    let value = e.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };

  validateFirstQuestion() 
  {
    let assessment_answer_1 = this.state.assessment_answer_1;
    let assessment_answer_2 = this.state.assessment_answer_2;
    let assessment_answer_3 = this.state.assessment_answer_3;
    let errors = {};
    let formIsValid = true;

    if (!assessment_answer_1) 
    {
      formIsValid = false;
      errors["assessment_answer_1"] = "* Please enter Answer 1.";
    }

    if (!assessment_answer_2) 
    {
      formIsValid = false;
      errors["assessment_answer_2"] = "* Please enter Answer 2.";
    }

    if (!assessment_answer_3) 
    {
      formIsValid = false;
      errors["assessment_answer_3"] = "* Please enter Answer 3.";
    }    

    this.setState({
      errors: errors,
      isLoading: false,
    });
    return formIsValid;
  }

  validateQuestion2() 
  {
    let assessment_answer_4 = this.state.assessment_answer_4;
    let errors = {};
    let formIsValid = true;

    if (!assessment_answer_4) 
    {
      formIsValid = false;
      errors["assessment_answer_4"] = "* Please enter Answer 1.";
    }   

    this.setState({
      errors: errors,
      isLoading: false,
    });
    return formIsValid;
  }

  validateQuestion3() 
  {
    let assessment_answer_5 = this.state.assessment_answer_5;
    let errors = {};
    let formIsValid = true;

    if (!assessment_answer_5) 
    {
      formIsValid = false;
      errors["assessment_answer_5"] = "* Please enter Answer 1.";
    }   

    this.setState({
      errors: errors,
      isLoading: false,
    });
    return formIsValid;
  }  

  clickBtn = e => {
    this.setState({ isLoading: true });

    /******************** End of Journey Video Get API ***************************/
    if(e.target.value === 'end_of_journey')
    {
        axios
        .get("/get_end_of_journey_video", { 
            params: {}
        })
        .then((response) => {
            this.setState({ isLoading: false });
            if(response.status === 200)
            {
                this.setState({ video_url: response.data.data.url, show_video:true });
            }
        })
        .catch((error) => {
            this.setState({ isLoading: false });
        }); 
    }

    /******************** Show Journey End Para ***************************/
    if(e.target.value === 'end_of_journey_desc')
    {
        this.setState({ isLoading: false,show_journey_end_desc:true });
    }

    /****************** Show End of Journey Responses *********************/
    if(e.target.value === 'assessment_answers_overview')
    {
        axios.get('/get_assessment_answers_for_users', {})
        .then((response) => {
          this.setState({ isLoading:false });
          if(response.status === 200) 
          {
            this.setState({ assessment_responses:response.data.data,show_assessment_responses:true });
          }
        })
        .catch((error) => {
          if(error.response.status === 400)
          {
            this.setState({ isLoading:false,assessment_data_not_found:true });
          }
        });
    }
    /****************** Show End of Journey Responses *********************/
    if(e.target.value === 'end_of_journey_assessment_question_1')
    {
        setTimeout(() => {
          this.setState({ isLoading: false,show_end_of_journey_assessment_question_1:true });
        }, 1000);
    }

    /****************** Save End of Journey Response 1 *********************/
    if(e.target.value === 'save_end_of_journey_assessment_1')
    {
        const journey_id = localStorage.getItem("journey_id");

        if(this.validateFirstQuestion()) 
        {
            this.setState({ isLoading: true });
            document.getElementById("submit_end_of_journey_assessment_1").reset();
            axios
            .post('/add_user_review', {
              review_question: 'You have come a long way! You now have the awareness and the power within to transcend the things that were holding you back. How do you plan to keep this momentum going and continue to act from your heart-center and be your highest self? Can you make three commitments to yourself in the form of a personal motto, a habit, or a behavior change',
              review_answer_1: this.state.assessment_answer_1,
              review_answer_2: this.state.assessment_answer_2,
              review_answer_3: this.state.assessment_answer_3,
              question_id: '901',
              journey_id: journey_id
            })
            .then((response) => {
              if(response.status === 200) 
              {
                  this.setState({
                    assessment_answer_1: '',
                    assessment_answer_2: '',
                    assessment_answer_3: '',
                    show_end_of_journey_assessment_question_2:true
                  });    
              } 

              if(response.status === "failed" && response.success === undefined) 
              {
                this.setState({
                  errMsgAssessmentAnswer1: response.data.validation_error.assessment_answer_1,
                  errMsgAssessmentAnswer2: response.data.validation_error.assessment_answer_2,
                  errMsgAssessmentAnswer3: response.data.validation_error.assessment_answer_3,
                });
                setTimeout(() => {
                  this.setState({ errMsgAssessmentAnswer1: "", errMsgAssessmentAnswer2: "", errMsgAssessmentAnswer3 : "" });
                }, 2000);
              } 
              else if(response.status === "failed" && response.success === false) 
              {
                this.setState({
                  errMsg: response.data.message,
                });
                setTimeout(() => {
                  this.setState({ errMsg: "" });
                }, 2000);
              }
              this.setState({ isLoading: false });
            })
            .catch((error) => {
              this.setState({ isLoading: false });
              if(error.response && error.response.status === 400 && error.response.data.error === 'Review for this Question for this User already Exists') 
              {
                  document.getElementById("submit_end_of_journey_assessment_1").reset();
                  this.setState({
                    assessment_answer_1: '',
                    assessment_answer_2: '',
                    assessment_answer_3: '',
                    show_end_of_journey_assessment_question_2:true
                  });      
              }
            });   
        }
    }

    /****************** Save End of Journey Response 2 *********************/
    if(e.target.value === 'save_end_of_journey_assessment_2')
    {
        const journey_id = localStorage.getItem("journey_id");
        if(this.validateQuestion2()) 
        {
            this.setState({ isLoading: true });
            document.getElementById("submit_end_of_journey_assessment_2").reset();
            axios
            .post('/add_user_review', {
              review_question: 'What would you say to a friend or family member who is deliberating and doubting whether they should start their own Socreates Journey Within? Feel free to share how it has helped you, what were your doubts or hesitations as you began your journey, and how you feel now that you have completed it.',
              review_answer_1: this.state.assessment_answer_4,
              review_answer_2: '',
              review_answer_3: '',
              question_id: '902',
              journey_id: journey_id
            })
            .then((response) => {
              this.setState({ isLoading: false });
              if(response.status === 200) 
              {
                  this.setState({
                    assessment_answer_4: '',
                    show_end_of_journey_assessment_question_3:true
                  });    
              }
    
              if(response.status === "failed" && response.success === undefined) 
              {
                this.setState({
                  errMsgAssessmentAnswer4: response.data.validation_error.assessment_answer_4,
                });
                setTimeout(() => {
                  this.setState({ errMsgAssessmentAnswer4: "" });
                }, 2000);
              } 
              else if(response.status === "failed" && response.success === false) 
              {
                this.setState({
                  errMsg: response.data.message,
                });
                setTimeout(() => {
                  this.setState({ errMsg: "" });
                }, 2000);
              }
            })
            .catch((error) => {
              this.setState({ isLoading: false });
              if(error.response && error.response.status === 400 && error.response.data.error === 'Review for this Question for this User already Exists') 
              {
                  document.getElementById("submit_end_of_journey_assessment_2").reset();
                  this.setState({
                    assessment_answer_4: '',
                    show_end_of_journey_assessment_question_3:true
                  });      
              }
            });          
        }
    }    

    /****************** Save End of Journey Response 3 *********************/
    if(e.target.value === 'save_end_of_journey_assessment_3')
    {
        const journey_id = localStorage.getItem("journey_id");
        if(this.validateQuestion3()) 
        {
            this.setState({ isLoading: true });
            document.getElementById("submit_end_of_journey_assessment_3").reset();
            axios
            .post('/add_user_review', {
              review_question: 'Imagine you have faced a few setbacks, are feeling a wave of negative emotions and you know you are not approaching your interpersonal interactions in heart-centered way. What guidance can you offer your self with the wisdom you have now, having just completed your Journey Within',
              review_answer_1: this.state.assessment_answer_5,
              review_answer_2: '',
              review_answer_3: '',
              question_id: '903',
              journey_id: journey_id
            })
            .then((response) => {
              this.setState({ isLoading: false });
              if(response.status === 200) 
              {
                  this.setState({
                    assessment_answer_5: '',
                    final_screen_end_of_journey:true
                  });    
              }
    
              if(response.status === "failed" && response.success === undefined) 
              {
                this.setState({
                  errMsgAssessmentAnswer5: response.data.validation_error.assessment_answer_5,
                });
                setTimeout(() => {
                  this.setState({ errMsgAssessmentAnswer5: "" });
                }, 2000);
              } 
              else if(response.status === "failed" && response.success === false) 
              {
                this.setState({
                  errMsg: response.data.message,
                });
                setTimeout(() => {
                  this.setState({ errMsg: "" });
                }, 2000);
              }
            })
            .catch((error) => {
              this.setState({ isLoading: false });
              if(error.response && error.response.status === 400 && error.response.data.error === 'Review for this Question for this User already Exists') 
              {
                  document.getElementById("submit_end_of_journey_assessment_3").reset();
                  this.setState({
                    assessment_answer_5: '',
                    final_screen_end_of_journey:true
                  });      
              }
            });          
        }
    } 

    if(e.target.value === 'finished')
    {
        this.setState({ isLoading: true });
        const journey_id = localStorage.getItem("journey_id");
        axios
        .post('/save_user_journey_status', {
          journeyId: journey_id,
          journey_status: 'Completed'
        })
        .then((response) => {
          this.setState({ isLoading: false });
          if(response.status === 200) 
          {
            this.setState({ redirect_to_home:true });   
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          if(error.response && error.response.status === 400)
          {
            this.setState({ redirect_to_home:true });
          }
        }); 
    }
    
    /************************* End **************************/
  };

  render() {
    const isLoading = this.state.isLoading;

    if(this.state.redirect_to_home)
    {
      return <Redirect to="/home" push={true} />;
    }

    /******************** Show Final Thank You Screen (Last Step) ***************************/
    if(this.state.final_screen_end_of_journey)
    {
        return (
            <>
              <div className="main-wrapper">
                <header>
                  <figure className="waves-top"></figure>
                  <figure className="logo">
                    <img src={Logo} alt="logo" />
                  </figure>
                  <AuthLinks />
                </header>

                {isLoading ? (
                <Loader />
                    ) : (
                    <span></span>
                )}  

                <div className="mid-container question-container">
                  <div className="content mt-0">
                      <div className="row align-self-center">
                          <div className="col-md-6 col-12">
                              <figure><img src={ThankYou} alt="Thank You" className="left-img img-shadow" /></figure>
                          </div>
                          <div className="col-md-6 col-12 align-self-center">
                              <div className="px-md-5 px-0 mt-sm-0 mt-4">
                                <h5>Congrats again on Completing Level I:</h5>
                                <h5 className="mb-3">The Journey Within!</h5>
                                <p className="mb-0">Stay Tuned....</p>
                                <p>Level II of Socreates is already in Production</p>
                              </div>
                              <button value={'finished'} className="btn btn-primary" onClick={this.clickBtn}>Done</button>
                          </div>
                      </div>
                  </div>     
                </div>
                <GeometryBundle2 />
                <CommonFooter />
              </div>  
            </>
        );
    }    

    /******************** Show End of Journey Assessment Question 3 (7th Step) ***************************/
    if(this.state.show_end_of_journey_assessment_question_3)
    {
        return (
            <>
              <div className="main-wrapper">
                <header>
                  <figure className="waves-top"></figure>
                  <figure className="logo">
                    <img src={Logo} alt="logo" />
                  </figure>
                  <AuthLinks />
                </header>

                {isLoading ? (
                    <Loader />
                  ) : (
                    <span></span>
                )}          

                <div className="mid-container journal-container">
                  <div className="content mt-6">
                    <div className="row align-self-center end_of_journey_responses">
                      <div className="col-md-12 col-sm-12 col-12 mx-auto">
                        <div className="text-center">
                          <p className="text-center">Imagine you have faced a few setbacks, are feeling a wave of negative emotions and you know you are not approaching your interpersonal interactions in heart-centered way. What guidance can you offer your self with the wisdom you have now, having just completed your Journey Within:</p>
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-12 col-12 mx-auto">
                        <div className="text-center">
                            <Form className="mt-4" id="submit_end_of_journey_assessment_3">
                                <div className="form-group text-left">
                                    <textarea className="form-control" name="assessment_answer_5" rows={3} placeholder="Enter Here" onBlur={this.onChangehandler} maxLength="240" />
                                    <span className="text-danger">{this.state.errMsgAssessmentAnswer5}</span>
                                    <span className="text-danger">{this.state.errors.assessment_answer_5}</span>
                                </div>
                            
                                <div className="mt-3 bottom-btns">
                                    <Button
                                        className="btn btn-primary"
                                        onClick={this.clickBtn}
                                        value={'save_end_of_journey_assessment_3'}
                                    >NEXT</Button>
                                </div>
                            </Form>  
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <GeometryBundle3 />
                <CommonFooter />
              </div>
            </>
        );
    }      

    /******************** Show End of Journey Assessment Question 2 (6th Step) ***************************/
    if(this.state.show_end_of_journey_assessment_question_2)
    {
        return (
            <>
              <div className="main-wrapper">
                <header>
                  <figure className="waves-top"></figure>
                  <figure className="logo">
                    <img src={Logo} alt="logo" />
                  </figure>
                  <AuthLinks />
                </header>

                {isLoading ? (
                    <Loader />
                  ) : (
                    <span></span>
                )}          

                <div className="mid-container journal-container">
                  <div className="content mt-6">
                    <div className="row align-self-center end_of_journey_responses">
                      <div className="col-md-12 col-sm-12 col-12 mx-auto">
                        <div className="text-center">
                          <p className="text-center">What would you say to a friend or family member who is deliberating and doubting whether they should start their own Socreates Journey Within? Feel free to share how it has helped you, what were your doubts or hesitations as you began your journey, and how you feel now that you have completed it.</p>
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-12 col-12 mx-auto">
                        <div className="text-center">
                            <Form className="mt-4" id="submit_end_of_journey_assessment_2">
                                <div className="form-group text-left">
                                    <textarea className="form-control" name="assessment_answer_4" rows={3} placeholder="Enter Here" onBlur={this.onChangehandler} maxLength="240" />
                                    <span className="text-danger">{this.state.errMsgAssessmentAnswer4}</span>
                                    <span className="text-danger">{this.state.errors.assessment_answer_4}</span>
                                </div>
                            
                                <div className="mt-3 bottom-btns">
                                    <Button
                                        className="btn btn-primary"
                                        onClick={this.clickBtn}
                                        value={'save_end_of_journey_assessment_2'}
                                    >NEXT</Button>
                                </div>
                            </Form>  
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <GeometryBundle1 />
                <CommonFooter />
              </div>
            </>
        );
    }      

    /******************** Show End of Journey Assessment Question 1 (5th Step) ***************************/
    if(this.state.show_end_of_journey_assessment_question_1)
    {
        return (
            <>
              <div className="main-wrapper">
                <header>
                  <figure className="waves-top"></figure>
                  <figure className="logo">
                    <img src={Logo} alt="logo" />
                  </figure>
                  <AuthLinks />
                </header>

                {isLoading ? (
                    <Loader />
                  ) : (
                    <span></span>
                )}          

                <div className="mid-container journal-container">
                  <div className="content mt-6">
                    <div className="row align-self-center end_of_journey_responses">
                      <div className="col-md-12 col-sm-12 col-12 mx-auto">
                        <div className="text-center">
                          <p className="text-center">You have come a long way! You now have the awareness and the power within to transcend the things that were holding you back. How do you plan to keep this momentum going and continue to act from your heart-center and be your highest self?</p>
                          <p className="text-center">Can you make three commitments to yourself in the form of a personal motto, a habit, or a behavior change</p>
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-12 col-12 mx-auto">
                        <div className="text-center">
                          <Form className="mt-4" id="submit_end_of_journey_assessment_1">
                              <div className="form-group text-left">
                                  <textarea className="form-control" name="assessment_answer_1" rows={3} placeholder="Enter Here" onBlur={this.onChangehandler} maxLength="240" />
                                  <span className="text-danger">{this.state.errMsgAssessmentAnswer1}</span>
                                  <span className="text-danger">{this.state.errors.assessment_answer_1}</span>
                              </div>
                              <div className="form-group text-left">
                                  <textarea className="form-control" name="assessment_answer_2" rows={3} placeholder="Enter Here" onBlur={this.onChangehandler} maxLength="240" />
                                  <span className="text-danger">{this.state.errMsgAssessmentAnswer2}</span>
                                  <span className="text-danger">{this.state.errors.assessment_answer_2}</span>
                              </div>
                              <div className="form-group text-left">
                                  <textarea className="form-control" name="assessment_answer_3" rows={3} placeholder="Enter Here" onBlur={this.onChangehandler} maxLength="240" />
                                  <span className="text-danger">{this.state.errMsgAssessmentAnswer3}</span>
                                  <span className="text-danger">{this.state.errors.assessment_answer_3}</span>
                              </div>
                          
                              <div className="mt-3 bottom-btns">
                              <Button
                                  className="btn btn-primary"
                                  onClick={this.clickBtn}
                                  value={'save_end_of_journey_assessment_1'}
                              >NEXT</Button>
                              </div>
                          </Form> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <GeometryBundle2 />
                <CommonFooter />
              </div>
            </>
        );
    }  

    /******************** Show Assessment Answers (4th Step) ***************************/
    if(this.state.show_assessment_responses)
    {
        return (
            <>
              <div className="main-wrapper another-main-wrapper">
                <header>
                  <figure className="waves-top"></figure>
                  <figure className="logo">
                    <img src={Logo} alt="logo" />
                  </figure>
                  <AuthLinks />
                </header>

                {isLoading ? (
                  <Loader />
                  ) : (
                    <span></span>
                )}

                <div className="mid-container journal-screen-container journal-container">
                  <div className="content mt-6">
                    <div className="row">
                      <div className="col-sm-12">
                        <h5 className="text-center tab-title mt-4">Do remember when you started on the journey? We do!</h5>
                        <div className="journal-right-content">
                          <div className="row">
                            {this.state.assessment_responses.map((assessment_response, index) => (
                                  <div className="col-md-4" key={index}>
                                    <div className="assesment-content-wrap">
                                      <h6>Q.{index + 1} {assessment_response.name}</h6>
                                      <p ><b>Ans.1</b> {assessment_response.subjective_answer_1}</p>
                                      <p ><b>Ans.2</b> {assessment_response.subjective_answer_2}</p>
                                      <p ><b>Ans.3</b> {assessment_response.subjective_answer_3}</p>
                                    </div>
                                  </div>
                            ))}
                            </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-md-12">
                            <button value={'end_of_journey_assessment_question_1'} className="btn btn-primary" onClick={this.clickBtn}>Next</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <GeometryBundle3 />
                <CommonFooter />
              </div>
            </>
        );
    }    

    /******************** Show Journey End Para (3rd Step) ***************************/
    if(this.state.show_journey_end_desc)
    {
        return (
            <>
              <div className="main-wrapper">
                <header>
                  <figure className="waves-top"></figure>
                  <figure className="logo">
                    <img src={Logo} alt="logo" />
                  </figure>
                  <AuthLinks />
                </header>

                {isLoading ? (
                  <Loader />
                  ) : (
                    <span></span>
                )}          

                <div className="mid-container journal-container">
                  <div className="content mt-0">
                      <div className="row align-self-center">
                          <div className="col-md-12 col-12">
                            <div className="journey_end_text">
                              <p className="text-center">You did it! You completed your Journey Within to peel back the layers of self-limiting beliefs and awaken your power. You are making changes key to being your highest self.</p>
                              <p className="text-center">Remember that the ultimate journey in life does not require an app; it is the journey you are on in each and every moment, day after day.</p>
                              <p className="text-center">Every interpersonal interaction and each relationship expands your perspective on yourself and offers you an opportunity to transcend negative emotions and raise your vibration.</p>
                              <p className="text-center">The more you become heart-centered and the less you act from your ego, the more fulfilling your life will become, the healthier your relationships will be and the more aligned you will be with your higher self.</p>
                              <button value={'assessment_answers_overview'} className="btn btn-primary" onClick={this.clickBtn}>Next</button>
                            </div>
                          </div>
                      </div>
                  </div>     
                </div>
                <GeometryBundle1 />
                <CommonFooter />
              </div> 
            </>
        );
    }

    /******************** Show Journey End Video (2nd Step) ***************************/
    if(this.state.show_video)
    {
        return (
            <>
              <div className="main-wrapper">
                <header>
                  <figure className="waves-top"></figure>
                  <figure className="logo">
                    <img src={Logo} alt="logo" />
                  </figure>
                  <AuthLinks />
                </header>

                {isLoading ? (
                  <Loader />
                  ) : (
                    <span></span>
                )}          

                <div className="mid-container centered_video">
                  <div className="content mt-0">
                      <div className="row align-self-center">
                          <div className="col-md-7 col-sm-12 mx-auto">
                              <h5 className="mt-4 mb-4">Journey Completed</h5>
                              <figure><VimeoCons get_thumbnail="TRUE" get_video_with_subtitles="TRUE" video_url={this.state.video_url} /></figure>
                              <button value={'end_of_journey_desc'} className="btn btn-primary" onClick={this.clickBtn}>Next</button>
                          </div>
                      </div>
                  </div>     
                </div>
                <GeometryBundle3 />
                <CommonFooter />
              </div> 
            </>
        );
    }

    /******************** Show Congratulations Gif (1st Step) ***************************/
    return (
      <>
        <div className="main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>

          {isLoading ? (
            <Loader />
            ) : (
              <span></span>
          )}          

          <div className="mid-container">
            <div className="content mt-0">
                <div className="row align-self-center">
                    <div className="col-md-12 col-12">
                        <figure><img src={ISocreated} alt="I Socreated 33" className="center-img img-shadow mb-4" /></figure>
                        <button value={'end_of_journey'} className="btn btn-primary" onClick={this.clickBtn}>Congrats</button>
                    </div>
                </div>
            </div>     
          </div>
          <GeometryBundle2 />
          <CommonFooter />
        </div> 
      </>
    );
  }
}