import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Meditation from "./../images/meditation.gif";
import Logo from "./../images/logo.png";
import AuthLinks from "./AuthLinks";
import CommonFooter from "./CommonFooter";
import GeometryBundle2 from "./GeometryBundle2";

export default class GoToFlowMediator extends Component {
  constructor(props) 
  {
    super(props);
    this.state = { redirect:false };
  }

  clickBtn = e => {
    this.setState({
      redirect:true
    });
  };

  render() {
    var video_info = this.props.video_info;
    var media_id   = this.props.media_id;
    //var video_name = this.props.video_name;
    var received_video_url  = this.props.received_video_url;

    if(this.state.redirect)
    {
      return <Redirect
          to={{
          pathname: "/library",
          state: { video_info:video_info, media_id:media_id, video_name:'Your Recommended Flow', received_video_url:received_video_url,video_type:'recommended_flow' }
        }}
      />
    }

    return (
      <>
        <div className="main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>

          <div className="mid-container">
            <div className="content">
                <div className="row align-self-center">
                    <div className="col-md-6 col-12">
                        <figure><img src={Meditation} alt="Meditation" className="left-img img-shadow" /></figure>
                    </div>
                    <div className="col-md-6 col-12 align-self-center">
                        <div className="px-md-5 px-0 mt-sm-0 mt-4">
                          <p>Each Flow uses sound healing with 18 different frequencies. It may feel different at first, then it will becoming soothing. The recommended Flow will help you integrate your learnings.
                          </p>
                        </div>
                        <button value={"session_end"} className="btn btn-primary" onClick={this.clickBtn}>OK</button>
                    </div>
                </div>
            </div>     
          </div>
          <GeometryBundle2 />
          <CommonFooter />
        </div>  
      </>
    );
  }
}