import React, { Component } from "react";
import Modal from 'react-bootstrap/Modal';

export default class CustomModal extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.user = JSON.parse(localStorage.getItem("userData"));
  }  

  render() {  
    return(
      <>
        <div className="d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
        </div>

        <Modal 
        show={this.props.isOpen} 
        onHide={this.props.closeModal}
        backdrop="static" 
        keyboard={false}
        centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.props.media_name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <p>{this.props.description}</p>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}