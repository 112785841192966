import React, { Component } from "react";
import { Redirect,Link } from "react-router-dom";
import { createBrowserHistory } from 'history';
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from "./../images/logo.png";
import AudioIcon from "./../images/audio_icon.svg";
import PdfIcon from "./../images/pdf_icon.svg";
import axios from "axios";
import Moment from 'moment';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import VimeoCons from "./VimeoCons";
import AuthLinks from "./AuthLinks";
import Loader from "./Loader";
import CommonFooter from "./CommonFooter";
import CustomModal from './CustomModal';
import CustomPDFModal from './CustomPDFModal';
import CustomVideoModal from './CustomVideoModal';
import CustomAudioModal from './CustomAudioModal';
import GeometryBundle2 from "./GeometryBundle2";

export default class Library extends Component {
  constructor(props) 
  {
    super(props);
    this.state = { insight_videos: [], flow_videos: [], tune_up_videos: [], bonus_videos : [], tutorial_video : [], tune_up_videos_count:0, flow_videos_count: 0, insight_videos_count: 0, bonus_videos_count : 0, isLoading:false, isOpen: false,modal_description:'',modal_name:'',isPDFOpen:false,pdf_url:'',isVideoOpen:false,isAudioOpen:false,audio_url:'',isLibraryVideoOpen:false };
  }

  componentDidMount() 
  {
      if(this.props && this.props.location.state && this.props.location.state.received_video_url && this.props.location.state.received_video_url !== '')
      {
        this.setState({ isVideoOpen:true });
      }
      const journey_id = localStorage.getItem('journey_id');
      const plan_id    = localStorage.getItem('plan_id');

      this.setState({ isLoading:true });

      /***************************************************** */
      axios.get('/tutorial_video', { params:{journey_id:30} })
      .then((response) => {
        if(response.status === 200) 
        {       
          this.setState({
              tutorial_video : response.data.data,
          });
        }
      })
      .catch((error) => {

      });

      /****************************************************** */
      axios.get('/get_flow_videos', { params:{plan_id:plan_id} })
      .then((response) => {
        if(response.status === 200) 
        {       
          var free_flows = response.data.free_flows_data;
          var premium_flows = response.data.premium_flows_data;
          var all_flows = free_flows.concat(premium_flows);

          this.setState({
              flow_videos : all_flows,
              flow_videos_count : response.data.free_flows_data.length + response.data.premium_flows_data.length,
          });
        }
      })
      .catch((error) => {

      });  

      /***************************************************** */
      axios.get('/get_33_seconds_videos_for_user', {})
      .then((response) => {
        if(response.status === 200) 
        {       
          var free_tune_up_videos = response.data.free_membership_bonus_videos;
          var premium_tune_up_videos = response.data.premium_membership_bonus_videos;
          var all_tune_up_videos = free_tune_up_videos.concat(premium_tune_up_videos);

          this.setState({
              tune_up_videos : all_tune_up_videos,
              tune_up_videos_count : all_tune_up_videos.length
          });
        }
      })
      .catch((error) => {

      });

      /***************************************************** */
      axios.get('/get_bonus_videos', { params:{plan_id:plan_id} })
      .then((response) => {
        if(response.status === 200) 
        {       
          this.setState({
              bonus_videos : response.data.data,
              bonus_videos_count : response.data.data.length + 1,
          });
        }
      })
      .catch((error) => {

      });     
      
      /***************************************************** */

      axios.get('/get_explainer_videos_for_user', { params:{journey_id:journey_id} })
      .then((response) => {
        if(response.status === 200) 
        {       
          this.setState({
              insight_videos : response.data.data,
              insight_videos_count : response.data.data.length,
              isLoading:false
          });
        }
      })
      .catch((error) => {

      });

      setTimeout(() => {this.setState({isLoading: false})}, 5000);
  }

  openModal = e => {
    if(e.target.attributes)
    {
      if(e.target.attributes.getNamedItem("media_name"))
      {
        var modal_name = e.target.attributes.getNamedItem("media_name").value;
      }
      else
      {
        modal_name = '';
      }

      if(e.target.attributes.getNamedItem("description"))
      {
        var modal_description = e.target.attributes.getNamedItem("description").value;
      }
      else
      {
        modal_description = '';
      }
    }
    else
    {
      modal_name = '';
      modal_description = '';
    }

    this.setState({
      isOpen: true,
      modal_description: modal_description,
      modal_name: modal_name,
    });
  };

  closeModal = e => {
    this.setState({
      isOpen: false,
      modal_description:'',
      modal_name:'',
    });
  };

  openPDFModal = e => {
    if(e.target.attributes)
    {
      if(e.target.attributes.getNamedItem("media_name"))
      {
        var modal_name = e.target.attributes.getNamedItem("media_name").value;
      }
      else
      {
        modal_name = '';
      }

      if(e.target.attributes.getNamedItem("pdf_url"))
      {
        var pdf_url = e.target.attributes.getNamedItem("pdf_url").value;
      }
      else
      {
        pdf_url = '';
      }
    }
    else
    {
      modal_name = '';
      pdf_url = '';
    }

    if(modal_name !== '' && pdf_url !== '')
    {
      this.setState({
        isPDFOpen: true,
        pdf_url: pdf_url,
        modal_name: modal_name,
      });
    }
  };

  openAudioModal = e => {
    if(e.target.attributes)
    {
      if(e.target.attributes.getNamedItem("media_name"))
      {
        var modal_name = e.target.attributes.getNamedItem("media_name").value;
      }
      else
      {
        modal_name = '';
      }

      if(e.target.attributes.getNamedItem("audio_file"))
      {
        var audio_url  = e.target.attributes.getNamedItem("audio_file").value;
      }
      else
      {
        audio_url = '';
      }
    }
    else
    {
      modal_name = '';
      audio_url = '';
    }

    if(modal_name !== '' && audio_url !== '')
    {
      this.setState({
        isAudioOpen: true,
        audio_url: audio_url,
        modal_name: modal_name,
      });
    }
  };

  openVideoModal = e => {
    if(e.target.attributes)
    {
      if(e.target.attributes.getNamedItem("media_name"))
      {
        var video_name  = e.target.attributes.getNamedItem("media_name").value;
      }
      else
      {
        video_name = '';
      }

      if(e.target.attributes.getNamedItem("video_url"))
      {
        var video_info  = e.target.attributes.getNamedItem("video_url").value;
      }
      else
      {
        video_info = '';
      }

      if(e.target.attributes.getNamedItem("video_type"))
      {
        var video_type  = e.target.attributes.getNamedItem("video_type").value;
      }
      else
      {
        video_type = '';
      }      

      if(e.target.attributes.getNamedItem("video_id"))
      {
        var video_id  = e.target.attributes.getNamedItem("video_id").value;
      }
      else
      {
        video_id  = '';
      }
      
      if(e.target.attributes.getNamedItem("uservideo_id"))
      {
        var uservideo_id  = e.target.attributes.getNamedItem("uservideo_id").value;
      }
      else
      {
        uservideo_id  = '';
      }
    }
    else
    {
      video_name = '';
      video_info = '';
      video_type = '';
      video_id = '';
      uservideo_id = '';
    }

    this.setState({
      isLibraryVideoOpen: true,
      library_video_name: video_name,
      library_video_url: video_info,
      library_video_type: video_type,
      library_video_id: video_id,
      library_uservideo_id: uservideo_id,
    });
  };

  closeAudioModal = e => {
    this.setState({
      isAudioOpen: false,
      audio_url:'',
      modal_name:'',
    });
  };

  closePDFModal = e => {
    this.setState({
      isPDFOpen: false,
      pdf_url:'',
      modal_name:'',
    });
  };

  closeVideoModal = e => {
    if(this.props && this.props.location.state && this.props.location.state.video_type && this.props.location.state.video_type === 'insight')
    {
      var videoID = this.props.location.state.video_id;
      var uservideo_id = this.props.location.state.uservideo_id;
  
      var last_viewed = Moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
  
      axios.post('/update_user_videos', { videoID:videoID,uservideo_id:uservideo_id,last_viewed:last_viewed })
        .then((response) => {
          if(response.status === 200) 
          {       
            this.setState({
              isVideoOpen: false,
            });
            const history = createBrowserHistory();
            if (history.location.state && history.location.state.received_video_url) {
                let state = { ...history.location.state };
                delete state.received_video_url;
                history.replace({ ...history.location, state });
            }
            window.location.reload();
          }
        })
        .catch((error) => {
  
        });
    }
    else
    {
      this.setState({
        isVideoOpen: false,
      });
      const history = createBrowserHistory();
      if (history.location.state && history.location.state.received_video_url) {
          let state = { ...history.location.state };
          delete state.received_video_url;
          history.replace({ ...history.location, state });
      }
      //window.location.reload();
    }
  };

  closeLibraryVideoModal = e => {
    if(this.state.library_video_type === 'insight')
    {
      var videoID = this.state.library_video_id;
      var uservideo_id = this.state.library_uservideo_id;
  
      var last_viewed = Moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
  
      axios.post('/update_user_videos', { videoID:videoID,uservideo_id:uservideo_id,last_viewed:last_viewed })
        .then((response) => {
          if(response.status === 200) 
          {       
            this.setState({
              isLibraryVideoOpen: false,
              library_video_name : '',
              library_video_info : '',
              library_video_type : '',
              library_video_id : '', 
              library_uservideo_id : '',
            });
            window.location.reload();
          }
        })
        .catch((error) => {
  
        }); 
    }
    else
    {
      this.setState({
        isLibraryVideoOpen: false,
        library_video_name : '',
        library_video_info : '',
        library_video_type : '',
        library_video_id : '', 
        library_uservideo_id : '',
      });
    }
  };  

  clickBtn = e => {
    this.setState({
      renderView: +e.target.value
    });
  };

  render() {
    const isLoading = this.state.isLoading;
    const login = localStorage.getItem("isLoggedIn");
    const plan_id = localStorage.getItem("plan_id");

    var baseapiURLPDF = localStorage.getItem("baseapiURLPDF");
    var baseapiURLaudios = localStorage.getItem("baseapiURLaudios");

    if(!login) 
    {
      return <Redirect to="/sign-in" />;
    }     

    const user = JSON.parse(localStorage.getItem("userData"));
    if(user && (user.email_verified_at === '' || user.email_verified_at === null))
    {
      return <Redirect to="/email-verification" push={true} />;
    }

    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 6,
        //partialVisibilityGutter: 50
      },
      desktop: {
        breakpoint: { max: 3000, min: 1351 },
        items: 6,
        //partialVisibilityGutter: 50
      },
      desktop2: {
        breakpoint: { max: 1350, min: 1024 },
        items: 5,
        //partialVisibilityGutter: 50
      },
      tablet: {
        breakpoint: { max: 1024, min: 848 },
        items: 4,
        //partialVisibilityGutter: 30
      },
      tablet1: {
        breakpoint: { max: 847, min: 481 },
        items: 2,
        //partialVisibilityGutter: 30
      },
      mobile: {
        breakpoint: { max: 480, min: 0 },
        items: 1,
        //partialVisibilityGutter: 30
      }
    }; 

    const flows_responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
        //partialVisibilityGutter: 50
      },
      desktop: {
        breakpoint: { max: 3000, min: 1351 },
        items: 5,
        //partialVisibilityGutter: 50
      },
      desktop2: {
        breakpoint: { max: 1350, min: 1024 },
        items: 4,
        //partialVisibilityGutter: 50
      },
      tablet: {
        breakpoint: { max: 1024, min: 848 },
        items: 3,
        //partialVisibilityGutter: 30
      },
      tablet1: {
        breakpoint: { max: 847, min: 481 },
        items: 2,
        //partialVisibilityGutter: 30
      },
      mobile: {
        breakpoint: { max: 480, min: 0 },
        items: 1,
        //partialVisibilityGutter: 40
      }
    }; 

    const bonus_responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 6,
        //partialVisibilityGutter: 50
      },
      desktop: {
        breakpoint: { max: 3000, min: 1351 },
        items: 6,
        //partialVisibilityGutter: 50
      },
      desktop2: {
        breakpoint: { max: 1350, min: 1024 },
        items: 4,
        //partialVisibilityGutter: 50
      },
      tablet: {
        breakpoint: { max: 1024, min: 848 },
        items: 3,
        //partialVisibilityGutter: 30
      },
      tablet1: {
        breakpoint: { max: 847, min: 481 },
        items: 2,
        //partialVisibilityGutter: 30
      },
      mobile: {
        breakpoint: { max: 480, min: 0 },
        items: 1,
        //partialVisibilityGutter: 40
      }
    }; 

    var bonus_videos_array = this.state.bonus_videos;
    var tutorial = this.state.tutorial_video;

    if(this.state.isLoading)
    {
      return (
        <>
          <div className="main-wrapper another-main-wrapper">
            <header>
              <figure className="waves-top"></figure>
              <figure className="logo">
                <img src={Logo} alt="logo" />
              </figure>
              <AuthLinks />
            </header>

            {isLoading ? (
                <Loader />
              ) : (
                <span></span>
            )}

              <div className="mid-container library-container library_screen">
                <div className="content mt-0">
                  <div className="video-listing-wrap">          
                    <div className="row">              

                    </div>                                            
                  </div>                                            
                </div>                                            
              </div>                                                                                       
            <GeometryBundle2 />
            <CommonFooter />           
          </div>    
        </>
      );
    }
    else
    {
      return (
        <>
          <div className="main-wrapper another-main-wrapper">
            <header>
              <figure className="waves-top"></figure>
              <figure className="logo">
                <img src={Logo} alt="logo" />
              </figure>
              <AuthLinks />
            </header>

            {isLoading ? (
                <Loader />
              ) : (
                <span></span>
            )}

                <div className="mid-container library-container library_screen">
                  <div className="content mt-0">
                    <div className="video-listing-wrap">          
                      <div className="row">

                            {this.state.insight_videos.length > 0 ? (
                            <>
                            <div className="col-12 grey_background">
                            <h5 className="mb-3">Insight Videos <span>Total Videos : {this.state.insight_videos_count} Video(s)</span></h5>
                            <div className="video-slide-1 video-slider">
                            <Carousel responsive={responsive} partialVisible={true} ssr={true}>
                              {this.state.insight_videos.map((insight_video, index) => (  
                                <article key={index}>
                                  <div className="card video-card">
                                      <Link to="#" onClick={this.openVideoModal} className="cur_pointer">
                                            <VimeoCons get_thumbnail_only="TRUE" video_url={insight_video.video_url} video_id={insight_video.videoID} uservideo_id={insight_video.uservideo_id} video_type="insight" media_name={insight_video.media_name} />
                                      </Link>
                                      <div className="card-body">
                                        <h5 className="card-title mb-0">{insight_video.media_name}</h5>
                                        <p className='mb-0'><small>Last Viewed : {Moment(`${insight_video.last_viewed}`).fromNow()}</small></p>
                                      </div>
                                  </div>
                                </article>
                              ))}
                            </Carousel>
                            </div>
                            </div>
                            </> ) : ( 
                            <>
                            </>
                            )}

                            <hr className="w-100 pb-2" />

                            {this.state.flow_videos.length > 0 ? (
                            <>
                            <div className="col-12">
                            <h5 className="mb-3">Heartfulness Flows <span>Total Videos : {this.state.flow_videos_count} Video(s)</span></h5>
                            <div className="video-slide-1 video-slider">
                            <Carousel responsive={flows_responsive} partialVisible={true} ref={(el) => (this.Carousel = el)}>
                              {this.state.flow_videos.map((flow_video, index) => (
                                <article key={index}>
                                  <div className={`card video-card ${flow_video.available_for_free_members === 0 && plan_id === '1' ? "premium-video-card" : ""}`}>
                                      <Link to="#" onClick={this.openVideoModal} className="d-block cur_pointer mb-3">
                                        <VimeoCons get_thumbnail_only="TRUE" video_url={flow_video.video_url} video_type="flow" media_name={flow_video.media_name} />
                                      </Link>
                                      <div className="card-body">
                                        <h5 className="card-title mb-0">{flow_video.media_name}</h5>
                                        <p className="my-2">{flow_video.description.substring(0,60)+'...'}</p>
                                        <div className="card-links">
                                          <Link to="#" onClick={this.openModal} media_name={flow_video.media_name} description={flow_video.description}>Read More</Link>
                                          {(flow_video.pdf_file === '' || flow_video.pdf_file === null) ? (
                                              <>
                                              </>
                                            ) : (
                                              <Link to="#" onClick={this.openPDFModal} className="pdf_file"><img src={PdfIcon} alt="PdfIcon" className="img-responsive" media_name={flow_video.media_name} pdf_url={baseapiURLPDF+flow_video.pdf_file} /></Link>
                                            )
                                          }
                                          {(flow_video.audio_file === '' || flow_video.audio_file === null) ? (
                                              <>
                                              </>
                                            ) : (
                                              <Link to="#" onClick={this.openAudioModal} className="audio_file"><img src={AudioIcon} alt="AudioIcon" className="img-responsive" media_name={flow_video.media_name} audio_file={baseapiURLaudios+flow_video.audio_file} /></Link>
                                            )
                                          }  
                                        </div> 
                                      </div>
                                      <Link to="/membership-upgrade" className="overlay-text">
                                        <p>
                                          <FontAwesomeIcon icon={faLock} />
                                          <span className="d-block">Tap to Upgrade to Premium and unlock this video.</span>
                                        </p>
                                      </Link>
                                  </div>
                                </article>
                              ))}
                            </Carousel>
                            </div>
                            </div>
                            </> ) : ( 
                            <>
                            </>
                            )} 

                            <hr className="w-100 pb-2" />

                            {this.state.tune_up_videos.length > 0 ? (
                            <>
                            <div className="col-12 grey_background">
                            <h5 className="mb-3">33 Second Tune-Ups <span>Total Videos : {this.state.tune_up_videos_count} Video(s)</span></h5>
                            <div className="video-slide-1 video-slider">
                            <Carousel responsive={flows_responsive} partialVisible={true} ref={(el) => (this.Carousel = el)}>
                              {this.state.tune_up_videos.map((tune_up_video, index) => (
                                <article key={index}>
                                  <div className={`card video-card ${tune_up_video.available_for_free_members === 0 && plan_id === '1' ? "premium-video-card" : ""}`}>
                                      <Link to="#" onClick={this.openVideoModal} className="d-block cur_pointer mb-3">
                                        <VimeoCons get_thumbnail_only="TRUE" video_url={tune_up_video.video_url} video_type="33_second" media_name={tune_up_video.media_name} />
                                      </Link>
                                      <div className="card-body">
                                        <h5 className="card-title mb-0">{tune_up_video.media_name}</h5>
                                        <p className="my-2">{tune_up_video.description.substring(0,60)+'...'}</p>
                                        <div className="card-links">
                                          <Link to="#" onClick={this.openModal} media_name={tune_up_video.media_name} description={tune_up_video.description}>Read More</Link>
                                          {(tune_up_video.pdf_file === '' || tune_up_video.pdf_file === null) ? (
                                              <>
                                              </>
                                            ) : (
                                              <Link to="#" onClick={this.openPDFModal} className="pdf_file"><img src={PdfIcon} alt="PdfIcon" className="img-responsive" media_name={tune_up_video.media_name} pdf_url={baseapiURLPDF+tune_up_video.pdf_file} /></Link>
                                            )
                                          }
                                          {(tune_up_video.audio_file === '' || tune_up_video.audio_file === null) ? (
                                              <>
                                              </>
                                            ) : (
                                              <Link to="#" onClick={this.openAudioModal} className="audio_file"><img src={AudioIcon} alt="AudioIcon" className="img-responsive" media_name={tune_up_video.media_name} audio_file={baseapiURLaudios+tune_up_video.audio_file} /></Link>
                                            )
                                          }  
                                        </div> 
                                      </div>
                                      <Link to="/membership-upgrade" className="overlay-text">
                                        <p>
                                          <FontAwesomeIcon icon={faLock} />
                                          <span className="d-block">Tap to Upgrade to Premium and unlock this video.</span>
                                        </p>
                                      </Link>
                                  </div>
                                </article>
                              ))}
                            </Carousel>
                            </div>
                            </div>
                            </> ) : ( 
                            <>
                            </>
                            )}      

                            <hr className="w-100 pb-2" />

                            {bonus_videos_array.length > 0 ? (
                            <>
                            <div className="col-12">
                            <h5 className="mb-3">Bonus Videos <span>Total Videos : {tutorial !== '' ? (bonus_videos_array.length + 1) : (bonus_videos_array.length) } Video(s)</span></h5>
                            <div className="video-slide-1 video-slider">
                            <Carousel responsive={bonus_responsive} partialVisible={true} ref={(el) => (this.Carousel = el)}>
                              {bonus_videos_array.map((bonus_video, index) => (
                                <article key={index}>
                                  <div className="card video-card">
                                      {bonus_video.video_url ? (
                                        <>
                                          <Link to="#" onClick={this.openVideoModal} className="d-block cur_pointer mb-3" data-url={bonus_video.video_url}>
                                            <VimeoCons get_thumbnail_only="TRUE" video_url={bonus_video.video_url} video_type="bonus" media_name={bonus_video.media_name} />
                                          </Link>
                                        </>
                                        ) : (
                                          <>
                                          </>
                                        )
                                      }
                                      <div className="card-body">
                                        <h5 className="card-title mb-0">{bonus_video.media_name}</h5>
                                        <p></p>
                                      </div>
                                  </div>
                                </article>
                              ))}

                              {tutorial !== '' ? (
                                <>
                                <article key={bonus_videos_array.length + 1}>
                                  <div className="card video-card">
                                      <Link to="#" onClick={this.openVideoModal} className="d-block cur_pointer mb-3" data-url={tutorial.video_url}>
                                        <VimeoCons get_thumbnail_only="TRUE" video_url={tutorial.video_url} video_type="bonus" media_name={tutorial.media_name} />
                                      </Link>
                                      <div className="card-body">
                                        <h5 className="card-title mb-0">{tutorial.media_name}</h5>
                                        <p></p>
                                      </div>
                                  </div>
                                </article>
                                </>
                                  ) : ( <> </> )
                              }
                            </Carousel>
                            </div>
                            </div>
                            </> ) : ( 
                            <>
                            </>
                            )}                          

                            <hr className="w-100 pb-2" />                     

                      </div>                                            
                    </div>                                            
                  </div>                                            
                </div>                                                                                       
            <GeometryBundle2 />
            <CommonFooter />           
          </div>

              { this.state.isOpen ? 
                <CustomModal 
                closeModal={this.closeModal} 
                isOpen={this.state.isOpen} 
                description={this.state.modal_description}
                media_name={this.state.modal_name}
                /> 
                : 
                null 
              }

              { this.state.isPDFOpen ? 
                <CustomPDFModal 
                closeModal={this.closePDFModal} 
                isOpen={this.state.isPDFOpen} 
                pdf_url={this.state.pdf_url}
                media_name={this.state.modal_name}
                /> 
                : 
                null 
              }   

              { this.state.isAudioOpen ? 
                <CustomAudioModal 
                closeModal={this.closeAudioModal} 
                isOpen={this.state.isAudioOpen} 
                audio_url={this.state.audio_url}
                media_name={this.state.modal_name}
                /> 
                : 
                null 
              } 

              { this.state.isVideoOpen ? 
                <CustomVideoModal 
                  closeModal={this.closeVideoModal} 
                  isOpen={this.state.isVideoOpen} 
                  video_info={this.props.location.state.received_video_url}
                  video_name={this.props.location.state.video_name}
                  video_type={this.props.location.state.video_type}
                  video_id={(this.props.location.state.video_id) ? this.props.location.state.video_id : ''}
                  uservideo_id={(this.props.location.state.uservideo_id) ? this.props.location.state.uservideo_id : ''}
                /> 
                : 
                null 
              }      

              { this.state.isLibraryVideoOpen ? 
                <CustomVideoModal 
                  closeModal={this.closeLibraryVideoModal} 
                  isOpen={this.state.isLibraryVideoOpen} 
                  video_info={this.state.library_video_url}
                  video_name={this.state.library_video_name}
                  video_type={this.state.library_video_type}
                  video_id={(this.state.library_video_id) ? this.state.library_video_id : ''}
                  uservideo_id={(this.state.library_uservideo_id) ? this.state.library_uservideo_id : ''}
                /> 
                : 
                null 
              }  
        </>
      );
    }
  }
}