import React, { Component } from "react";
import { Link,Redirect } from "react-router-dom";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Input } from "reactstrap";
import axios from "axios";
import Moment from 'moment';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import SignUp from "./../../images/signup-img.png";
import Logo from "./../../images/logo.png";
import Loader from "./../../components/Loader";
import CommonFooter from "./../../components/CommonFooter";
import SignUpGeometryBundle from "./../../components/SignUpGeometryBundle";

export default class Signin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      msg: "",
      isLoading: false,
      redirect: false,
      errMsgEmail: "",
      errMsgPwd: "",
      errMsg: "",
      errCookiesMsg: "",
      errors: {},
      hidden: true
    };
    this.toggleShow = this.toggleShow.bind(this);
  }

  onChangehandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };

  toggleShow(e) {
    e.preventDefault();
    this.setState({ hidden: !this.state.hidden });
  }

  validateForm() 
  {
    let email_val = this.state.email;
    let password_val = this.state.password;
    let errors = {};
    let formIsValid = true;

    if (!email_val) 
    {
      formIsValid = false;
      errors["email"] = "* Please enter your Email Address.";
    }

    if (typeof email_val !== "undefined") 
    {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

      if (!pattern.test(email_val)) 
      {
        formIsValid = false;
        errors["email"] = "* Please enter valid Email Address.";
      }
    }

    if (!password_val) 
    {
      formIsValid = false;
      errors["password"] = "* Please enter your Password.";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

signup(res, type) 
  {
    if(res.error && res.error === 'idpiframe_initialization_failed' && res.details && res.details === 'Cookies are not enabled in current environment.' && type === 'google')
    {
      this.setState({
        errCookiesMsg: 'Cookies are Disabled. Kindly enable Cookies to Signin Using Google.',
      });
      //setTimeout(() => {
        //this.setState({ errCookiesMsg: "" });
      //}, 10000);
    }

    let postData;

    var current_datetime = Moment(new Date()).format('YYYY-MM-DD HH:mm:ss');

      if (type === 'facebook' && res.userID) 
      {
        postData = {
          name:res.name ? res.name : "",
          email:res.email ? res.email : "",
          register_type: "facebook",
          device_type:"web",
          sign_up_platform:"web",
          email_verified_at: current_datetime,
          socialID: res.userID,
          password : ""
        };
      }

      if(type === 'google' && res.profileObj && res.profileObj.googleId) 
      {
        postData = {
          name:res.profileObj.name ? res.profileObj.name : "",
          email:res.profileObj.email ? res.profileObj.email : "",
          register_type: "gmail",
          device_type:"web",
          sign_up_platform:"web",
          email_verified_at: current_datetime,
          socialID: res.profileObj.googleId,
          password : ""
        };
      }

      if(postData) 
      {
        this.setState({ isLoading: true });
        axios
        .post("/register", postData)
        .then((response) => {
          this.setState({ isLoading: false });

          if (response.status === 200) 
          {
            localStorage.setItem("isLoggedIn", true);
            if(response.data.data)
            {
              localStorage.setItem("userData", JSON.stringify(response.data.data));
              localStorage.setItem("userToken", response.data.success.token);
            }
            else
            {
              localStorage.setItem("userData", JSON.stringify(response.data.success));
              localStorage.setItem("userToken", response.data.success.token);
            }
            axios.defaults.headers.common['Authorization'] = "Bearer "+response.data.success.token;

            setTimeout(() => {
              this.setState({ msg: "",redirect: true });
            }, 500);
          }

          if (response.status === "failed") 
          {
            this.setState({ msg: response.data.error });
            setTimeout(() => {
              this.setState({ msg: "" });
            }, 5000);
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
        });
      } 
  }  

  terms_of_use = () => {
    window.open('https://socreates.com/terms-of-use', '_blank');
  };

  privacy_policy = () => {
    window.open('https://socreates.com/privacy-policy', '_blank');
  };  

  onSignInHandler = (e) => {
    e.preventDefault();
    if (this.validateForm()) 
    {
      const url = '/login';
      this.setState({ isLoading: true });
      axios
        .post(url, {
          email: this.state.email,
          password: this.state.password,
        })
        .then((response) => {
          this.setState({ isLoading: false });
          if(response.status === 200) 
          {
              const received_data = JSON.stringify(response.data.data);
              const received_success = response.data.success;

              localStorage.setItem("isLoggedIn", true);
              localStorage.setItem("userData", received_data);

              if(response.data.data.email_verified_at === "" || response.data.data.email_verified_at === null)
              {
                localStorage.setItem("userToken", received_success.partial_token);
                axios.defaults.headers.common['Authorization'] = "Bearer "+received_success.partial_token;
              }
              else
              {
                localStorage.setItem("userToken", received_success.token);
                axios.defaults.headers.common['Authorization'] = "Bearer "+received_success.token;
              }

              this.setState({
                msg: response.data.message,
                redirect: true,
                email: '',
                password: '',
                hidden: false,
              });      
          }

          if(response.status === "failed" && response.success === undefined) 
          {
            this.setState({
              errMsgEmail: response.data.validation_error.email,
              errMsgPwd: response.data.validation_error.password,
            });
            setTimeout(() => {
              this.setState({ errMsgEmail: "", errMsgPwd: "" });
            }, 2000);
          } 
          else if(response.status === "failed" && response.success === false) 
          {
            this.setState({
              errMsg: response.data.message,
            });
            setTimeout(() => {
              this.setState({ errMsg: "" });
            }, 2000);
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          if(error.response.status === 401) 
          {
            this.setState({
              errMsg: error.response.data.error,
            });
            setTimeout(() => {
              this.setState({ errMsg: "" });
            }, 5000);
          }
        });
    }
  };

  render() {

    if (this.state.redirect) 
    {
      const user = JSON.parse(localStorage.getItem("userData"));

      if(user && (user.email_verified_at === "" || user.email_verified_at === null))
      {
        return <Redirect to="/email-verification" push={true} />;
      }
      else if(user && (user.has_suggested_journey === "false" || user.has_suggested_journey === null))
      {
        return <Redirect to="/welcome" push={true} />;
      }
      else if(user)
      {
        return <Redirect to="/home" />;
      }
    }

    const login = localStorage.getItem("isLoggedIn");

    if(login) 
    {
      return <Redirect to="/home" />;
    }

    const isLoading = this.state.isLoading;

    const responseFacebook = (response) => {
      this.signup(response, 'facebook');
    }

    const responseGoogle = (response) => {
      this.signup(response, 'google');
    }
    return (
      <>
        <div className="main-wrapper signup-main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
          </header>

          {isLoading ? (
            <Loader />
            ) : (
            <span></span>
          )}

          <div className="mid-container signup-container">
            <div className="content signup-wrapper">
              <div className="row">
                <div className="col-md-6 col-12 align-self-center">
                  <figure><img src={SignUp} alt="SignUp" className="left-img" /></figure>
                </div>
                <div className="col-md-6 col-12 p-0">
                  <div className="signup-form-wrapper login-form">
                    <Form className="row" id="login_form">

                      <div className="form-group col-12 mb-sm-4 mb-2">
                        <Input
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter Email Address *"
                          value={this.state.email}
                          onChange={this.onChangehandler}
                          maxLength="50"
                          autoComplete='off'
                        />
                        <span className="text-danger float-left mt-1">{this.state.msg}</span>
                        <span className="text-danger float-left mt-1">{this.state.errMsgEmail}</span>
                        <span className="text-danger float-left mt-1">{this.state.errors.email}</span>
                      </div>  

                      <div className="form-group col-12 mb-2">
                        <Input
                          type={this.state.hidden ? 'password' : 'text'}
                          name="password"
                          className="form-control"
                          placeholder="Enter Password *"
                          value={this.state.password}
                          onChange={this.onChangehandler}
                          maxLength="30"
                        />
                        <p onClick={this.toggleShow} className="signin-eye cur_pointer"><FontAwesomeIcon icon={faEye} /></p>
                        <span className="text-danger float-left mt-1">{this.state.errMsgPwd}</span>
                        <span className="text-danger float-left mt-1">{this.state.errors.password}</span>
                        <p className="text-danger mt-2">{this.state.errMsg}</p>
                      </div>                    

                      <div className="col-12">
                        <p className="text-sm-right text-center mb-3 mb-sm-0"><Link to="/forgot-password" className="text-white external_link">Forgot Password?</Link></p>
                      </div>

                      <div className="form-group col-12 text-md-center mb-0">
                        <button className="btn-arrow" onClick={this.onSignInHandler}><FontAwesomeIcon icon={faArrowRight} /></button>   
                      </div>

                      <div className="col-12">
                        <span className="text-danger mt-2 text-center cookies_error">{this.state.errCookiesMsg}</span>
                        <p className="divider">OR Continue with</p>
                        <div className="social-login-btns">
                          <FacebookLogin
                            appId="228905318460007" //APP ID NOT CREATED YET
                            textButton="Facebook"
                            fields="name,email,picture"
                            icon='fa-facebook'
                            cssClass="custom_facebook"
                            callback={responseFacebook}
                          />

                          <GoogleLogin
                            clientId="933245719811-plfnu28orukrvekboias68ckmvk9l2pc.apps.googleusercontent.com" //CLIENTID NOT CREATED YET
                            buttonText="GOOGLE"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            className="social_google_login mt-3"
                            loginHint="Login With Google"
                          /> 
                        </div>
                        <p className="text-white text-center mt-3">Doesn't have an account yet? <Link to="/sign-up" className="text-white external_link"><b>SignUp</b></Link></p>
                        <p className="text-white text-center mt-3"> By going further, you are agreeing to Socreates's <Link to="/terms-of-use" className="text-white external_link">Terms of Use</Link> and <Link to="/privacy-policy" className="text-white external_link">Privacy Policy</Link></p>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <SignUpGeometryBundle />
          <CommonFooter />
        </div>
      </>
    );
  }
}