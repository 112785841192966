import axios from "axios";

export default class APICalls
{
	getCurrentMembershipDetails = (params) => {
		return axios.get('get_current_membership','');
	};

	getUserDetails = (params) => {
		return axios.get('user','');
	};	

	getActiveJourneys = (params) => {
		return axios.post('myMemberShipJourney','');
	};	
}