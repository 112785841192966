import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Moment from 'moment';
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeartfulnessInviteGoDeeper from "./../../images/HeartfulnessInviteGoDeeper.gif";
import Logo from "./../../images/logo.png";
import Screen8 from "./../Screens/Screen8";
import SignupSuccess from "./../Screens/SignupSuccess";
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import Loader from "./../Loader";
import CommonFooter from "./../CommonFooter";
import AuthLinks from "./../AuthLinks";
import GeometryBundle1 from "./../GeometryBundle1";

export default class Screen9 extends Component {
  userData;
  constructor(props) 
  {
    super(props);
    this.state = { redirect: false,isLoading:false,errCookiesMsg:'' };
    this.signup = this.signup.bind(this);
  }

  clickBtn = e => {
    this.setState({
      renderView: +e.target.value
    });
  };

  signup(res, type) 
  {
    if(res.error && res.error === 'idpiframe_initialization_failed' && res.details && res.details === 'Cookies are not enabled in current environment.' && type === 'google')
    {
      this.setState({
        errCookiesMsg: 'Cookies are Disabled. Kindly enable Cookies to Signin Using Google.',
      });
      //setTimeout(() => {
        //this.setState({ errCookiesMsg: "" });
      //}, 10000);
    }

    let postData;
    
    var current_datetime = Moment(new Date()).format('YYYY-MM-DD HH:mm:ss');

      if (type === 'facebook' && res.userID) 
      {
        postData = {
          name:res.name ? res.name : "",
          email:res.email ? res.email : "",
          register_type: "facebook",
          device_type:"web",
          sign_up_platform:"web",
          email_verified_at: current_datetime,
          socialID: res.userID,
          password : ""
        };
      }

      if(type === 'google' && res.profileObj && res.profileObj.googleId) 
      {
        postData = {
          name:res.profileObj.name ? res.profileObj.name : "",
          email:res.profileObj.email ? res.profileObj.email : "",
          register_type: "gmail",
          device_type:"web",
          sign_up_platform:"web",
          email_verified_at: current_datetime,
          socialID: res.profileObj.googleId,
          password : ""
        };
      }

      if(postData) 
      {
        this.setState({ isLoading: true });
        axios
        .post("/register", postData)
        .then((response) => {
          this.setState({ isLoading: false });
          if (response.status === 200) 
          {
            localStorage.setItem("isLoggedIn", true);
            if(response.data.data)
            {
              localStorage.setItem("userData", JSON.stringify(response.data.data));
              localStorage.setItem("userToken", response.data.success.token);
            }
            else
            {
              localStorage.setItem("userData", JSON.stringify(response.data.success));
              localStorage.setItem("userToken", response.data.success.token);
            }
            axios.defaults.headers.common['Authorization'] = "Bearer "+response.data.success.token;

            setTimeout(() => {
              this.setState({ msg: "",redirect: true });
            }, 200);
          }

          if (response.status === "failed") 
          {
            this.setState({ msg: response.data.error });
            setTimeout(() => {
              this.setState({ msg: "" });
            }, 5000);
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
        });
      } 
  }

  render() {
    if(this.state.renderView === 8)
    {
      return <Screen8 />;
    }

    if (this.state.redirect) 
    {
      return <SignupSuccess />;
    }
    const isLoading = this.state.isLoading;

    const responseFacebook = (response) => {
      this.signup(response, 'facebook');
    }

    const responseGoogle = (response) => {
      this.signup(response, 'google');
    }

    return (
      <>  
        <div className="main-wrapper signup-main-wrapper">
          {isLoading ? (
              <Loader />
            ) : (
              <span></span>
          )}

          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>

          <div className="mid-container so-container">
            <div className="content signup-option-content">
                <div className="row align-self-center">
                    <div className="col-md-6 col-12">
                        <figure><img src={HeartfulnessInviteGoDeeper} alt="HeartfulnessInviteGoDeeper" className="left-img img-shadow" /></figure>
                    </div>
                    <div className="col-md-6 col-12 align-self-center">
                        <div className="px-md-5 px-0 mt-sm-0 mt-4">
                          <p>Do you want to go deeper <br/>and meet the new you?</p>
                          <span className="text-danger mt-2 text-center cookies_error">{this.state.errCookiesMsg}</span>
                          <p className="mt-md-2 mt-1">Continue with</p>
                          <p className="social-signin-btns social_login_buttons">
                          <FacebookLogin
                            appId="228905318460007" //APP ID NOT CREATED YET
                            textButton="Facebook"
                            fields="name,email,picture"
                            icon='fa-facebook'
                            cssClass="custom_facebook"
                            callback={responseFacebook}
                          />

                          <GoogleLogin
                            clientId="933245719811-plfnu28orukrvekboias68ckmvk9l2pc.apps.googleusercontent.com" //CLIENTID NOT CREATED YET
                            buttonText="Google"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            className="social_google_login"
                            loginHint="Login With Google"
                          /> 
                          </p>
                          <p className="mt-md-2 mt-1">OR</p>
                          <div className="mt-2">
                            <button value={8} className="btn btn-primary" onClick={this.clickBtn}>BACK</button>
                            <Link to="/sign-up" className="btn btn-primary email-icon"><FontAwesomeIcon icon={faEnvelope} /> Sign Up with Email</Link>
                          </div>
                        </div>
                    </div>
                </div>
            </div>     
          </div>
          <GeometryBundle1 />
          <CommonFooter />
        </div> 
      </>
    );
  }
}