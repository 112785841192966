import React, { Component } from "react";
import { Link } from "react-router-dom";
import JournalIcon from "./../images/journal.svg";
import LibraryIcon from "./../images/library.svg";
import ProgressIcon from "./../images/progress.svg";
import JourneyIcon from "./../images/progress-icon.svg";
import CommunityIcon from "./../images/community.svg";

export default class CommonFooter extends Component {
  userData;
  constructor(props) 
  {
    super(props);
    this.state = {

    };
  }

  render() {

    const login = localStorage.getItem("isLoggedIn");

    if (window.location.href.indexOf("welcome") > -1 || window.location.href.indexOf("email-verification") > -1 || window.location.href.indexOf("start-day") > -1 || window.location.href.indexOf("forgot-password") > -1 || window.location.href.indexOf("journey") > -1 || window.location.href.indexOf("revisit") > -1 || window.location.href.indexOf("membership-upgrade") > -1 || window.location.href.indexOf("checkout") > -1 || window.location.href.indexOf("edit-profile") > -1 || window.location.href.indexOf("feeling") > -1 || window.location.href.indexOf("privacy-policy") > -1) 
    {
      return (
        <>
          <footer>
              <figure className="waves-bottom"></figure>
          </footer>
        </>
      );
    }

    if(!login)
    {
      return (
        <>
          <footer>
              <Link to="/privacy-policy" className="footer_policy" target="_blank">View our Privacy Policy</Link>
              <figure className="waves-bottom"></figure>
          </footer>
        </>
      );
    }
    else
    {
      return (
        <>
          <footer>
            <figure className="waves-bottom wave-after-signup"></figure>
            <div className="bottom-nav">
                <ul className="list-unstyled">
                    <li className={(window.location.href.indexOf("home") > -1) ? 'active' : ''}><Link to="/home"><figure><img src={JourneyIcon} alt="Journey" /></figure> Journey</Link></li>
                    <li className={(window.location.href.indexOf("progress") > -1) ? 'active' : ''}><Link to="/progress"><figure><img src={ProgressIcon} alt="Progress" /></figure> Progress</Link></li>
                    <li className={(window.location.href.indexOf("journal") > -1) ? 'active' : ''}><Link to="/journal"><figure><img src={JournalIcon} alt="Journal" /> </figure>Journal</Link></li>
                    <li className={(window.location.href.indexOf("library") > -1) ? 'active' : ''}><Link to="/library"><figure><img src={LibraryIcon} alt="Library" /> </figure>Library</Link></li>
                    <li className={(window.location.href.indexOf("community") > -1) ? 'active' : ''}><Link to="/community"><figure><img src={CommunityIcon} alt="Community" /></figure> Community</Link></li>                    
                </ul>
            </div>
          </footer>
        </>
      );
    }
  }
}