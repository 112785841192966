import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import Logo from "./../images/logo.png";
import AuthLinks from "./AuthLinks";
import CommonFooter from "./CommonFooter";
import DailySessionEnd from "./DailySessionEnd";
import Loader from "./Loader";
import GeometryBundle2 from "./GeometryBundle2";

export default class FeelingHow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigate: false,
      isLoading: false,
      msg: "",
      redirect: false,
      smiley_answers:[],
      redirect_to:'',
      session_day:0
    };
    this.user = JSON.parse(localStorage.getItem("userData"));
  }  

  componentDidMount() 
  {
    this.setState({ isLoading: true });

    const journey_id = localStorage.getItem('journey_id');
    /***********************************************/
    axios
    .get("/get_question_count_for_day", { 
      params: { 'journey_id':journey_id }
    })
    .then((response) => {
      this.setState({ isLoading: false });

      if(response.status === 200)
      {
        if(response.data)
        {
          this.setState({ session_day:(response.data.day_count) + 1 });          
        }
      }
    })
    .catch((error) => {
      this.setState({ isLoading: false });
    }); 
    
    if(this.props && this.props.location.state.day_count === 0)
    {
      this.setState({ redirect_to: 'start_day' });
    }

    if(this.props && this.props.location.state.day_count > 0 && this.props.location.state.question_count === 0)
    {
      this.setState({ redirect_to: 'journal' });
    }

    if(this.props && this.props.location.state.day_count > 0 && this.props.location.state.question_count > 0)
    {
      this.setState({ redirect_to: 'journey' });
    }

    if(this.props && this.props.location.state && this.props.location.state.media_id && this.props.location.state.media_id !== '')
    {
      this.setState({ redirect_to: 'daily_session_end' });
    }

    if(this.props && this.props.location.state && this.props.location.state.journey_status && this.props.location.state.journey_status !== '')
    {
      this.setState({ redirect_to: 'journey_finished' });
    }

    axios.get('/get_smiley_answers', { params:{} })
    .then((response) => {
      if(response.status === 200) 
      {       
        this.setState({
          smiley_answers : response.data.data,
        });
        this.setState({ isLoading:false });
      }
    })
    .catch((error) => {
      this.setState({ isLoading: false });
    });            
  }

  submit_feel = e => {
    if(e.target.attributes.getNamedItem("smiley_value"))
    {
      var smiley_value  = e.target.attributes.getNamedItem("smiley_value").value;
    }
    else
    {
      smiley_value = '';
    }

    if(smiley_value !== '')
    {    
      var session_day = this.state.session_day;

      var feel_id = localStorage.getItem('feel_id');
      var feel_session_id = localStorage.getItem('feel_session_id');
      var journey_id = localStorage.getItem('journey_id');

      if(feel_id)
      {
        axios
        .post("/save_user_feel", {
          'feel_id' : feel_id,
          'feel_session_id' : (feel_session_id === null) ? session_day : feel_session_id,
          'journey_id' : journey_id,
          'session_end_response' : smiley_value,
        })
        .then((response) => {
          this.setState({ isLoading: false });
          if(response.status === 200)
          {
            localStorage.removeItem('feel_id');
            localStorage.removeItem('feel_session_id');
            this.setState({ redirect: true });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          if(error.response.status === 400)
          {
            localStorage.removeItem('feel_id');
            localStorage.removeItem('feel_session_id');
            this.setState({ redirect: true });
          }
        }); 
      }
      else
      {
        axios
        .post("/save_user_feel", {
          'feel_session_id' : (feel_session_id === null) ? session_day : feel_session_id,
          'session_start_response' : smiley_value,
          'journey_id' : journey_id,
        })
        .then((response) => {
          this.setState({ isLoading: false });
          if(response.status === 200)
          {
            localStorage.setItem('feel_id',response.data.data.id);
            localStorage.setItem('feel_session_id',session_day);
            this.setState({ redirect: true });
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          if(error.response.status === 400)
          {
            localStorage.removeItem('feel_id');
            localStorage.removeItem('feel_session_id');
            this.setState({ redirect: true });
          }
        }); 
      }
    }
  };

  render() {
    const user = JSON.parse(localStorage.getItem("userData"));
    const { navigate } = this.state;
    const isLoading = this.state.isLoading;

    if(user && (user.email_verified_at === '' || user.email_verified_at === null))
    {
      return <Redirect to="/email-verification" push={true} />;
    }

    if (navigate) {
      return <Redirect to="/sign-in" push={true} />;
    }

    if(this.state.redirect && this.state.redirect_to === 'journey_finished')
    {
      return <Redirect to="/journey-finish" push={true} />;
    }

    if(this.state.redirect && this.state.redirect_to === 'start_day')
    {
      return <Redirect to="/start-day" push={true} />;
    }

    if(this.state.redirect && this.state.redirect_to === 'journal')
    {
      return <Redirect to="/journal" push={true} />;
    }

    if(this.state.redirect && this.state.redirect_to === 'journey')
    {
      return <Redirect to="/journey" push={true} />;
    }

    if(this.state.redirect && this.state.redirect_to === 'daily_session_end')
    {
      if(this.props.location.state)
      {
        return <DailySessionEnd video_info={this.props.location.state.video_info} media_id={this.props.location.state.media_id} video_name={this.props.location.state.video_name} received_video_url={this.props.location.state.received_video_url} />;
      }
      else{
        return <DailySessionEnd video_info={this.props.location.state.video_info} media_id={this.props.location.state.media_id} video_name={this.props.location.state.video_name} received_video_url={this.props.location.state.received_video_url} />;
      }
    }  
    
    const login = localStorage.getItem("isLoggedIn");

    if(!login) 
    {
      return <Redirect to="/sign-in" />;
    }

    return (
      <>
        <div className="main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>

          {isLoading ? (
            <Loader />
            ) : (
              <span></span>
          )}

          <div className="mid-container">
            <div className="content mt-0">
              <div className="row">
                <div className="col-lg-7 col-sm-10 mx-auto align-self-center">
                  <div className="px-md-5 px-0">
                    <h5 className="feeling-text">{user.name}, how are you REALLY feeling today? <br />Take a deep breath, and then answer.</h5>
                    <figure className="faces-wrap">
                        {this.state.smiley_answers.map((answer, index) => (
                          <button key={index} onClick={this.submit_feel}><img src={window.baseURLImage+'SurveyAnswers/'+answer.image_url} alt={answer.name} smiley_value={answer.smiley_value} /></button>
                        ))}
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <GeometryBundle2 />
          <CommonFooter />
        </div> 
      </>
    );
  }
}