import React, { Component } from "react";
import Modal from 'react-bootstrap/Modal';

export default class CustomPDFModal extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.user = JSON.parse(localStorage.getItem("userData"));
  }  

  render() {  
    return(
      <>
        <div className="d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
        </div>

        <Modal 
        size="lg"
        show={this.props.isOpen} 
        onHide={this.props.closeModal}
        backdrop="static" 
        keyboard={false}
        centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.props.media_name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <iframe src={this.props.pdf_url+'#toolbar=0'} height="500" style={{ width:'100%',border:'none',overflow:'hidden' }} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; encrypted-media; picture-in-picture;" title="PDF"></iframe>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}