import React, { Component } from "react";
import Amazing_work from "./../../images/amazing-work.gif";
import Logo from "./../../images/logo.png";
import AuthLinks from "./../AuthLinks";
import StartNow from "./../Screens/StartNow";
import CommonFooter from "./../CommonFooter";
import GeometryBundle3 from "./../GeometryBundle3";

export default class AmazingWork extends Component {
  constructor(props) 
  {
    super(props);
    this.state = { };
  }

  clickBtn = e => {
    this.setState({
      renderView: +e.target.value
    });
  };

  render() {
    if(this.state.renderView === 1)
    {
      return <StartNow />
    }

    return (
      <>
        <div className="main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>

          <div className="mid-container">
            <div className="content">
                <div className="row align-self-center">
                    <div className="col-md-6 col-sm-12 col-12 align-self-center mx-auto">
                        <figure><img src={Amazing_work} alt="Amazing work" className="left-img img-shadow" /></figure>
                        <button value={1} className="btn btn-primary mt-4" onClick={this.clickBtn}>Next</button>
                    </div>
                </div>
            </div>     
          </div>
          <GeometryBundle3 />
          <CommonFooter />
        </div>  
      </>
    );
  }
}