import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import Logo from "./../images/logo.png";
import AuthLinks from "./AuthLinks";
import Loader from "./Loader";
import CommonFooter from "./CommonFooter";
import CustomModal from './CustomModal';
import GeometryBundle2 from "./GeometryBundle2";

export default class Progress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigate: false,
      isLoading: false,
      redirect: false,
      journey_name:'',
      day_count:'',
      phase_name:'',
      phase_description:'',
      phase_hex:'#9cbf1d',
      total_days:'',
      day_progress_percentage:'',
      isOpen: false
    };
    this.user = JSON.parse(localStorage.getItem("userData"));
    this.questions_count = 0;
  }  

  componentDidMount() 
  {
    this.setState({ isLoading: true });
    const journey_id = localStorage.getItem("journey_id");
    axios
    .get("/get_progress_data", {
        params:{journey_id : journey_id}
    })
    .then((response) => {
      this.setState({ isLoading: false });

      if(response.status === 200)
      {
        if(response.data.data)
        {
          this.setState({ day_count: response.data.data.get_day_count,phase_name:response.data.data.phase_name,phase_description:response.data.data.phase_description,phase_hex:response.data.data.hex_code,total_days:response.data.data.total_days,day_progress_percentage:response.data.data.day_progress_percentage });
          localStorage.setItem("progress_data", JSON.stringify(response.data.data));
        }
      }
    })
    .catch((error) => {
      this.setState({ isLoading: false });
    }); 
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });
  //handleSubmit(name) => //some code

  render() {
    const user = JSON.parse(localStorage.getItem("userData"));
    if(user && (user.email_verified_at === '' || user.email_verified_at === null))
    {
      return <Redirect to="/email-verification" push={true} />;
    }
    
    const isLoading = this.state.isLoading;
    
    const login = localStorage.getItem("isLoggedIn");

    if(!login) 
    {
      return <Redirect to="/sign-in" />;
    }       

    return (
      <>
        <div className="main-wrapper another-main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>

          {isLoading ? (
            <Loader />
            ) : (
              <span></span>
          )}

            <div className="mid-container progress-container">
              <div className="content signup-option-content mt-0">
                  <div className="row">
                    <div className="col-lg-5 col-sm-8 col-12 mx-auto">
                      <div className="progress-wrapper">                          
                          <p className="p-circle circle-1" style={{ color: `${this.state.phase_hex}`,border: `25px solid ${this.state.phase_hex}` }}>{this.state.phase_name}
                              <span className="d-block" style={{ color: `${this.state.phase_hex}` }}>{this.state.phase_description}</span>
                          </p>
                          <p className="p-circle circle-2">{this.state.day_progress_percentage}%</p>
                          <p className="p-circle circle-3">Day {this.state.day_count} of {this.state.total_days}</p>                          
                      </div>      
                    </div>
                  </div>
              </div>            
            </div>
          <GeometryBundle2 />
          <CommonFooter />
        </div>
        { this.state.isOpen ? 
          <CustomModal 
          closeModal={this.closeModal} 
          isOpen={this.state.isOpen} 
          handleSubmit={this.handleSubmit}
          /> 
          : 
          null 
        }
      </>
    );
  }
}