import React, { Component } from "react";
import SignupCircle1 from "./../images/signup-circle-1.svg";
import SignupCircle2 from "./../images/signup-circle-2.svg";
import SignupCircle3 from "./../images/signup-circle-3.svg";

export default class SignUpGeometryBundle extends Component {

  render() {    
    return (
      <>
          <figure className="geomatric-imgs d-none d-md-block">
            <img src={SignupCircle1} alt="SignUp Sacred Geometry" className="geo-top-left geo-top-left circle-top-left" />
            <img src={SignupCircle2} alt="SignUp Sacred Geometry" className="geo-top-right circle-bottom-left" />
            <img src={SignupCircle2} alt="SignUp Sacred Geometry" className="geo-bottom-left circle-top-right" />
            <img src={SignupCircle3} alt="SignUp Sacred Geometry" className="geo-bottom-right circle-bottom-right" />
          </figure>
      </>
    );
  }
}