import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Button, Form, FormGroup, Input } from "reactstrap";
import axios from "axios";
import Logo from "./../images/logo.png";
import Sad from "./../images/ic_sad_view.png";
import AuthLinks from "./AuthLinks";
import Loader from "./../components/Loader";
import CommonFooter from "./CommonFooter";
import GeometryBundle1 from "./GeometryBundle1";

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      msg: "",
      isLoading: false,
      redirect: false,
      errMsgEmail: "",
      errMsg: "",
      errors: {}
    };
  }

  onChangehandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };

  validateForm() 
  {
    let email_val = this.state.email;
    let errors = {};
    let formIsValid = true;

    if (!email_val) 
    {
      formIsValid = false;
      errors["email"] = "* Please enter your Email Address.";
    }

    if (typeof email_val !== "undefined") 
    {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

      if (!pattern.test(email_val)) 
      {
        formIsValid = false;
        errors["email"] = "* Please enter valid Email Address.";
      }
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  send_password_recover_link = () => {
    if (this.validateForm()) 
    {
      const url = '/create';
      this.setState({ isLoading: true });
      axios
        .post(url, {
          email: this.state.email
        })
        .then((response) => {
          this.setState({ isLoading: false });
          if(response.status === 200) 
          {
              this.setState({
                msg: response.data.message,
                email: '',
              });    
              setTimeout(() => {
                this.setState({ msg: "",redirect:true });
              }, 5000);  
          }

          if(response.status === 404) 
          {
              this.setState({
                errMsg: 'Email you entered is not registered ,please register yourself.',
                email: '',
              });  
              setTimeout(() => {
                this.setState({ errMsg: "" });
              }, 2000);     
          }

          if(response.status === "failed" && response.success === undefined) 
          {
            this.setState({
              errMsgEmail: response.data.validation_error.email,
            });
            setTimeout(() => {
              this.setState({ errMsgEmail: "" });
            }, 2000);
          } 
          else if(response.status === "failed" && response.success === false) 
          {
            this.setState({
              errMsg: response.data.message,
            });
            setTimeout(() => {
              this.setState({ errMsg: "" });
            }, 2000);
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          if(error.response.status === 401) 
          {
            this.setState({
              errMsg: error.response.data.error,
            });
            setTimeout(() => {
              this.setState({ errMsg: "" });
            }, 5000);
          }

          if(error.response.status === 404) 
          {
              this.setState({
                errMsg: 'Email you entered is not registered ,please register yourself.',
                email: '',
              });  
              setTimeout(() => {
                this.setState({ errMsg: "" });
              }, 8000);     
          }
        });
    }
  };

  render() {
    const isLoading = this.state.isLoading;
    if(this.state.redirect)
    {
      return <Redirect to="/sign-in" />;
    }

    const login = localStorage.getItem("isLoggedIn");
    if(login) 
    {
      return <Redirect to="/home" />;
    }     

    return (
      <>
        <div className="main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>

          {isLoading ? (
            <Loader />
            ) : (
              <span></span>
          )}

          <div className="mid-container forgot_pass">
            <div className="content mt-0">
                <div className="row align-self-center">
                    <div className="col-md-6 col-12">
                        <figure><img src={Sad} alt="Sad" className="left-img img-shadow" /></figure>
                    </div>
                    <div className="col-md-6 col-12 align-self-center">
                        <div className="px-md-5 px-0 mt-sm-0 mt-4">
                          <h5><b>Forgot Password ?</b></h5>
                          <p className="text-center my-3">Enter your Email Below to receive your Password Reset link.</p>
                          <Form className="forgot_password">
                            <FormGroup className="text-left">
                              <Input
                                type="email"
                                name="email"
                                className=""
                                placeholder="Enter Email Address *"
                                value={this.state.email}
                                onChange={this.onChangehandler}
                                autoComplete='off'
                                maxLength="40"
                              />
                              <p className="text-danger text-center mt-3">{this.state.errMsg}</p>
                              <p className="text-danger text-center">{this.state.errMsgEmail}</p>
                              <p className="text-danger text-center">{this.state.errors.email}</p>
                            </FormGroup>

                            <p className="text-success">{this.state.msg}</p>

                            <div className="text-center">
                              <Button
                                className="btn-primary"
                                color="success"
                                onClick={this.send_password_recover_link}
                              >Send</Button>
                            </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>     
          </div>
          <GeometryBundle1 />
          <CommonFooter />
        </div> 
      </>
    );
  }
}