import React, { Component } from "react";
import ThumbsUp from "./../../images/h-thumbsUp.gif";
import Logo from "./../../images/logo.png";
import AuthLinks from "./../AuthLinks";
import { Redirect } from "react-router-dom";
import Tutorial from "./../Screens/Tutorial";
import CommonFooter from "./../CommonFooter";
import GeometryBundle1 from "./../GeometryBundle1";

export default class StartNow extends Component {
  constructor(props) 
  {
    super(props);
    this.state = { };
  }

  clickBtn = e => {
    this.setState({
      renderView: e.target.value
    });
  };

  render() {
    if(this.state.renderView === 'later')
    {
      return <Redirect to="/home" />
    }

    if(this.state.renderView === 'now')
    {
      return <Tutorial />
    }

    return (
      <>
        <div className="main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>

          <div className="mid-container">
            <div className="content">
                <div className="row align-self-center">
                    <div className="col-md-6 col-12">
                        <figure><img src={ThumbsUp} alt="ThumbsUp" className="left-img img-shadow" /></figure>
                    </div>
                    <div className="col-md-6 col-12 align-self-center">
                        <div className="px-md-5 px-0 mt-sm-0 mt-4">
                          <p>Start your Journey Within now or save for later?</p>
                        </div>
                          <button value='now' className="btn btn-primary" onClick={this.clickBtn}>Start Now</button>
                          <button value='later' className="btn btn-primary" onClick={this.clickBtn}>Save for Later</button>
                    </div>
                </div>
            </div>     
          </div>
          <GeometryBundle1 />
          <CommonFooter />
        </div> 
      </>
    );
  }
}