import React, { Component } from "react";
import { Redirect,Link } from "react-router-dom";
import axios from "axios";
import { Tabs, Tab } from 'react-bootstrap';
import Logo from "./../images/logo.png";
import DataNotFound from "./../images/data_not_found.gif";
import AuthLinks from "./AuthLinks";
import Loader from "./Loader";
import CommonFooter from "./CommonFooter";
import GeometryBundle2 from "./GeometryBundle2";

export default class Journal extends Component {
  userData;
  constructor(props) 
  {
    super(props);
    this.state = {
      redirect:false,
      isLoading:false,
      socreates_takeaways:[],
      user_takeaways:['No Response','No Response','No Response'],
      assessment_responses:[],
      end_of_journey_responses:[],
      journey_data_not_found:false,
      assessment_data_not_found:false,
      reflections_data_not_found:false,
      redirect_to_library:false,
      last_video_url:'',
      last_video_name:'',
    };
  }
  
  componentDidMount() 
  {
      const journey_id = localStorage.getItem('journey_id');
      this.setState({ isLoading:true });

      axios.get('/get_user_journal', { params:{ journey_id:journey_id } })
      .then((response) => {
        if(response.status === 200) 
        {
          this.setState({ isLoading:false,socreates_takeaways:response.data.data.socreates_takeaways,user_takeaways:response.data.data.user_takeaways,last_video_url:response.data.data.video_url,last_video_name:response.data.data.video_name,video_id:response.data.data.videoID, uservideo_id:response.data.data.uservideo_id });
        }
      })
      .catch((error) => {
        if(error.response.status === 400)
        {
          this.setState({ isLoading:false,reflections_data_not_found:true });
        }
      });
  }

  clickBtn = e => {
    this.setState({
      renderView: +e.target.value
    });
  };

  changeTab = e => {
    if(e === 'assessment')
    {
      this.setState({ isLoading:true });
      axios.get('/get_assessment_answers_for_users', {})
      .then((response) => {
        this.setState({ isLoading:false });
        if(response.status === 200) 
        {
          this.setState({ assessment_responses:response.data.data });
        }
      })
      .catch((error) => {
        if(error.response.status === 400)
        {
          this.setState({ isLoading:false,assessment_data_not_found:true });
        }
      });
    }

    if(e === 'end_of_journey')
    {
      this.setState({ isLoading:true });
      const journey_id = localStorage.getItem('journey_id');
      axios.get('/get_user_end_of_journey_responses', { params:{ journey_id:journey_id } })
      .then((response) => {
        this.setState({ isLoading:false });
        if(response.status === 200) 
        {
          this.setState({ end_of_journey_responses:response.data.data });
        }
      })
      .catch((error) => {
        if(error.response.status === 400)
        {
          this.setState({ isLoading:false,journey_data_not_found:true });
        }
      });
    }

    if(e === 'journal')
    {
      this.setState({ isLoading:true });
      setTimeout(
        () => this.setState({ isLoading: false }), 
        500
      );
    }
  };

  go_to_library = e => {
    this.setState({
      redirect_to_library: true
    });
  };

  render() {
    var video_name = this.state.last_video_name;
    var video_url  = this.state.last_video_url;
    var uservideo_id  = this.state.uservideo_id;
    var video_id  = this.state.video_id;

    if(this.state.redirect)
    {
      return <Redirect to="/home"/>;
    }

    if(this.state.redirect_to_library)
    {
      return <Redirect
          to={{
          pathname: "/library",
          state: { video_name:video_name, received_video_url:video_url,video_type:'insight',uservideo_id:uservideo_id,video_id:video_id }
      }}
      />
    }
    const isLoading = this.state.isLoading;
    const reflections_data_not_found = this.state.reflections_data_not_found;
    const assessment_data_not_found = this.state.assessment_data_not_found;
    const journey_data_not_found = this.state.journey_data_not_found;
    
    const login = localStorage.getItem("isLoggedIn");

    if(!login) 
    {
      return <Redirect to="/sign-in" />;
    }     

    const user = JSON.parse(localStorage.getItem("userData"));

    if(user && (user.email_verified_at === '' || user.email_verified_at === null))
    {
      return <Redirect to="/email-verification" push={true} />;
    }

    return (
      <>
        <div className="main-wrapper another-main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>

          {isLoading ? (
            <Loader />
            ) : (
              <span></span>
          )}

          <div className="mid-container journal-screen-container">
            <div className="content mt-3">
              <div className="row">
                <div className="col-sm-12 p-0">
                <Tabs id="myTab" defaultActiveKey="journal" transition={false} onSelect={this.changeTab} className="journal-tabs justify-content-center mb-3 mb-sm-0 mx-auto">

                  <Tab eventKey="journal" title="Reflections">
                    {reflections_data_not_found ? (
                      <figure className="mt-4"><img src={DataNotFound} alt="DataNotFound" className="gif-large img-end-journey" /></figure>
                    ) : (
                    <>
                      <div className="reflection-btns text-center mt-sm-4 mt-0">
                        <Link to="#" onClick={this.go_to_library} className="btn btn-primary">Rewatch Last Video</Link>
                        <Link to="/journey" className="btn btn-primary dark-brown">Start Today's Session</Link>
                        <Link to="/previous-sessions" className="btn btn-primary">All Previous Sessions</Link>
                      </div>
                      <h5 className="my-3 text-center tab-title">Summary of previous Day's Session</h5>
                        <div className="journal-right-content">
                          <div className="row">

                            <div className="col-md-4">
                                <div className="reflection-content">
                                    <h5 className="mb-2">Socreates Summary</h5>
                                    {this.state.socreates_takeaways[0] ? (
                                      <p className="text-justify">1. {this.state.socreates_takeaways[0]}</p>
                                    ) : (
                                      <p></p>
                                    )} 
                                    <h5 className="mb-2 mt-4">Your Reflections</h5>
                                    {this.state.user_takeaways[0] ? (
                                      <p className="text-justify">1. {this.state.user_takeaways[0]}</p>
                                    ) : (
                                      <p className="text-justify">1. No Response Submitted</p>
                                    )} 
                                </div>                                    
                            </div>

                            <div className="col-md-4 mt-3 mt-sm-0">
                                <div className="reflection-content">
                                    <h5 className="mb-2">Socreates Summary</h5>
                                    {this.state.socreates_takeaways[1] ? (
                                      <p className="text-justify">2. {this.state.socreates_takeaways[1]}</p>
                                    ) : (
                                      <p></p>
                                    )} 
                                    <h5 className="mb-2 mt-4">Your Reflections</h5>
                                    {this.state.user_takeaways[1] ? (
                                      <p className="text-justify">2. {this.state.user_takeaways[1]}</p>
                                    ) : (
                                      <p className="text-justify">2. No Response Submitted</p>
                                    )} 
                                </div>                                    
                            </div>

                            <div className="col-md-4 mt-3 mt-sm-0">
                                <div className="reflection-content">
                                    <h5 className="mb-2">Socreates Summary</h5>
                                    {this.state.socreates_takeaways[2] ? (
                                      <p className="text-justify">3. {this.state.socreates_takeaways[2]}</p>
                                    ) : (
                                      <p></p>
                                    )} 
                                    <h5 className="mb-2 mt-4">Your Reflections</h5>
                                    {this.state.user_takeaways[2] ? (
                                      <p className="text-justify">3. {this.state.user_takeaways[2]}</p>
                                    ) : (
                                      <p className="text-justify">3. No Response Submitted</p>
                                    )} 
                                </div>                                    
                            </div>
                          </div>
                        </div>
                      </>   
                    )}                                                
                  </Tab>

                  <Tab eventKey="assessment" title="I Decree">
                      {assessment_data_not_found ? (
                          <figure className="mt-4"><img src={DataNotFound} alt="DataNotFound" className="gif-large" /></figure>
                        ) : (
                          <>
                            <h5 className="text-center tab-title mt-3">Through my conscious intent, I decree the following:</h5>
                            <div className="journal-right-content">
                              <div className="row">
                                {this.state.assessment_responses.map((assessment_response, index) => (
                                      <div className="col-md-4" key={index}>
                                        <div className="assesment-content-wrap">
                                          <h6>Q.{index + 1} {assessment_response.name}</h6>
                                          <p ><b>-</b> {assessment_response.subjective_answer_1}</p>
                                          <p ><b>-</b> {assessment_response.subjective_answer_2}</p>
                                          <p ><b>-</b> {assessment_response.subjective_answer_3}</p>
                                        </div>
                                      </div>
                                ))}
                              </div>
                            </div>
                          </>
                        )}
                  </Tab>

                  <Tab eventKey="end_of_journey" title="End of Journey">
                      {journey_data_not_found ? (
                          <figure className="mt-4"><img src={DataNotFound} alt="DataNotFound" className="gif-large img-end-journey" /></figure>
                        ) : (
                          <div className="journal-right-content mt-3 end-of-journey-tab">
                            <div className="row">
                              {this.state.end_of_journey_responses.map((end_of_journey_response, index) => (
                                  <div className={(index === 1) ? 'col-md-12' : 'col-md-12 mt-3 mt-sm-0'} key={index}>
                                  <h6 className="text-left">{index + 1}. {end_of_journey_response.review_question}</h6>
                                  <p className="text-justify">a. {end_of_journey_response.review_answer_1}</p>

                                  {end_of_journey_response.review_answer_2 ? (
                                    <p className="text-justify">b. {end_of_journey_response.review_answer_2}</p>
                                  ) : (
                                    <span></span>
                                  )}
                                  
                                  {end_of_journey_response.review_answer_3 ? (
                                    <p className="text-justify">c. {end_of_journey_response.review_answer_3}</p>
                                  ) : (
                                    <span></span>
                                  )}  
                                  <hr/>                                  
                                  </div>
                              ))}
                            </div>
                          </div>
                      )}
                  </Tab>

                </Tabs>
                </div>
              </div>
            </div>
          </div>
          <GeometryBundle2 />
          <CommonFooter />
        </div>  
      </>
    );
  }
} 