import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Logo from "./../images/logo.png";
import AuthLinks from "./AuthLinks";
import CommonFooter from "./CommonFooter";
import GeometryBundle3 from "./GeometryBundle3";

export default class RevisitJourney extends Component {
  constructor(props) 
  {
    super(props);
    this.state = { };
  }

  clickBtn = e => {
    this.setState({
      renderView: e.target.value
    });
  };

  render() {
    if(this.state.renderView === 'start_journey')
    {
      return <Redirect to="/journey" />;
    }

    if(this.state.renderView === 'start_journal')
    {
      return <Redirect to="/journal" />;
    } 

    const login = localStorage.getItem("isLoggedIn");

    if(!login) 
    {
      return <Redirect to="/sign-in" />;
    } 

    const user = JSON.parse(localStorage.getItem("userData"));

    if(user && (user.email_verified_at === '' || user.email_verified_at === null))
    {
      return <Redirect to="/email-verification" push={true} />;
    }    

    return (
      <>
        <div className="main-wrapper">
          <header>
            <figure className="waves-top"></figure>
            <figure className="logo">
              <img src={Logo} alt="logo" />
            </figure>
            <AuthLinks />
          </header>         

          <div className="mid-container">
            <div className="content mt-0">
                <div className="row align-self-center">
                    <div className="col-md-12 col-12">
                      <button value={"start_journey"} className="btn btn-primary" onClick={this.clickBtn}>Resume my Journey</button><br/>
                      <button value={"start_journal"} className="btn btn-primary mt-4" onClick={this.clickBtn}>Revisit a Learning</button><br/>
                    </div>
                </div>
            </div>     
          </div>
          <GeometryBundle3 />
          <CommonFooter />
        </div> 
      </>
    );
  }
}